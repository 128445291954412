<template>
  <section :class="{ sobre_nos: sobre_nos }">
    <h2>{{ $i18n.locale == "pt" ? "MANIFESTO" : "MANIFEST" }}</h2>
    <div class="manifesto">
      <iframe
        loading="lazy"
        :src="`https://player.vimeo.com/video/923137127?h=5d67acbef1&title=0&byline=0&portrait=0`"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        title="vídeo manifesto"
      ></iframe>
      <!-- allowfullscreen -->
    </div>
  </section>
</template>

<script>
export default {
  name: "Manifesto",
  props: {
    sobre_nos: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.manifesto {
  margin: 50px auto;
  width: 885px;
  height: 499px;
  /* background: #888888; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.manifesto.sobre_nos {
  width: 100%;
  height: 600px;
}

.manifesto iframe {
  width: 100%;
  height: 100%;
}

h2 {
  margin-top: 120px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
}

.manifesto p {
  font-family: "Roboto Slab";
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.6px;
  text-align: center;
  margin: 90px 0 60px 0;
  color: var(--white-color);
}
.manifesto p span {
  font-family: "Roboto Slab";
  color: var(--secondary-color);
}

@media (max-width: 989px) {
  .manifesto,
  .manifesto.sobre_nos {
    width: 100%;
    height: 300px;
    padding: 0;
    margin: 0 auto;
  }

  .manifesto h2,
  .manifesto p {
    text-align: left;
    max-width: 90%;
  }
}
</style>