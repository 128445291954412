<template>
  <div></div>
</template>

<script>
export default {
  name: "SolucaoRedirect",
  mounted() {
    this.$router.push(`/solucoes/${this.$route.params.nome_solucao}`);
  },
};
</script>
