import Vue from 'vue'
import Vuex from 'vuex'
import {
  api
} from '@/services.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    user: {
      "id": -1,
      "nome": "",
      "email": "",
      "dataCriacao": "",
      "dataAtualizacao": "",
      "ativo": false
    },
    mobile: false,
    idioma: 'pt-br'
  },
  getters: {},
  mutations: {
    UPDATE_IDIOMA(state, payload) {
      state.idioma = payload;
    },
    UPDATE_MOBILE(state, payload) {
      state.mobile = payload;
    },
    UPDATE_LOGIN(state, payload) {
      state.login = payload;
    },
    UPDATE_USER(state, payload) {
      window.localStorage.user = JSON.stringify(payload)
      state.user = payload;
    },

  },
  actions: {
    checkDevice(context, payload) {
      context.commit('UPDATE_MOBILE', payload)
    },
    logarUsuario(context, payload) {
      return api.post("/backoffice/login", payload)
        .then(response => {
          window.localStorage.token = response.data.token;
        })
    },


    getUsuario(context) {
      return api.get(`/backoffice/usuario/logged`)
        .then(response => {
          context.commit('UPDATE_USER', response.data)
          context.commit('UPDATE_LOGIN', true)

        }).catch((error) => {
          if (error.response.status == 401) {
            context.dispatch('deslogarUsuario')
            this.$router.push('/backoffice/login')
          }
        })
    },

    deslogarUsuario(context) {
      context.commit('UPDATE_USER', {
        "id": -1,
        "nome": "",
        "email": "",
        "dataCriacao": "",
        "dataAtualizacao": "",
        "ativo": false
      })
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      context.commit('UPDATE_LOGIN', false)
    },
    alterarIdioma(context, payload) {
      window.sessionStorage.idioma = payload;
      context.commit('UPDATE_IDIOMA', payload)

      
    }

  },
  modules: {},
})