import { render, staticRenderFns } from "./SetupCase.vue?vue&type=template&id=6ba54428&scoped=true"
import script from "./SetupCase.vue?vue&type=script&lang=js"
export * from "./SetupCase.vue?vue&type=script&lang=js"
import style0 from "./SetupCase.vue?vue&type=style&index=0&id=6ba54428&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba54428",
  null
  
)

export default component.exports