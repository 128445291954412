<template>
  <div></div>
</template>

<script>
export default {
  name: "CaseRedirect",
  mounted() {
    this.$router.push(`/cases/${this.$route.params.id}`);
  },
};
</script>
