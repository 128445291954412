<template>
  <section>
    <div class="flex-filtros-btn" v-if="!ativo">
      <button
        class="btn btn-remove"
        @click.prevent="removerFiltro"
        v-if="existe_filtro"
      >
        {{
          $i18n.locale == "pt"
            ? `REMOVER ${qtd_filtros} FILTROS`
            : `REMOVE ${qtd_filtros}  FILTERS`
        }}
      </button>
      <button
        class="btn btn-remove"
        @click="openFiltros(true)"
        data-anima="bottom"
      >
        {{ $i18n.locale == "pt" ? "FILTROS" : "FILTERS" }}
        <img
          loading="lazy"
          src="@/assets/img/svg/tune_FILL0_wght200_GRAD0_opsz48.svg"
          alt="filtro icone"
        />
      </button>
    </div>
    <div class="filtros" v-show="ativo" data-anima="top">
      <button class="btn btn-close">
        <img
          loading="lazy"
          src="@/assets/img/svg/close_FILL0_wght200_GRAD0_opsz48.svg"
          alt="icone close"
          @click="openFiltros(false)"
        />
      </button>
      <div class="filtro-grid">
        <div class="item-filtro" v-if="configs_cases.industria.length">
          <h2>{{ $i18n.locale == "pt" ? "Indústrias" : "Industries" }}</h2>
          <div
            class="item-checkbox"
            v-for="ind in configs_cases.industria"
            :key="ind.id"
          >
            <input
              type="checkbox"
              :id="`industria-${ind.id}`"
              :value="ind.id"
              @change="toggleFiltro('industriaId', ind)"
            />
            <label :for="`industria-${ind.id}`">{{ ind.descricao }}</label>
          </div>
        </div>
        <div class="item-filtro" v-if="configs_cases.solucao.length">
          <h2>{{ $i18n.locale == "pt" ? "Soluções" : "Solutions" }}</h2>
          <div
            class="item-checkbox"
            v-for="solucao in configs_cases.solucao"
            :key="solucao.id"
          >
            <input
              type="checkbox"
              :id="`solucao-${solucao.id}`"
              :value="solucao.id"
              @change="toggleFiltro('solucaoId', solucao)"
            />
            <label :for="`solucao-${solucao.id}`">{{
              solucao.descricao
            }}</label>
          </div>
        </div>
        <!-- <div class="item-filtro" v-if="configs_cases.cliente.length">
          <h2>{{ $i18n.locale == "pt" ? "Empresas" : "Companies" }}</h2>
          <div
            class="item-checkbox"
            v-for="cliente in configs_cases.cliente"
            :key="cliente.id"
          >
            <input
              type="checkbox"
              :id="`cliente-${cliente.id}`"
              :value="cliente.id"
              @change="toggleFiltro('clienteId', cliente.id)"
            />
            <label :for="`cliente-${cliente.id}`">{{ cliente.nome }}</label>
          </div>
        </div> -->
      </div>
      <div class="flex-filtros-btn">
        <button class="btn btn-filtro" @click.prevent="filtrar">
          {{ $i18n.locale == "pt" ? "Aplicar filtros" : "Apply filters" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services";
export default {
  name: "FiltrosCase",
  data() {
    return {
      ativo: false,
      existe_filtro: false,
      configs_cases: {
        industria: [],
        cliente: [],
        solucao: [],
      },
      filtros: {
        industriaId: [],
        clienteId: [],
        solucaoId: [],
      },
      qtd_filtros: 0,
    };
  },
  methods: {
    removerFiltro() {
      sessionStorage.filtro_case = "";
      this.$emit("filtrar", "");
      this.ativo = false;
      this.existe_filtro = null;
      sessionStorage.qtd_filtros = 0;
    },
    toggleFiltro(item, value) {
      const index = this.filtros[item].indexOf(value.id);

      if (index !== -1) {
        this.filtros[item].splice(index, 1);
      } else {
        this.filtros[item].push(value.id);
      }
    },
    filtrar() {
      let queryString = Object.keys(this.filtros).map((key) => {
        if (Array.isArray(this.filtros[key])) {
          return this.filtros[key].length > 0
            ? this.filtros[key]
                .map((value, index) => `${key}[${index}]=${value}`)
                .join("&")
            : "";
        } else {
          return `${key}=${this.filtros[key]}`;
        }
      });

      queryString = queryString.filter((elemento) => elemento !== "").join("&");
      sessionStorage.filtro_case = `&${queryString}`;
      sessionStorage.qtd_filtros = `&${queryString}`.split("&").length - 1;
      this.qtd_filtros = sessionStorage.qtd_filtros;
      this.$emit("filtrar", `&${queryString}`);
      this.existe_filtro = sessionStorage.filtro_case;
      this.ativo = false;
    },
    openFiltros(value) {
      this.ativo = value;
    },
    fetchConfig() {
      api.get("cases/configuration").then((resp) => {
        this.configs_cases = resp.data;
      });
    },
  },
  mounted() {
    this.fetchConfig();
    this.existe_filtro = sessionStorage.filtro_case || null;
    this.qtd_filtros = sessionStorage.qtd_filtros || 0;
  },
};
</script>

<style scoped>
h2 {
  font-family: "Roboto Slab";
}
/* filtros */
.filtros {
  background: #000;
  padding: 60px 200px;
}

.filtros .filtro-grid {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin: 0 auto;
}
.filtros .filtro-grid h2,
.filtros .filtro-grid label {
  color: #fff;
}
.filtros .btn-close,
.btn-abrir-filtro {
  margin: 0 auto;
  border-radius: 50%;
  padding: 0;
  border: 1px solid #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: -80px;
}
.btn-remove {
  border: 1px solid #000;
  background: transparent;
  color: #000 !important;
  font-size: 12px;
  letter-spacing: 1.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn-remove img {
}
.btn-abrir-filtro {
  margin: 0;
  top: 0;
}
.btn-remove img {
  width: 15px;
}
.filtros .btn-close img,
.btn-abrir-filtro img {
  width: 20px;
}
.item-filtro {
  /* background: red; */
  flex: 1;
}
.item-filtro h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 30px;
}
.item-filtro .item-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-filtro .item-checkbox label {
  margin: 0;
  font-size: 16px;
  color: #9b9a98;
  font-weight: lighter;
  cursor: pointer;
}
.filtros .btn-filtro {
  margin: 0 auto;
  background: #797979;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.8px;
  margin-top: 50px;
}
.btn-abrir-filtro {
  background: transparent;
  border: 1px solid #000;
  position: initial;
  /* margin-top: -20px; */
}
.item-checkbox + .item-checkbox {
  margin-top: 20px;
}
@media (max-width: 989px) {
  .btn-abrir-filtro {
    /* margin-top: 20px; */
  }
  .filtros {
    padding: 20px 40px;
    padding-bottom: 60px;
  }
  .filtros .filtro-grid {
    flex-direction: column;
    gap: 40px;
  }
  .item-filtro h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .filtros .btn-close {
    top: -40px;
  }
}
.flex-filtros-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>