<template>
  <footer>
    <div class="container container-footer">
      <FormularioContato
        @FormularioContato="FormularioContato"
        ref="formContato"
        :claro="true"
      />
      <div class="center-footer">
        <nav>
          <div class="desk">
            <ul class="itens-menu">
              <li>
                <router-link to="/solucoes" title="Soluções">{{
                  $i18n.locale == "pt" ? "Soluções" : "Solutions"
                }}</router-link>
              </li>
              <!-- <li>
                <router-link to="/industrias">Indústrias</router-link>
              </li> -->
              <li>
                <router-link to="/cases" title="Cases">Cases</router-link>
              </li>
              <li>
                <router-link to="/sobre-nos" title="Sobre nós">{{
                  $i18n.locale == "pt" ? "Sobre nós" : "About us"
                }}</router-link>
              </li>
              <li>
                <router-link to="/bacon-news" title="Bacon News"
                  >Bacon News</router-link
                >
              </li>
            </ul>
          </div>
          <div class="mob">
            <ul class="itens-menu">
              <!-- <li>
                <a
                  href="mailto:CONTATO@NEWBACON.COM"
                  to="/solucoes"
                  title="Email para contato"
                  >CONTATO@NEWBACON.COM</a
                >
              </li> -->
              <!-- <li>
                <router-link to="/industrias">TRABALHE CONOSCO</router-link>
              </li> -->

              <li>
                <router-link to="/termos-de-uso" title="Termos de uso">{{
                  $i18n.locale == "pt" ? "TERMO DE USO" : "TERMOS OF USE"
                }}</router-link>
              </li>
              <li>
                <router-link
                  to="/politica-de-privacidade"
                  title="Política de privacidade"
                  >{{
                    $i18n.locale == "pt"
                      ? "POLÍTICA DE PRIVACIDADE"
                      : "PRIVACY POLICY"
                  }}</router-link
                >
              </li>
              <li class="idioma" @click.prevent="changeLanguage()">
                <img
                  loading="lazy"
                  src="@/assets/img/svg/language_FILL0_wght200_GRAD0_opsz48.svg"
                  alt="icone idioma"
                  width="20"
                  height="20"
                />
                <a href="#" title="Alterar idioma">{{
                  $i18n.locale == "pt" ? "ENGLISH" : "PORTUGUÊS"
                }}</a>
              </li>
            </ul>
          </div>
          <div class="whats">
            <a href="https://wa.me/5511992214220/?text=Olá!" target="_blank">
              <button class="btn btn-3">
                WHATSAPP
                <img
                  loading="lazy"
                  src="@/assets/img/svg/iconmonstr-whatsapp-1.svg"
                  alt="icone whatsapp"
                  width="50"
                  height="50"
                />
              </button>
            </a>
          </div>

          <a
            class="endereco desk"
            href="https://maps.app.goo.gl/o57aCyqbAkxxLCQy9"
            target="_blank"
            title="Endereço da Newbacon"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/pin_drop_FILL0_wght200_GRAD0_opsz48.svg"
              alt="icone pin"
              width="50"
              height="50"
            />
            <span>
              R. Prof. José Leite e Oiticica, 84 <br />
              Brooklin São Paulo - SP, 04705-080
            </span>
          </a>
        </nav>
      </div>
      <div class="bottom-footer">
        <div class="mob">
          <a
            class="endereco"
            href="https://maps.app.goo.gl/o57aCyqbAkxxLCQy9"
            target="_blank"
            title="Endereço da Newbacon"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/pin_drop_FILL0_wght200_GRAD0_opsz48.svg"
              alt="icone pin"
              width="50"
              height="50"
            />
            <span>
              R. Prof. José Leite e Oiticica, 84 <br />
              Brooklin São Paulo - SP, 04705-080
            </span>
          </a>
        </div>

        <p class="desk" v-if="$i18n.locale == 'pt'">
          © 2004 - {{ ano_atual }} Newbacon, todos os direitos reservados.
          <router-link to="/termos-de-uso" title="Termos de uso">
            Termos de uso e preferências </router-link
          >/
          <router-link
            to="/politica-de-privacidade"
            title="Política de privacidade"
            >Política de privacidade</router-link
          >
        </p>
        <p class="desk" v-if="$i18n.locale == 'en'">
          © 2004 - {{ ano_atual }} Newbacon, all rights reserved.

          <router-link to="/termos-de-uso" title="Termos de uso">
            Terms of use and preferences </router-link
          >/
          <router-link
            to="/politica-de-privacidade"
            title="Política de privacidade"
            >Privacy policy.</router-link
          >
        </p>

        <p class="mob">© 2004 - {{ ano_atual }} Newbacon</p>
      </div>
    </div>
  </footer>
</template>

<script>
import FormularioContato from "./FormularioContato.vue";
import { api } from "@/services";
import { EventBus } from "@/main.js";
export default {
  name: "Footer",
  components: {
    FormularioContato,
  },
  data() {
    return {
      selectedLanguage: null,
    };
  },
  computed: {
    ano_atual() {
      return new Date().getFullYear();
    },
  },
  methods: {
    changeLanguage() {
      const lang = this.$i18n.locale == "en" ? "pt-br" : "en-us";

      const splitLang = lang.split("-")[0];
      if (splitLang !== this.selectedLanguage) {
        EventBus.$emit("loadingGlobal", true);
        this.selectedLanguage = splitLang;
        this.$i18n.locale = splitLang;

        this.$store.dispatch("alterarIdioma", lang);

        // Obter o caminho da rota atual
        let newPath = this.$route.path;

        // Remover qualquer parâmetro de idioma existente
        newPath = newPath.replace(/\/(pt|en)$/, "");

        // Adicionar o novo parâmetro de idioma
        newPath += `/${splitLang}?n=b`;
        newPath = newPath.replace("//", "/");

        // Navegar para a nova rota
        this.$router.push(newPath);
      }
    },
    FormularioContato(data) {
      const form = this.$refs.formContato;

      api
        .post("/lead", data)
        .then(() => {
          if (form) {
            form.showNotificacao(
              "success",
              this.$i18n.locale == "pt"
                ? `Olá ${data.nome}, agradecemos seu interesse. Entraremos em contato em breve para entender melhor suas necessidades e discutir como podemos ajudar.`
                : `Hello ${data.nome}, thank you for your interest. We will contact you shortly to better understand your needs and discuss how we can help.`
            );
          }
        })
        .catch((error) => {
          form.showNotificacao(
            "error",
            error.response
              ? error.response.data.errors[0]?.message
              : this.$i18n.locale == "pt"
              ? "Algo deu errado na requisição"
              : "Something went wrong with the request"
          );
        });
    },
  },
};
</script>

<style scoped>
footer {
  background: var(--primary-color);
  padding: 25px 0;
  font-size: 1.2rem;
  position: relative;
  bottom: 0;
  margin: 0 !important;
}
.container-footer {
  padding: 15px 10px;
  margin: 0 auto;
}

.center-footer nav {
  padding: 32px 0;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #696969;
}
.center-footer .itens-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}
.center-footer .itens-menu li a {
  color: var(--white-color);
  font-size: 16px;
}

.center-footer .whats button {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  letter-spacing: 1.8px;
  width: 180px;
  padding: 0;
}
.center-footer .whats button img {
  max-width: 15px;
}
.endereco {
  align-items: center;
  gap: 15px;
}
.endereco img {
  height: 36px;
}
.endereco span {
  color: var(--white-color);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
.bottom-footer {
  padding: 32px 0;
}
.bottom-footer p {
  color: #b7b7b7;
  font-size: 14px;
  font-weight: 300;
}
.bottom-footer p a {
  color: #b7b7b7;
  text-decoration: underline;
}
.whats button:hover {
  background-color: #fbb034;
}

@media (min-width: 990px) {
  .endereco {
    display: flex !important;
  }

  .mob {
    display: none !important;
  }
}

@media (max-width: 989px) {
  .top-footer {
    flex-direction: column;
    justify-content: center;
  }
  .text-info,
  .center-footer nav {
    flex-direction: column-reverse;
  }
  .text-info span {
    text-align: center;
    max-width: 70%;
  }
  .input-item {
    width: 100%;
  }
  .center-footer .itens-menu {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 40px;
    gap: 30px;
  }
  .bottom-footer {
    padding: 20px 30px;
  }
  .bottom-footer p {
    line-height: 1.5;
  }
  .container-footer {
    padding: 15px 0px;
  }

  .top-footer {
    padding: 0px 10px 60px 10px;
  }

  .center-footer,
  .bottom-footer {
    background-color: #696969;
  }

  footer,
  .container-footer {
    padding-bottom: 0px;
  }

  .whats button {
    border: 1px solid black;
    margin-top: -26px;
  }
  .center-footer .itens-menu {
    gap: 20px;
    padding: 30px 40px 10px 40px;
  }
  /*
  .center-footer .itens-menu li::after {
    content: " ";
    background-color: black;
    display: block;
    height: 1px;
  }*/

  .center-footer .itens-menu li a {
    width: 100%;
    text-align: left;
    color: var(--white-color);
    font-size: 12px;
  }

  .center-footer .itens-menu li a:hover {
    color: white;
  }

  .bottom-footer p {
    text-align: center !important;
    color: white !important;
  }
  .mob {
    text-align: center;
    padding-bottom: 20px;
  }
  .mob img {
    margin: 0 auto;
    padding-bottom: 10px;
  }
  .bottom-footer .desk {
    display: none;
  }
  .center-footer nav {
    padding: 0px;
  }
  nav .mob {
    width: 100%;
  }
}
.idioma {
  display: flex;
  align-items: center;
  gap: 10px;
}
.idioma img {
  padding: 0;
  filter: invert(1);
}
</style>