import { render, staticRenderFns } from "./SolucoesItens.vue?vue&type=template&id=dab12518&scoped=true"
import script from "./SolucoesItens.vue?vue&type=script&lang=js"
export * from "./SolucoesItens.vue?vue&type=script&lang=js"
import style0 from "./SolucoesItens.vue?vue&type=style&index=0&id=dab12518&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dab12518",
  null
  
)

export default component.exports