<template>
  <div
    class="notificacao"
    :class="[infos.variant, { form: form }]"
    data-anima="top"
  >
    {{ infos.mensagem }}
  </div>
</template>

<script>
export default {
  name: "Notificacao",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Boolean,
      default: false,
    },
  },

  // computed: {
  //   getIcone() {
  //     return this.infos.tipo
  //       ? require(`@/assets/img/icon-${this.infos.tipo}.svg`)
  //       : null;
  //   },
  // },
};
</script>

<style scoped>
.notificacao {
  background: #f7f7f7;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  /* display: flex;
  align-items: center; 
  gap: 10px;*/
  font-weight: 600;
  margin: 10px 0;
  margin-bottom: 15px;
  display: inline-block;
  text-align: center;
  line-height: 1.4;
}
.notificacao.form {
  text-align: left;
  font-weight: 600;
  max-width: 90%;
}
.notificacao.small {
  padding: 8px 12px;
  font-size: 12px;
}
.notificacao.small img {
  max-width: 15px;
  border-radius: 6px;
}
.notificacao.error {
  color: #ef5257;
  background: rgba(239, 82, 87, 0.1);
}
.notificacao.success {
  color: #fbb034;
  background: rgbA(251, 176, 52, 0.1);
}
.notificacao.info {
  color: #002092;
  background: rgba(0, 32, 146, 0.1);
}
@media (max-width: 989px) {
  .notificacao {
    padding: 8px 12px;
    font-size: 13px;
  }
}
</style>