<template>
  <section class="container">
    <div data-anima="bottom">
      <h1>{{ $i18n.locale == "pt" ? "SOBRE NÓS" : "ABOUT US" }}</h1>
      <h2 v-if="$i18n.locale == 'pt'">
        Quando dados<br />
        encontram paixão,<br />
        a magia acontece.
      </h2>
      <h2 v-if="$i18n.locale == 'en'">
        When data<br />
        meets passion,<br />
        magic happens.
      </h2>

      <img
        loading="lazy"
        data-anima="left"
        src="@/assets/img/bg/sobre-nos-1920x845.png"
        alt="sobre nos bg"
        class="bg desk"
      />
      <img
        loading="lazy"
        data-anima="left"
        src="@/assets/img/banners/sobre-nos_720x600.png"
        alt="sobre nos bg"
        class="bg mobile"
      />
    </div>
    <div class="content">
      <!-- <h3 v-if="$i18n.locale == 'en'">
          Only a CRM specialist company can<br v-i="!isMobile" />
          sign below: Data-driven love
        </h3> -->
      <div class="text-content" v-if="$i18n.locale == 'pt'">
        <h3>
          Apenas um empresa especialista em CRM pode<br v-if="!isMobile" />
          assinar embaixo: Data-driven love
        </h3>

        <p>
          Existe um grupo de pessoas que não se contenta em ser normal, em fazer
          sempre as mesmas coisas. <br /><br />Movidos à inovação, nunca têm
          medo de tentar, de arriscar e de viver intensamente. São aqueles que
          se destacam na multidão, que chamam a atenção pela autenticidade e
          pela ousadia, que são únicos e originais.<br /><br />
          Carregamos em nosso DNA esses valores que nos fazem diferentes e temos
          muito orgulho em ser parte dessa turma que é menor, mas muito mais
          interessante. Fugimos da monotonia, do tédio e da apatia porque somos
          dinâmicos, cheios de energia e criatividade. Desafiamos tudo o que nos
          parece comum e não aceitamos o impossível. Se não existe solução, tudo
          bem, a gente cria uma!<br /><br />
          Sabemos que sempre podemos ir além. Sem receio de errar, sem ligar
          para críticas, inventamos e nos reinventamos o tempo todo. Porque
          acreditamos que o mundo mudou para melhor toda vez que alguém pensou
          diferente e não aceitou ser normal.<br /><br />
          Somos únicos porque combinamos o melhor de dois mundos em um só. Somos
          a mistura improvável de especialistas em dados que amam comunicação e
          criativos apaixonados por dados. Somos data-driven love.<br /><br />
          A verdade é que para ser diferente é preciso ter muita coragem. Fácil
          é ser normal e isso é tudo o que a gente não quer.<br /><br />
          Afinal, ser incomum é que é "normal" para nós.<br /><br />
          Nós somos diferentes. <br /><br />E você?
        </p>
      </div>

      <div class="text-content" v-if="$i18n.locale == 'en'">
        <h3>
          Only a CRM specialist company can<br v-if="!isMobile" />
          endorse this: Data-driven love
        </h3>

        <p>
          There's a group of people who refuse to settle for average, for doing
          the same old things. <br /><br />Fueled by innovation, they're never
          afraid to try, to take risks, to live intensely. They're the ones who
          stand out in the crowd, who catch attention with their authenticity
          and boldness, who are unique and original.<br /><br />
          We carry these values in our DNA that make us different, and we're
          proud to be part of this smaller, but much more interesting group. We
          run away from monotony, boredom, and apathy because we're dynamic,
          full of energy and creativity. We challenge everything that seems
          common and we don't accept the impossible. If there's no solution,
          that's okay, we'll create one!<br /><br />
          We know we can always go further. Fearless of making mistakes,
          uncaring of criticism, we invent and reinvent ourselves all the time.
          Because we believe the world changed for the better every time someone
          thought differently and refused to be normal.<br /><br />
          We're unique because we combine the best of two worlds in one. We're
          the unlikely mix of data experts who love communication and creatives
          passionate about data. We are data-driven love.<br /><br />
          The truth is, to be different takes a lot of courage. It's easy to be
          normal, and that's everything we don't want.<br /><br />
          After all, being uncommon is what's "normal" to us.<br /><br />
          We are different.<br /><br />And you?
        </p>
      </div>

      <!-- <Manifesto :sobre_nos="true" /> -->
    </div>
    <!-- <div>
      <h1>
        {{ $i18n.locale == "pt" ? "OUTRAS SOLUÇÕES" : "OTHER SOLUTIONS" }}
      </h1>
      <SolucoesItens />
    </div> -->

    <!-- <div>
      <Cases
        anima_scroll="-scroll"
        :rodape="true"
        :form="false"
        :cases_home="cases_itens"
      />
    </div> -->
    <div class="flex-actions">
      <router-link to="/cases" class="btn btn-4" title="cases"
        >—
        <span>{{
          $i18n.locale == "pt" ? "VEJA TODOS OS CASES" : "SEE ALL CASES"
        }}</span>
        —</router-link
      >

      <router-link to="/solucoes" class="btn btn-4" title="cases"
        >—
        <span>{{
          $i18n.locale == "pt" ? "NOSSAS SOLUÇÕES" : "OUR SOLUTIONS"
        }}</span>
        —</router-link
      >
    </div>
  </section>
</template>

<script>
// import Manifesto from "@/components/Home/Manifesto.vue";
// import SolucoesItens from "@/components/Home/SolucoesItens.vue";
// import Cases from "@/components/Home/Cases.vue";

import { api } from "@/services";
import { ScrollAnima } from "@/helpers";

export default {
  name: "SobreNosView",
  components: {
    // Manifesto,
    // SolucoesItens,
    // Cases,
  },
  data() {
    return {
      cases_itens: [],
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
  methods: {
    fetchCases() {
      this.loading = true;
      api
        .get(`/cases?page=1&pageSize=3&statusId=1`)
        .then((resp) => {
          this.cases_itens = resp.data?.items;
        })
        .finally(() => {
          this.loading = false;
          const scrollAnima = new ScrollAnima("[data-anima]");
          scrollAnima.init();
        });
    },
  },
  mounted() {
    this.fetchCases();
  },
};
</script>

<style scoped>
h1,
p {
  text-align: center;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: lighter;
  margin: 40px auto;
}
h2 {
  font-size: 45px;
  font-family: "Roboto Slab";
  line-height: 50px;
  max-width: 65%;
  /* background: red; */
  margin: 0 auto;
}
.bg {
  margin-top: -150px;
}
.content {
  /* background-color: red; */
  margin: 100px 0;
}
.content .text-content {
  max-width: 65%;
  margin: 0 auto;
}
.content h3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-weight: bold;
}
.content p {
  margin: 0;
  margin-top: 45px;
  margin-bottom: 100px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}
.content p span {
  font-weight: 600;
}

@media (max-width: 989px) {
  h1 {
    margin: 40px auto;
    font-size: 12px;
  }
  h2 {
    margin-bottom: 60px;
    font-size: 32px;
    line-height: 34px;
    margin: 30px auto;
    max-width: 100%;
  }
  .bg {
    margin-left: -25px;
    margin-top: -30px;
  }
  .content {
    margin: 50px 0;
    margin-bottom: 0;
  }
  .content .text-content {
    max-width: 100%;
  }
  .content p {
    margin-bottom: 0;
  }
  .flex-actions {
    flex-direction: column;
    margin-top: 60px;
  }
  .flex-actions a {
    width: 100%;
  }
}
.flex-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 150px;
}
</style>