<template>
  <header data-anima="top" id="menu-header">
    <nav class="container">
      <router-link to="/" class="logo" title="Logo Newbacon">
        <img
          loading="lazy"
          src="@/assets/img/svg/logo-newbacon.svg"
          alt="Logo newbacon"
          width="200"
          height="30"
      /></router-link>
      <a
        href="#"
        class="hamburguer"
        @click.prevent="mobile = true"
        title="Menu icone"
      >
        <img
          loading="lazy"
          src="@/assets/img/svg/airware_FILL0_wght200_GRAD0_opsz48.svg"
          alt="menu mobile"
          width="200"
          height="30"
        />
      </a>
      <ul class="itens-menu desk">
        <menu class="itens-menu" v-if="$i18n.locale == 'pt'">
          <li>
            <router-link to="/solucoes" title="soluções">Soluções</router-link>
          </li>
          <!-- <li>
          <router-link to="/industrias">Indústrias</router-link>
        </li> -->
          <li>
            <router-link to="/cases" title="cases">Cases</router-link>
          </li>
          <li>
            <router-link to="/sobre-nos" title="sobre nós"
              >Sobre nós</router-link
            >
          </li>
          <li>
            <router-link to="/bacon-news" title="bacon news"
              >Bacon News</router-link
            >
          </li>
          <li>
            <a href="#contato" title="Link para contato">Contato</a>
          </li>
        </menu>
        <menu class="itens-menu" v-if="$i18n.locale == 'en'">
          <li>
            <router-link to="/solucoes" title="soluções">Solutions</router-link>
          </li>
          <!-- <li>
          <router-link to="/industrias">Indústrias</router-link>
        </li> -->
          <li>
            <router-link to="/cases" title="cases">Cases</router-link>
          </li>
          <li>
            <router-link to="/sobre-nos" title="sobre nós"
              >About Us</router-link
            >
          </li>
          <li>
            <router-link to="/bacon-news" title="bacon news"
              >Bacon News</router-link
            >
          </li>
          <li>
            <a href="#contato" title="Link for contact">Contact</a>
          </li>
        </menu>
        <li>
          <a
            href="#"
            class="idioma-item"
            v-if="$i18n.locale == 'en'"
            title="Alterar idioma"
            @click.prevent="changeLanguage('pt-br')"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/language_FILL0_wght200_GRAD0_opsz48.svg"
              alt="icone idioma"
              width="30"
              height="30"
            />
            PT-BR
          </a>
          <a
            href="#"
            class="idioma-item"
            v-if="$i18n.locale == 'pt'"
            title="Change language"
            @click.prevent="changeLanguage('en-us')"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/language_FILL0_wght200_GRAD0_opsz48.svg"
              alt="icone idioma"
              width="30"
              height="30"
            />
            EN-US
          </a>
        </li>
      </ul>

      <div class="menu-mobile" v-if="mobile" data-anima="top">
        <ul class="itens-menu mobile" @click="mobile = false">
          <li class="init-item">
            <router-link to="/">
              <img
                loading="lazy"
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Início</router-link
            >
            <img
              loading="lazy"
              src="@/assets/img/svg/close_FILL0_wght200_GRAD0_opsz48.svg"
              class="close"
              alt="close"
            />
          </li>
          <li>
            <router-link to="/solucoes"
              ><img
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Soluções</router-link
            >
          </li>
          <!-- <li>
            <router-link to="/industrias"
              ><img
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Indústrias</router-link
            >
          </li> -->
          <li>
            <router-link to="/cases"
              ><img
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Cases</router-link
            >
          </li>
          <li>
            <router-link to="/sobre-nos"
              ><img
                loading="lazy"
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Sobre nós</router-link
            >
          </li>
          <li>
            <router-link to="/bacon-news"
              ><img
                loading="lazy"
                src="@/assets/img/svg/seta-menu.svg"
                alt="Arrow"
              />Bacon News</router-link
            >
          </li>
          <li>
            <a
              href="#"
              class="idioma-item"
              v-if="$i18n.locale == 'pt'"
              title="Alterar idioma"
              @click.prevent="changeLanguage('en-us')"
            >
              <img
                loading="lazy"
                src="@/assets/img/svg/language_FILL0_wght200_GRAD0_opsz48.svg"
                alt="icone idioma"
                width="30"
                height="30"
              />
              PT-BR
            </a>
            <a
              href="#"
              class="idioma-item"
              v-if="$i18n.locale == 'en'"
              title="Change language"
              @click.prevent="changeLanguage('pt-br')"
            >
              <img
                loading="lazy"
                src="@/assets/img/svg/language_FILL0_wght200_GRAD0_opsz48.svg"
                alt="icone idioma"
                width="30"
                height="30"
              />
              EN-US
            </a>
          </li>
        </ul>
        <div class="form-contato">
          <FormularioContato
            @FormularioContato="FormularioContato"
            ref="formContato"
          />
        </div>
        <div>
          <div class="whats">
            <button class="btn btn-4">
              WHATSAPP
              <img
                loading="lazy"
                src="@/assets/img/svg/iconmonstr-whatsapp-1.svg"
                alt="icone whatsapp"
                width="50"
                height="50"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { EventBus } from "@/main.js";
import FormularioContato from "./FormularioContato.vue";
import { api } from "@/services";
export default {
  name: "Menu",
  components: {
    FormularioContato,
  },
  data() {
    return {
      mobile: false,
      selectedLanguage: null,
      loading_idioma: true,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content:
            this.$i18n.locale == "pt"
              ? `Uma agência data-driven, com a missão de transformar dados em comunicação, combinando a tecnologia com criação e planejamento orientado por dados.`
              : `A data-driven agency, with the mission of transforming data into communication, combining technology with data-driven creation and planning.`,
        },
        {
          name: "og:description",
          content:
            this.$i18n.locale == "pt"
              ? `Uma agência data-driven, com a missão de transformar dados em comunicação, combinando a tecnologia com criação e planejamento orientado por dados.`
              : `A data-driven agency, with the mission of transforming data into communication, combining technology with data-driven creation and planning.`,
        },
        {
          name: "author",
          content: "Newbacon",
        },
      ],
    };
  },
  methods: {
    FormularioContato(data) {
      const form = this.$refs.formContato;

      api
        .post("/lead", data)
        .then(() => {
          if (form) {
            form.showNotificacao(
              "success",
              this.$i18n.locale == "pt"
                ? `Olá ${data.nome}, agradecemos seu interesse. Entraremos em contato em breve para entender melhor suas necessidades e discutir como podemos ajudar.`
                : `Hello ${data.nome}, thank you for your interest. We will contact you shortly to better understand your needs and discuss how we can help.`
            );
          }
        })
        .catch((error) => {
          form.showNotificacao(
            "error",
            error.response
              ? error.response.data.errors[0]?.message
              : this.$i18n.locale == "pt"
              ? "Algo deu errado na requisição"
              : "Something went wrong with the request"
          );
        });
    },
    changeLanguage(lang) {
      const splitLang = lang.split("-")[0];
      if (splitLang !== this.selectedLanguage) {
        EventBus.$emit("loadingGlobal", true);
        this.selectedLanguage = splitLang;
        this.$i18n.locale = splitLang;

        this.$store.dispatch("alterarIdioma", lang);

        // Obter o caminho da rota atual
        let newPath = this.$route.path;

        // Remover qualquer parâmetro de idioma existente
        newPath = newPath.replace(/\/(pt|en)$/, "");

        // Adicionar o novo parâmetro de idioma
        newPath += `/${splitLang}?n=b`;
        newPath = newPath.replace("//", "/");

        // Navegar para a nova rota
        this.$router.push(newPath);
      }
    },
  },
  mounted() {
    const extractLangFromPath = (path) => {
      const match = path.match(/\/(pt|en)$/);
      return match ? match[1] : null;
    };
    const langFromRoute = extractLangFromPath(this.$route.path);
    const lang =
      langFromRoute === "pt" || langFromRoute === "en" ? langFromRoute : "pt";
    this.$i18n.locale = lang;
    this.$store.dispatch("alterarIdioma", lang);
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* background: var(--white-color); */
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  z-index: 99999;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  /* background: var(--white-color); */
  /* min-height: 80px; */
}
nav .logo {
  width: 120px;
  padding-bottom: 5px;
}
nav .itens-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}
nav .itens-menu li a {
  font-size: 16px;
  transition: 0.3s;
}

nav li a:hover,
nav li a.router-link-active,
nav li a.router-link-exact-active {
  /* color: var(--secondary-color); */
  font-weight: 600;
  cursor: pointer;
}
nav .hamburguer,
nav .menu-mobile,
nav .itens-menu.mobile {
  display: none;
}
nav .itens-menu.desk {
  display: flex !important;
}

@media (max-width: 989px) {
  nav {
    /* background: blue; */
    padding: 0 20px;
    min-height: 50px;
  }

  nav .itens-menu.desk {
    display: none !important;
  }
  nav .hamburguer,
  nav .menu-mobile,
  nav .itens-menu.mobile {
    display: block;
  }
  nav .hamburguer img {
    width: 22px;
  }
  nav .menu-mobile {
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background: #000;
    left: 0;
    top: 0;
    padding: 80px 30px;
  }
  nav .menu-mobile li a {
    color: #fff;
    font-size: 22px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  nav .menu-mobile li + li {
    margin-top: 50px;
  }
  nav .init-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  nav .init-item .close {
    width: 20px;
  }
  .idioma-item img {
    filter: invert(1);
  }
  nav .itens-menu li a.idioma-item {
    font-size: 18px !important;
  }
}
.top-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-top: 80px;
}
.text-info {
  display: block;
  text-align: center;
}
.text-info img {
  width: 20px;
  margin: 0 auto;
  margin-top: 15px;
}
.text-info span {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  color: #fff;
}
.input-item {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.input-item input {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  height: 50px;
  width: 100%;
}
.input-item input,
.input-item input::placeholder {
  color: #fff;
  font-weight: 300;
}
.input-item button {
  position: absolute;
  right: 0;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: transparent;
  cursor: pointer;
}
.whats {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.whats button {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  letter-spacing: 1.8px;
  width: 180px;
  padding: 0;
  font-weight: 600;
  background: #fff;
}
.whats button img {
  max-width: 15px;
}
.idioma-item {
  display: flex;
  align-items: center;
  gap: 7px;
}
nav .itens-menu li a.idioma-item {
  font-size: 14px;
}
.idioma-item img {
  width: 16px;
}
.form-contato {
  margin-top: 80px;
}
ul {
  list-style: none !important;
}
li::marker {
  color: transparent !important;
}
</style>