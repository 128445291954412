<template>
  <div class="blog-container" :class="{ bacon_news: bacon_news }">
    <div class="container conteudo">
      <div class="grid-blog">
        <div
          class="item-blog"
          v-for="item in blog_itens"
          :key="item.id"
          :title="item.title"
          @click.prevent="openBlog(item.url)"
        >
          <span
            >—
            {{ item.tags.length ? item.tags[0].name : item.authors[0].name }}
            —</span
          >
          <div class="img-blog">
            <img
              loading="lazy"
              :src="item.feature_image"
              alt="img-blog"
              width="100%"
              height="250"
            />
          </div>
          <h6>{{ item.title }}</h6>
          <a href="#">{{ $i18n.locale == "pt" ? "LER" : "READ" }} </a>
        </div>
      </div>
      <router-link
        to="/bacon-news"
        v-if="$route.name != 'Bacon News'"
        title="Visualizar todo do blog"
        ><img
          src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
          alt="icone mais"
          width="50"
          height="50"
          class="icone-mais"
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItensBlog",
  props: {
    bacon_news: {
      type: Boolean,
      default: false,
    },
    page_news: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      blog_itens: [],
    };
  },
  methods: {
    openBlog(url) {
      window.open(url);
    },
    fetchBlog() {
      this.loading = true;
      const limit = this.$route.name == "Bacon News" ? "" : "&limit=4";
      fetch(
        `https://newbacon.ghost.io/ghost/api/v3/content/posts/?key=503c21621d2643c0509393eb92&include=tags,authors${limit}`
      )
        .then((resp) => resp.json())
        .then((resp) => {
          this.blog_itens = resp?.posts;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchBlog();
  },
};
</script>

<style scoped>
.blog-container {
  background: #000;
  padding: 60px 10px;
}
/* .blog-container.bacon_news {
  background: #fff;
} */
.blog-container .grid-blog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 100px 0;
}
.blog-container .grid-blog .item-blog {
  border: 1px solid var(--white-color);
  border-radius: 10px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 530px;
  cursor: pointer;
}
/* .blog-container.bacon_news .grid-blog .item-blog {
  border-color: #000;
} */
.blog-container .grid-blog .item-blog span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 25px;
  display: inline-block;
}
/* .blog-container.bacon_news .grid-blog .item-blog span {
  color: rgba(0, 0, 0, 0.6);
} */
.blog-container .grid-blog .item-blog img {
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;
}

.blog-container .grid-blog .item-blog h6 {
  color: var(--white-color);
  font-family: "Roboto Slab";
  margin-top: 25px;
  font-size: 24px;
}

.blog-container .grid-blog .item-blog a {
  color: var(--white-color);
  margin-left: auto;
  /* margin-top: 75px; */
  text-decoration: underline;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
/* .blog-container.bacon_news .grid-blog .item-blog h5,
.blog-container.bacon_news .grid-blog .item-blog a {
  color: #000;
} */
.item-blog:hover {
  background-color: #fff;
}
/* .bacon_news .item-blog:hover {
  background: #000;
} */

.item-blog:hover span,
.item-blog:hover h6,
.item-blog:hover a {
  color: #000 !important;
}

/* .bacon_news .item-blog:hover span,
.bacon_news .item-blog:hover h5,
.bacon_news .item-blog:hover a {
  color: #fff !important;
} */
.icone-mais {
  filter: invert(100%);
  margin: 0 auto;
  cursor: pointer;
}
.img-blog {
}
@media (max-width: 989px) {
  .blog-container {
    padding: 0;
  }
  .blog-container .grid-blog {
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    padding: 0;
    padding-bottom: 40px;
  }
  .blog-container .grid-blog .item-blog {
    min-width: 300px;
    flex: 1;
    height: auto;
  }
  .grid-blog::-webkit-scrollbar {
    height: 2px;
  }
  .icone-mais {
    margin-top: 40px;
  }
}
</style>