<template>
  <div>
    <BlogNews />
    <section class="container">
      <!-- <div data-anima="bottom">
      <h1>Bacon News</h1>
      <h2>Notícias, atualizações e artigos<br /></h2>
    </div>

    <div>
      <ItensBlog :bacon_news="true" />
      
    </div> -->
    </section>
  </div>
</template>

<script>
import BlogNews from "@/components/Home/BlogNews.vue";

export default {
  name: "BaconNewsView",
  components: {
    BlogNews,
  },
};
</script>

<style scoped>
.geral {
  background: #000;
}
</style>