<template>
  <section
    class="solucoes"
    data-anima="bottom"
    :class="{ detalhes_solucoes: detalhes_solucoes }"
  >
    <div class="solucoes-itens" :class="{ flex: solucao_ativa }">
      <div class="item" v-if="solucao_ativa != 'execucao-criativa'">
        <router-link
          title="execucao-criativa"
          to="/solucao/redirect/execucao-criativa/"
        >
          <img
            loading="lazy"
            src="@/assets/img/banners/execucao-criativa-data-driven_720x600.png"
            alt="Execução Criativa"
          />
          <h5>
            {{
              $i18n.locale == "pt" ? "Execução Criativa" : "Creative Execution"
            }}
          </h5>
          <img
            loading="lazy"
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            width="50"
            height="50"
            class="icone-mais mobile"
          />
        </router-link>
      </div>

      <div class="item" v-if="solucao_ativa != 'planejamento-data-driven'">
        <router-link
          title="planejamento-data-driven"
          to="/solucao/redirect/planejamento-data-driven"
        >
          <img
            loading="lazy"
            src="@/assets/img/banners/insight-plano-de-acao_720x600.png"
            alt="Planejamento Data-driven"
          />
          <h5>
            {{
              $i18n.locale == "pt"
                ? "Planejamento Data-driven"
                : "Data-driven planning"
            }}
          </h5>
          <img
            loading="lazy"
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            width="50"
            height="50"
            class="icone-mais mobile"
          />
        </router-link>
      </div>

      <div class="item" v-if="solucao_ativa != 'data-science'">
        <router-link to="/solucao/redirect/data-science" title="data-science">
          <img
            loading="lazy"
            src="@/assets/img/banners/data-science_720x600.png"
            alt="Data science"
          />
          <h5>Data science</h5>
          <img
            loading="lazy"
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            width="50"
            height="50"
            class="icone-mais mobile"
          />
        </router-link>
      </div>
      <div
        class="item"
        v-if="solucao_ativa != 'data-management-e-engenharia-de-dados'"
      >
        <router-link
          title="data-management-e-engenharia-de-dados"
          to="/solucao/redirect/data-management-e-engenharia-de-dados"
        >
          <img
            loading="lazy"
            src="@/assets/img/banners/data-management_720x600.png"
            alt="Data management & engenharia de dados"
          />
          <h5>
            {{
              $i18n.locale == "pt"
                ? "Data management & engenharia de dados"
                : "Data management & data engineering"
            }}
          </h5>
          <img
            loading="lazy"
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            width="50"
            height="50"
            class="icone-mais mobile"
          />
        </router-link>
      </div>

      <div class="item" v-if="solucao_ativa != 'gestao360-do-crm'">
        <router-link
          to="/solucao/redirect/gestao360-do-crm"
          title="gestao360-do-crm"
        >
          <img
            loading="lazy"
            src="@/assets/img/banners/gestao-360_720x600.png"
            alt="Gestão 360º do CRM"
          />
          <h5>
            {{
              $i18n.locale == "pt"
                ? "Gestão 360º do CRM"
                : "360º CRM Management"
            }}
          </h5>
          <img
            loading="lazy"
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            width="50"
            height="50"
            class="icone-mais mobile"
          />
        </router-link>
      </div>
    </div>
    <div></div>
  </section>
</template>

<script>
export default {
  name: "SolucoesItens",
  props: {
    solucao_ativa: {
      type: String,
      default: "",
    },
  },
  computed: {
    detalhes_solucoes() {
      return this.$route.name == "Detalhes Soluções";
    },
  },
};
</script>

<style scoped>
.solucoes-itens .item {
  width: 300px;
  border: 1px solid black;
  margin-bottom: 50px;
  cursor: pointer;
}

.solucoes-itens .item:hover {
  background: #898989;
}
.solucoes-itens .item:hover h5 {
  color: #fff;
}

.detalhes_solucoes .solucoes-itens .item:hover {
  background: #fff !important;
}
.detalhes_solucoes .solucoes-itens .item:hover h5 {
  color: #000;
}

.item img {
  position: absolute;
  width: 370px;
  margin-top: -70px;
  margin-left: -35px;
  transition: 0.3s;
}

.item img:hover {
  transform: scale(1.05);
}

.item h5 {
  font-family: "Roboto Slab";
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  height: 350px;
  display: grid;
  align-items: end;
  padding: 0px 10px 30px 10px;
}

.icone-mais {
  width: 50px !important;
  margin: 0 auto !important;
}
.bg-solucoes {
  margin: 0 auto;
}
.solucoes-itens.flex {
  flex-wrap: nowrap;
  margin-top: 200px;
  max-width: 90%;
}

.solucoes-itens.flex .item {
  min-width: 250px;
  flex: 1;
}
.solucoes-itens.flex .item img {
  width: 300px;
}
.solucoes-itens.flex .item h5 {
  height: 300px;
}
@media (min-width: 990px) {
  .solucoes-itens {
    flex-wrap: wrap;
    max-width: 1100px;
    min-height: 350px;
    display: flex;
    /* justify-content: space-evenly; */
    margin: auto;
    margin-top: 120px;
    padding-bottom: 30px;
    gap: 60px;
  }
}

@media (max-width: 989px) {
  .solucoes h2,
  .solucoes p {
    width: 100%;
  }
  .solucoes h2 {
    padding: 0px 20px;
    font-size: 24px;
    line-height: 26px;
    text-align: left;
    margin: 30px auto;
  }
  .solucoes p {
    padding: 0px 20px;
    text-align: left;
    margin: 0 auto;
  }
  .solucoes h1 {
    margin: 20px auto;
  }

  .solucoes-itens {
    gap: 15px;
    margin: 0px auto;
    overflow-x: scroll;
    max-width: 100% !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    padding-bottom: 30px !important;
    margin-top: 80px !important;
  }

  .solucoes-itens .item {
    border: none;
    width: 100% !important;
    flex: 1 0 100% !important;
    margin-bottom: 30px;
  }
  .solucoes-itens a {
    flex: 1 0 100% !important;
  }

  .item img {
    width: 100% !important;
    position: relative;
    margin: 0px;
  }

  .item h5 {
    height: auto !important;
  }
  .solucoes-itens .item:hover {
    background: transparent;
  }
  .solucoes-itens .item:hover h5 {
    color: #000;
  }

  .detalhes_solucoes .solucoes-itens .item:hover {
    background: #000 !important;
  }
}
</style>