<template>
  <section class="container backoffice">
    <h1>🔥 {{ case_id ? "Editar" : "Novo" }} Case</h1>
    <p>
      {{
        case_id
          ? "Edite os campos abaixo como desejar"
          : "Crie um novo case preenchendo os campos abaixo"
      }}
    </p>
    <div v-show="!loading" data-anima="bottom" class="sessoes-conteudos">
      <Acordiao title="SEO" :ativo="true">
        <SeoCase
          :edit_case="edit_case"
          :case_id="+case_id"
          :seo_id="+seo_id"
          @updateSEO="updateSEO"
        />
      </Acordiao>

      <Acordiao title="SETUP">
        <SetupCase
          :edit_case="edit_case"
          :case_id="+case_id"
          :case_item="case_item"
          :seo_id="+seo_id"
          @updateSetup="updateSetup"
        />
      </Acordiao>

      <Acordiao title="CASE CONTEÚDO">
        <CaseConteudo :edit_case="edit_case" :case_id="+case_id" />
      </Acordiao>

      <Acordiao title="RESULTADOS">
        <ResultadoCase
          :edit_case="edit_case"
          :case_id="+case_id"
          :resultado_itens="resultado_itens"
        />
      </Acordiao>
    </div>
    <router-link
      :to="`/cases/${case_id}/?preview`"
      class="btn btn-nb-outline btn-preview"
      v-if="case_id && !loading"
      target="_blank"
    >
      Preview 🔥
    </router-link>
    <Loading v-if="loading" data-anima="top" />
  </section>
</template>

<script>
import { api } from "@/services";
import SeoCase from "@/components/CaseBackoffice/SeoCase.vue";
import SetupCase from "@/components/CaseBackoffice/SetupCase.vue";
import ResultadoCase from "@/components/CaseBackoffice/ResultadoCase.vue";
import CaseConteudo from "@/components/CaseBackoffice/CaseConteudo.vue";
import Acordiao from "@/components/Base/Acordiao.vue";

export default {
  name: "NovoCase",
  components: {
    SeoCase,
    SetupCase,
    ResultadoCase,
    CaseConteudo,
    Acordiao,
  },
  data() {
    return {
      loading: false,
      case_id: null,
      edit_case: false,
      case_item: null,
      seo_id: null,

      resultado_itens: [],
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    updateSEO(id_seo) {
      this.seo_id = id_seo;
    },
    updateSetup(id) {
      this.case_id = id;
    },

    fetchCase(id) {
      this.loading = true;
      api
        .get(`/cases/${id}`)
        .then((resp) => {
          this.case_item = {
            ...this.case_item,
            ...resp.data,
          };
          // this.fetchContent(resp.data.id);
          this.seo_id = resp.data.seo.id;
          this.resultado_itens = resp.data.resultados;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.case_id = this.$route.params.id;
    if (this.case_id) {
      this.edit_case = true;
      this.fetchCase(this.case_id);
    }
  },
};
</script>

<style scoped>
.btn-preview {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 10px 20px;
  height: 45px;
  font-size: 14px;
}
.sessoes-conteudos {
  margin: 60px auto;
}
</style>

