<template>
  <section class="container backoffice">
    <h1>📦 Listagem de Cases</h1>
    <p>Total de cases: {{ cases.totalCount || 0 }}</p>
    <div class="filtros">
      <div class="item-form">
        <label for="clientes">Clientes</label>
        <select
          name="clientes"
          id="clientes"
          v-model="filtros.clienteId"
          :disabled="loading"
        >
          <option
            :value="cliente.id"
            v-for="cliente in configs_cases.cliente"
            :key="cliente.id"
          >
            {{ cliente.nome }}
          </option>
        </select>
      </div>

      <div class="item-form">
        <label for="solucao">Solução</label>
        <select
          name="solucao"
          id="solucao"
          v-model="filtros.solucaoId"
          :disabled="loading"
        >
          <option
            :value="solucao.id"
            v-for="solucao in configs_cases.solucao"
            :key="solucao.id"
          >
            {{ solucao.descricao }}
          </option>
        </select>
      </div>

      <div class="item-form">
        <label for="industria">Industria</label>
        <select
          name="industria"
          id="industria"
          v-model="filtros.industriaId"
          :disabled="loading"
        >
          <option
            :value="industria.id"
            v-for="industria in configs_cases.industria"
            :key="industria.id"
          >
            {{ industria.descricao }}
          </option>
        </select>
      </div>

      <div class="item-form">
        <label for="status">Status</label>
        <select
          name="status"
          id="status"
          v-model="filtros.statusId"
          :disabled="loading"
        >
          <option
            :value="status.id"
            v-for="status in configs_cases.status"
            :key="status.id"
          >
            {{ status.descricao }}
          </option>
        </select>
      </div>

      <div class="item-form">
        <label for="pesquisa">Pesquisa</label>
        <input
          type="text"
          placeholder="por Título BR / EN"
          id="pesquisa"
          v-model="filtros.searchText"
          :disabled="loading"
        />
      </div>

      <div class="item-form">
        <button class="btn" @click="novoCase" :disabled="loading">
          Novo Case
        </button>
      </div>
    </div>
    <div class="tabela" v-show="!loading" data-anima="bottom">
      <table>
        <thead>
          <tr>
            <th>#ID</th>
            <th>Cliente</th>
            <th>Título</th>
            <th>Solução</th>
            <th>Indústria</th>
            <th>Status</th>
            <th>Carrousel</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody v-if="cases.items?.length">
          <tr v-for="(item, index) in cases.items" :key="item.id">
            <td>#{{ item.id }}</td>
            <td>{{ item.empresa.nome }}</td>
            <td :title="item.tituloEn">{{ item.tituloBr }}</td>
            <td>
              <div>
                <span v-for="solu in item.solucao" :key="solu.id"
                  >- {{ solu.descricao }}</span
                >
              </div>
            </td>
            <td>{{ item.industria.descricao }}</td>
            <td>
              {{ item.status }}
            </td>
            <!-- <td class="status">
              <div>
                <label class="switch" :for="`status-${item.id}`">
                  <input
                    type="checkbox"
                    :id="`status-${item.id}`"
                    :checked="item.status == 'ATIVO' ? true : false"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </td> -->
            <td>
              <div>
                <label class="switch" :for="`carrousel-${item.id}`">
                  <input
                    type="checkbox"
                    :id="`carrousel-${item.id}`"
                    :checked="item.fgCarrousel"
                    @change="changeCarrousel(item, index)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td class="actions">
              <button
                class="btn btn-2-outline"
                @click.prevent="editarCase(item.id)"
              >
                Editar
              </button>
              <button
                v-if="item.status == 'ATIVO'"
                class="btn btn-5-outline"
                @click.prevent="statusCase(item, index, 'Desativar')"
              >
                Desativar
              </button>
              <button
                v-else
                class="btn btn-6-outline"
                @click.prevent="statusCase(item, index, 'Ativar')"
              >
                Ativar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!cases.items?.length && !loading" class="feedback">
        Nenhum dado encontrado
      </div>
    </div>
    <div class="paginacao" v-if="cases.items?.length && !loading">
      <div class="total-pages">
        <select name="total-pages" id="total-pages" v-model="filtros.pageSize">
          <option value="10">10 por página</option>
          <option value="20">20 por página</option>
          <option value="30">30 por página</option>
          <option value="50">50 por página</option>
        </select>
      </div>
      <div class="paginas">
        <ul>
          <li
            class="page-anterior"
            @click="paginacao('ANTERIOR')"
            v-if="cases.hasPreviousPage"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/keyboard_arrow_down_FILL0_wght200_GRAD0_opsz48.svg"
              alt="seta"
            />
            Anterior
          </li>
          <li>Página {{ cases.page }}</li>
          <li
            class="page-proximo"
            @click="paginacao('PROXIMO')"
            v-if="cases.hasNextPage"
          >
            Próximo
            <img
              loading="lazy"
              src="@/assets/img/svg/keyboard_arrow_down_FILL0_wght200_GRAD0_opsz48.svg"
              alt="seta"
            />
          </li>
        </ul>
      </div>
    </div>
    <Loading v-if="loading" data-anima="bottom" />
  </section>
</template>

<script>
import { api } from "@/services";
import { debounce } from "@/helpers";

export default {
  name: "ListagemCases",
  data() {
    return {
      loading: false,
      configs_cases: {
        cliente: [
          {
            id: null,
            nome: "Todos os clientes",
          },
        ],
        solucao: [
          {
            id: null,
            descricao: "Todas as soluções",
          },
        ],
        industria: [
          {
            id: null,
            descricao: "Todas as industrias",
          },
        ],
        status: [
          {
            id: null,
            descricao: "Todos os status",
          },
        ],
      },
      filtros: {
        page: 1,
        pageSize: 10,
        clienteId: null,
        industriaId: null,
        solucaoId: null,
        statusId: null,
        searchText: "",
      },
      cases: [],
    };
  },
  watch: {
    filtros: {
      handler(v) {
        this.formatFiltros(v);
      },
      deep: true,
    },
  },
  methods: {
    paginacao(type) {
      switch (type) {
        case "ANTERIOR": {
          this.filtros.page--;
          break;
        }
        case "PROXIMO": {
          this.filtros.page++;
          break;
        }
      }
    },
    changeCarrousel(item, index) {
      const r = window.confirm(
        `⚠️ Deseja desativar o carrousel do case ${item.tituloBr} ?`
      );
      if (r) {
        api
          .get(`/cases/${item.id}/change-carrousel`)
          .then(() => {
            this.cases[index].fgCarrousel = !item.fgCarrousel;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const check = document.querySelector(`#carrousel-${item.id}`);
        check.checked = item.fgCarrousel;
        this.cases[index].fgCarrousel = item.fgCarrousel;
      }
    },
    editarCase(id) {
      this.$router.push(`/backoffice/cases/editar/${id}`);
    },
    statusCase(item, index, tipo) {
      const r = window.confirm(`⚠️ Deseja ${tipo} o case ${item.tituloBr} ?`);

      if (r) {
        api.get(`/cases/${item.id}/change-status`).then(() => {
          if (tipo == "Ativar") {
            item.status = "ATIVO";
          } else {
            item.status = "INATIVO";
          }
        });
        // .finally(() => {
        //   this.loading = false;
        // });
      }
    },
    formatFiltros: debounce(function () {
      const params = new URLSearchParams();
      const data = this.filtros;

      // Adiciona apenas as propriedades não nulas ou indefinidas
      for (const [key, value] of Object.entries(data)) {
        if (value !== null && value !== undefined && value !== "") {
          params.append(key, value);
        }
      }

      this.fetchCases(params.toString());
    }, 400),
    fetchConfigCases() {
      this.loading = true;
      api
        .get("cases/configuration")
        .then((resp) => {
          this.formatConfig(resp.data);
          this.formatFiltros();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    formatConfig(data) {
      Object.keys(this.configs_cases).forEach((item) => {
        if (data[item]) {
          this.configs_cases[item] = this.configs_cases[item].concat(
            data[item]
          );
        }
      });
    },
    fetchCases(query) {
      this.loading = true;
      api
        .get(`/cases?${query || ""}`)
        .then((resp) => {
          this.cases = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    novoCase() {
      this.$router.push("/backoffice/cases/novo");
    },
  },
  mounted() {
    this.fetchConfigCases();
  },
};
</script>

<style scoped>
h1 {
  font-family: "Roboto Slab";
  font-size: 28px;
  margin-bottom: 10px;
}
.filtros {
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  justify-content: space-between;
}

.filtros .item-form + .item-form {
  margin: 0;
  flex: 1;
}
.backoffice input,
.backoffice select,
.backoffice textarea {
  padding: 15px 10px;
}

.backoffice button {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 40px 0;
}

th,
td {
  /* border: 1px solid #e5e5e5; */
  padding: 15px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
}
td span {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

th {
  background-color: #f7f7f7;
}

.actions {
  display: flex;
  gap: 10px;
}
.actions .btn {
  padding: 5px 15px;
  height: 40px;
  font-size: 13px;
}
.check-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.check-flex label {
  margin: 0;
}
.feedback {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  padding: 20px 30px;
  max-width: 300px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
}
.paginacao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px 0;
  margin-bottom: 60px;
}
.paginacao .paginas ul,
.paginacao .paginas ul li {
  display: flex;
  align-items: center;
  gap: 15px;
}
.paginacao .paginas ul li {
  gap: 10px;
  transition: 0.3s;
  background: #f7f7f7;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.paginacao .paginas ul li.page-anterior img {
  transform: rotate(90deg);
}
.paginacao .paginas ul li.page-proximo img {
  transform: rotate(-90deg);
}
.paginacao .paginas ul li:hover {
  font-weight: 600;
  cursor: pointer;
}
</style>