<template>
  <div>
    <form @submit.prevent="addConteudo" data-anima="bottom">
      <div class="item-case">
        <h2 id="title-conteudos">
          Conteúdo - Case
          <Loading :mini="true" :no_space="true" v-if="loading" />
        </h2>

        <div>
          <div class="content-item">
            <div class="campos-setup">
              <div class="grid-item">
                <div>
                  <div class="item-form">
                    <label for="titulo-bloco-br">
                      (BR) - Título do bloco de conteúdo (ex: O desafio, A
                      solução)
                      <span>({{ content_item.tituloBr.length }}/25 crt)</span>
                    </label>
                    <!-- maxlength="25" -->
                    <input
                      type="text"
                      id="titulo-bloco-br"
                      placeholder="Ex: Desafio"
                      v-model="content_item.tituloBr"
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                  </div>
                  <div class="item-form">
                    <label for="conteudo-bloco-br"> (BR) - Conteúdo </label>
                    <!-- maxlength="600" -->
                    <quill-editor
                      v-model="content_item.conteudoBr"
                      name="conteudo-bloco-br"
                      id="conteudo-bloco-br"
                      class="quill-text"
                      required
                      ref="quillConteudobr"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    ></quill-editor>
                    <!-- <textarea
                      v-model="content_item.conteudoBr"
                      name="conteudo-bloco-br"
                      id="conteudo-bloco-br"
                      placeholder="O uso de meta tags em páginas da web é frequentemente exigido por motores de busca como uma fonte de informação para ajudá-los a decidir."
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    ></textarea> -->
                  </div>
                </div>
                <div>
                  <div class="item-form">
                    <label for="titulo-bloco-en">
                      (EN) - Título do bloco de conteúdo (ex: O desafio, A
                      solução)
                      <span>({{ content_item.tituloEn.length }}/25 crt)</span>
                    </label>
                    <!-- maxlength="25" -->
                    <input
                      v-model="content_item.tituloEn"
                      type="text"
                      id="titulo-bloco-en"
                      placeholder="Ex: Desafio"
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                  </div>
                  <div class="item-form">
                    <label for="conteudo-bloco-en"> (EN) - Conteúdo </label>
                    <!-- maxlength="600" -->
                    <quill-editor
                      v-model="content_item.conteudoEn"
                      name="conteudo-bloco-en"
                      id="conteudo-bloco-en"
                      required
                      class="quill-text"
                      ref="quillConteudoen"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    ></quill-editor>
                    <!-- <textarea
                      v-model="content_item.conteudoEn"
                      name="conteudo-bloco-en"
                      id="conteudo-bloco-en"
                      placeholder="The use of meta tags in web pages are often required by search engines as a source of information to help them to decide."
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    ></textarea> -->
                  </div>
                </div>
              </div>
              <div class="grid-item">
                <div class="option-conteudo">
                  <p>TIPO DE CONTEÚDO:</p>

                  <div class="item-option">
                    <input
                      type="radio"
                      id="imagem"
                      name="option-conteudo"
                      value="📷 Imagem"
                      checked
                      v-model="content_item.tipo_content"
                      required
                      @change="changeTipoConteudo"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                    <label for="imagem">Imagem</label><br />
                  </div>

                  <div class="item-option">
                    <input
                      type="radio"
                      id="video"
                      name="option-conteudo"
                      value="📽️ Vídeo"
                      v-model="content_item.tipo_content"
                      required
                      @change="changeTipoConteudo"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                    <label for="video">Vídeo</label><br />
                  </div>

                  <div class="item-option">
                    <input
                      type="radio"
                      id="texto"
                      name="option-conteudo"
                      value="📜 Texto"
                      v-model="content_item.tipo_content"
                      required
                      @change="changeTipoConteudo"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                    <label for="texto">Apenas texto</label><br />
                  </div>
                </div>
                <div class="item-form">
                  <label for="ordem-content"> Ordem do conteúdo </label>
                  <input
                    type="number"
                    id="ordem-content"
                    v-model="content_item.ordem"
                    required
                    :disabled="loading || (conteudo_id && !edit_case)"
                  />
                </div>
              </div>
            </div>

            <div class="imagens-setup">
              <h2>
                {{
                  content_item.tipo_content == "📜 Texto"
                    ? "📜 Adicione os textos acima"
                    : content_item.tipo_content
                }}
              </h2>
              <div class="itens-flex">
                <div
                  class="item-form"
                  v-if="content_item.tipo_content == '📷 Imagem'"
                >
                  <label for="img-content-desk">
                    Imagem Conteúdo DESK:<br />
                    Imagem png transparente com 1920x845px

                    <div class="img-setup">
                      <img
                        loading="lazy"
                        :src="
                          imgAux.imagemDesk.file ||
                          content_item.imagemDesk.file ||
                          content_item.imagemDesk
                        "
                        :alt="content_item.textoAltBr"
                      />
                    </div>
                    <input
                      type="file"
                      name="img-content-desk"
                      id="img-content-desk"
                      class="file-item"
                      accept="image/*"
                      @change="fileChangeContent($event, 'imagemDesk')"
                      :required="!edit_case"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />

                    <span>
                      Arquivo: {{ content_item.imagemDesk?.fileName }}</span
                    >
                  </label>
                </div>
                <div
                  class="item-form item-form-video"
                  v-if="content_item.tipo_content == '📽️ Vídeo'"
                >
                  <label for="canal-video"> Canal do vídeo </label>
                  <select
                    name="canal"
                    id="canal"
                    v-model="content_item.canalVideo"
                    :disabled="loading || (conteudo_id && !edit_case)"
                    required
                  >
                    <option value="VIMEO">VIMEO</option>
                    <!-- <option value="YOUTUBE">YOUTUBE</option> -->
                  </select>
                </div>

                <div
                  class="item-form"
                  v-if="content_item.tipo_content == '📷 Imagem'"
                >
                  <label for="img-content-mobile">
                    {{ content_item.tipo_content }} Conteúdo Mobile:<br />
                    {{ content_item.tipo_content }} png transparente com
                    1920x845px

                    <div class="img-setup">
                      <img
                        loading="lazy"
                        :src="
                          imgAux.imagemMobile.file ||
                          content_item.imagemMobile.file ||
                          content_item.imagemMobile
                        "
                        :alt="content_item.textoAltBr"
                      />
                    </div>
                    <input
                      type="file"
                      name="img-content-mobile"
                      id="img-content-mobile"
                      class="file-item"
                      accept="image/*"
                      @change="fileChangeContent($event, 'imagemMobile')"
                      :required="!edit_case"
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />

                    <span>
                      Arquivo: {{ content_item.imagemMobile?.fileName }}</span
                    >
                  </label>
                </div>

                <div
                  class="item-form item-form-video"
                  v-if="content_item.tipo_content == '📽️ Vídeo'"
                >
                  <label for="url-video"> URL do vídeo </label>
                  <input
                    type="url"
                    name="url-video"
                    id="url-video"
                    v-model="content_item.urlVideo"
                    required
                    placeholder="https://vimeo.com/{id}"
                    :disabled="loading || (conteudo_id && !edit_case)"
                  />
                </div>

                <div
                  class="item-form alts"
                  v-if="content_item.tipo_content == '📷 Imagem'"
                >
                  <div class="item-alt">
                    <label for="alt-br"> (BR) - Texto ALT: </label>
                    <input
                      v-model="content_item.textoAltBr"
                      type="text"
                      id="alt-br"
                      placeholder="Case Data-driven Newbacon + Electrolux"
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                  </div>
                  <div class="item-alt">
                    <label for="alt-en"> (EN) - Texto ALT: </label>
                    <input
                      v-model="content_item.textAltEn"
                      type="text"
                      id="alt-en"
                      placeholder="Newbacon + Electrolux Data-driven Case"
                      required
                      :disabled="loading || (conteudo_id && !edit_case)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-acao">
            <button class="btn mini-btn" type="submit" :disabled="loading">
              {{ content_item.editar ? "Atualizar" : "Adicionar" }} conteúdo
            </button>
          </div>

          <div class="conteudos-grid">
            <div
              class="conteudos-for"
              v-for="(conteudo, index) in conteudo_itens"
              :key="index"
            >
              <div class="item-conteudo">
                <p>
                  {{ conteudo.tipo_content }}
                </p>
                <p>
                  Título BR: <span>{{ conteudo.tituloBr }}</span>
                </p>
                <p>
                  Conteúdo BR: <span>{{ conteudo.conteudoBr }}</span>
                </p>
                <p>
                  Título EN: <span>{{ conteudo.tituloEn }}</span>
                </p>
                <p>
                  Conteúdo EN: <span>{{ conteudo.conteudoEn }}</span>
                </p>
                <div v-if="conteudo.tipo_content == '📷 Imagem'">
                  <p>
                    Arquivo Desktop:
                    <span>{{
                      conteudo.imagemDesk?.fileName ||
                      quebraUrl(conteudo.imagemDesk)
                    }}</span>
                  </p>
                  <p>
                    Arquivo Mobile:
                    <span>{{
                      conteudo.imagemMobile?.fileName ||
                      quebraUrl(conteudo.imagemMobile)
                    }}</span>
                  </p>
                  <p>
                    Texto Alt BR: <span>{{ conteudo.textoAltBr }}</span>
                  </p>
                  <p>
                    Texto Alt EN: <span>{{ conteudo.textAltEn }}</span>
                  </p>
                </div>
                <div v-if="content_item.tipo_content == '📽️ Vídeo'">
                  <p>
                    Canal vídeo:
                    <span>{{ conteudo.canalVideo }}</span>
                  </p>
                  <p>
                    URL vídeo: <span>{{ quebraUrl(conteudo.urlVideo) }}</span>
                  </p>
                </div>

                <div class="flex-btn">
                  <button
                    class="btn mini-btn btn-2-outline"
                    v-if="edit_case"
                    :disabled="loading"
                    @click.prevent="editarConteudo(conteudo)"
                  >
                    Editar item
                  </button>
                  <button
                    class="btn mini-btn btn-5-outline"
                    :disabled="loading"
                    @click.prevent="removerConteudo(conteudo, index)"
                  >
                    Remover item
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-footer">
            <div>
              <button
                class="btn"
                @click.prevent="submitConteudo"
                :disabled="loading || !case_id || !conteudo_itens.length"
              >
                <!-- :disabled="!case_id || !conteudo_itens.length || conteudo_id" -->
                Salvar conteúdo
                <Loading v-if="loading" data-anima="top" :mini="true" />
              </button>
              <span v-if="!case_id" class="valida-etapa"
                >🚫 Necessário completar a etapa SETUP primeiro*</span
              >
            </div>
            <Notificacao
              :infos="notificacao_infos"
              v-if="notificacao_infos.mensagem"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { api } from "@/services";
import QuillEditor from "@/components/QuillEditor.vue";

export default {
  name: "CaseConteudo",
  components: {
    QuillEditor,
  },
  props: {
    case_id: {
      type: Number,
      default: -1,
    },
    edit_case: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorContent: "",
      editorOptions: {
        // Opções do editor, consulte a documentação do Quill.js para mais opções
      },

      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      loading: false,
      content_item: {
        id: 0,
        tipo_content: "📷 Imagem",
        // tipo_content: "📜 Texto",
        tituloBr: "",
        tituloEn: "",
        conteudoBr: "",
        conteudoEn: "",
        imagemDesk: {
          file: null,
          fileName: null,
        },
        imagemMobile: {
          file: null,
          fileName: null,
        },
        textoAltBr: null,
        textAltEn: null,
        canalVideo: null,
        urlVideo: null,
        ordem: 0,
      },
      conteudo_itens: [],
      conteudo_id: null,
      imgAux: {
        imagemDesk: {
          file: null,
        },
        imagemMobile: {
          file: null,
        },
      },
    };
  },

  methods: {
    changeTipoConteudo() {
      if (
        this.content_item.tipo_content == "📷 Imagem" &&
        !this.content_item.imagemDesk?.file
      ) {
        this.content_item = {
          ...this.content_item,
          imagemDesk: {
            file: null,
            fileName: null,
          },
          imagemMobile: {
            file: null,
            fileName: null,
          },
        };
      }
    },
    quebraUrl(url) {
      if (url) {
        const comprimentoDesejado = 30;

        const substring = url.substring(0, comprimentoDesejado);

        return substring + "...";
      }
    },
    getImageBase64(event, item) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imgAux[item] = {
          file: reader.result,
          fileName: file.name,
        };
      };
      reader.readAsDataURL(file);
    },
    editarConteudo(item) {
      this.content_item = {
        editar: true,
        ...item,
      };

      this.$refs.quillConteudobr.setHTML(this.content_item.conteudoBr);
      this.$refs.quillConteudoen.setHTML(this.content_item.conteudoEn);

      document.getElementById("title-conteudos").scrollIntoView();
    },
    formatContent(data) {
      let req = data;
      req = {
        id: req.id,
        values: req.values.map((item) => {
          if (item.tipo_content == "📷 Imagem") {
            return {
              ...item,
              idCase: null,
              canalVideo: null,
              urlVideo: null,
            };
          } else {
            return {
              ...item,
              idCase: null,
              imagemDesk: null,
              imagemMobile: null,
              textAltEn: null,
              textoAltBr: null,
            };
          }
        }),
      };
      req.values = req.values.map((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] == null) {
            delete item[key];
          }
        });
        return item;
      });

      return req;
    },
    async submitConteudo() {
      this.loading = true;
      const data = {
        id: this.case_id,
        values: [...this.conteudo_itens],
        // values: [...this.teste],
      };

      const body = this.formatContent(data);

      const formData = this.jsonToFormData(body);
      const appendFile = async (file, formDataKey) => {
        if (file) formData.append(formDataKey, file);
      };

      this.conteudo_itens.forEach(async (item, index) => {
        await appendFile(
          item.imagemDesk?.file,
          `values[${index}].imagemDesk.file`
        );
        await appendFile(
          item.imagemMobile?.file,
          `values[${index}].imagemMobile.file`
        );
      });

      if (this.edit_case) {
        this.updateConteudo(formData);
      } else {
        this.createConteudo(formData);
      }
    },
    updateConteudo(formData) {
      api
        .put("/case-conteudo", formData)
        .then((resp) => {
          this.conteudo_id = resp.data.length;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Edição de conteúdos salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createConteudo(formData) {
      api
        .post("/case-conteudo", formData)
        .then((resp) => {
          this.conteudo_id = resp.data.length;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Criação de conteúdos salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fileChangeContent(event, item) {
      this.content_item[item] = {
        fileName: "",
        file: "",
      };
      const file = event.target.files[0];
      if (file) {
        this.content_item[item].file = file;
        this.content_item[item].fileName = file.name;
        this.getImageBase64(event, item);
      } else {
        this.imgAux[item] = {
          file: null,
          fileName: null,
        };
      }
    },
    deleteConteudo(item, index) {
      this.loading = true;
      api
        .delete(`/case-conteudo/${item.id}`)
        .then(() => {
          this.conteudo_itens.splice(index, 1);
        })
        .catch((error) => console.log(error));
    },

    removerConteudo(item, index) {
      const r = window.confirm(
        `⚠️ Deseja remover o conteúdo ${item.tituloBr} ?`
      );

      if (r) {
        if (this.conteudo_id) {
          this.deleteConteudo(item, index);
        } else {
          this.conteudo_itens.splice(index, 1);
        }
      }
    },
    addConteudo() {
      this.content_item.conteudoBr = this.$refs.quillConteudobr.getHTML();
      this.content_item.conteudoEn = this.$refs.quillConteudoen.getHTML();

      if (
        this.content_item.conteudoBr != "<p><br></p>" &&
        this.content_item.conteudoBr &&
        this.content_item.conteudoEn != "<p><br></p>" &&
        this.content_item.conteudoEn
      ) {
        if (this.content_item.editar) {
          const indexItem = this.conteudo_itens.findIndex(
            (item) => item.id == this.content_item.id
          );

          this.conteudo_itens[indexItem] = this.content_item;
        } else {
          const data = {
            ...this.content_item,
            id: this.edit_case ? 0 : null,
          };
          this.conteudo_itens.unshift(data);
        }

        setTimeout(() => {
          this.content_item = {
            tipo_content: "📷 Imagem",
            tituloBr: "",
            tituloEn: "",
            conteudoBr: "",
            conteudoEn: "",
            imagemDesk: {
              file: null,
              fileName: null,
            },
            imagemMobile: {
              file: null,
              fileName: null,
            },
            textoAltBr: null,
            textAltEn: null,
            canalVideo: null,
            urlVideo: null,
            ordem: 0,
          };
          this.imgAux = {
            imagemDesk: {
              file: null,
            },
            imagemMobile: {
              file: null,
            },
          };

          this.$refs.quillConteudobr.setHTML("");
          this.$refs.quillConteudoen.setHTML("");
        }, 200);
      } else {
        alert("Preencha os conteudos obrigatórios");
      }
    },
    jsonToFormData(json) {
      const formData = new FormData();

      Object.keys(json).forEach((key) => {
        this.appendToFormData(formData, key, json[key]);
      });

      return formData;
    },

    appendToFormData(formData, key, value) {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          this.appendToFormData(formData, `${key}[${index}]`, element);
        });
      } else if (typeof value === "object" && value !== null) {
        Object.keys(value).forEach((subKey) => {
          this.appendToFormData(formData, `${key}.${subKey}`, value[subKey]);
        });
      } else {
        formData.append(key, value);
      }
    },

    fetchContent(id) {
      this.loading = true;
      api
        .get(`/case-conteudo/case/${id}`)
        .then((resp) => {
          // this.conteudo_itens = resp.data;
          resp.data.forEach((item) => {
            this.conteudo_itens.push({
              ...item,
              tipo_content: !item.canalVideo
                ? item.imagemDesk
                  ? "📷 Imagem"
                  : "📜 Texto"
                : "📽️ Vídeo",
            });
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.edit_case) {
        this.fetchContent(this.case_id);
      }
    }, 200);
  },
};
</script>

<style scoped>
.item-form-video + .item-form-video {
  margin: 0;
}
.item-conteudo {
  /* min-height: 440px; */
}
h2 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.img-setup img {
  height: 150px;
  object-fit: contain;
}
.itens-flex .item-form + .item-form {
  margin: 0;
}
.grid-item + .grid-item {
  margin-top: 70px;
}
.flex-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>