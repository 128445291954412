import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Loading from '@/components/Base/Loading.vue'
import Notificacao from '@/components/Base/Notificacao.vue'
import i18n from "./i18n/i18n.js";


import VueMeta from 'vue-meta'

Vue.use(VueMeta)


Vue.config.productionTip = false

Vue.component('Loading', Loading)
Vue.component('Notificacao', Notificacao)
export const EventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')