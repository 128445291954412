<template>
  <div>
    <div class="item-case resultado-content">
      <h2 id="title-resultado">
        Resultados: até 3 indicadores
        <Loading :mini="true" :no_space="true" v-if="loading" />
      </h2>
      <form @submit.prevent="addIndicadorResultado" data-anima="bottom">
        <div class="content-item">
          <div class="option-conteudo">
            <p>EXIBIR:</p>
            <div class="item-option">
              <input
                type="radio"
                id="mostrar"
                name="option-resultados"
                value="true"
                checked
                v-model="indicador_item.exibir"
                required
                :disabled="loading"
              />
              <label for="mostrar">Mostrar</label><br />
            </div>

            <div class="item-option">
              <input
                type="radio"
                id="nao-mostrar"
                name="option-resultados"
                value="false"
                v-model="indicador_item.exibir"
                required
                :disabled="loading"
              />
              <label for="nao-mostrar">Não mostrar</label><br />
            </div>
          </div>
          <div class="campos-setup">
            <div class="grid-item">
              <div>
                <div class="item-form">
                  <label for="titulo-indicador-br">
                    (BR) - Indicador
                    <span
                      >({{ indicador_item.indicadorBr.length }}/10 crt)</span
                    >
                  </label>
                  <!-- maxlength="10" -->
                  <input
                    :disabled="loading"
                    type="text"
                    id="titulo-indicador-br"
                    placeholder="Ex: 30%"
                    v-model="indicador_item.indicadorBr"
                    required
                  />
                </div>
                <div class="item-form">
                  <label for="conteudo-indicador-br">
                    (BR) - Descrição do indicador
                    <span
                      >({{ indicador_item.descricaoBr.length }}/250 crt)</span
                    >
                  </label>
                  <!-- maxlength="250" -->
                  <textarea
                    :disabled="loading"
                    v-model="indicador_item.descricaoBr"
                    required
                    name="conteudo-indicador-br"
                    id="conteudo-indicador-br"
                    placeholder="O uso de meta tags em páginas da web é frequentemente exigido por motores de busca como uma fonte de informação para ajudá-los a decidir."
                  ></textarea>
                </div>
              </div>
              <div>
                <div class="item-form">
                  <label for="titulo-indicador-en">
                    (EN) - Indicador
                    <span
                      >({{ indicador_item.indicadorEn.length }}/10 crt)</span
                    >
                  </label>
                  <!-- maxlength="10" -->
                  <input
                    :disabled="loading"
                    type="text"
                    id="titulo-indicador-en"
                    placeholder="Ex: Desafio"
                    v-model="indicador_item.indicadorEn"
                    required
                  />
                </div>
                <div class="item-form">
                  <label for="conteudo-indicador-en">
                    (EN) - Descrição do indicador
                    <span
                      >({{ indicador_item.descricaoEn.length }}/250 crt)</span
                    >
                  </label>
                  <!-- maxlength="250" -->
                  <textarea
                    :disabled="loading"
                    v-model="indicador_item.descricaoEn"
                    required
                    name="conteudo-indicador-en"
                    id="conteudo-indicador-en"
                    placeholder="The use of meta tags in web pages are often required by search engines as a source of information to help them to decide."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-acao">
            <button class="btn mini-btn" type="submit" :disabled="loading">
              {{ indicador_item.editar ? "Atualizar" : "Adicionar" }} indicador
            </button>
          </div>

          <div v-if="resultados_item.length">
            <div
              data-anima="bottom"
              class="resultados"
              v-for="(resultado, index) in resultados_item"
              :key="index"
            >
              <p>Exibir: {{ resultado.exibir ? "Mostrar" : "Não mostrar" }}</p>
              <div class="grid-resultado">
                <div class="item-resultado">
                  <span> {{ resultado.indicadorBr }}</span>
                  <p>
                    {{ resultado.descricaoBr }}
                  </p>
                </div>
                <div class="item-resultado">
                  <span>{{ resultado.indicadorEn }}</span>
                  <p>
                    {{ resultado.descricaoEn }}
                  </p>
                </div>
              </div>

              <div class="flex-btn">
                <button
                  v-if="edit_case"
                  class="btn mini-btn btn-2-outline remove-indicador"
                  @click.prevent="editarIndicador(resultado)"
                  :disabled="loading"
                >
                  Editar indicador
                </button>
                <button
                  :disabled="loading"
                  class="btn mini-btn btn-5-outline remove-indicador"
                  @click.prevent="removerIndicador(resultado, index)"
                >
                  Remover indicador
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-footer">
          <div>
            <button
              class="btn"
              @click.prevent="submitResultados"
              :disabled="loading || !resultados_item.length || !case_id"
            >
              Salvar resultados
              <Loading v-if="loading" data-anima="top" :mini="true" />
            </button>
            <span v-if="!case_id" class="valida-etapa"
              >🚫 Necessário completar a etapa SETUP primeiro*</span
            >
          </div>
          <Notificacao
            :infos="notificacao_infos"
            v-if="notificacao_infos.mensagem"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { api } from "@/services";

export default {
  name: "ResultadoCase",
  data() {
    return {
      loading: false,
      resultado_id: null,
      resultados_item: [],
      indicador_item: {
        id: 0,
        descricaoBr: "",
        descricaoEn: "",
        exibir: false,
        indicadorBr: "",
        indicadorEn: "",
      },
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  props: {
    case_id: {
      type: Number,
      default: -1,
    },
    conteudo_id: {
      type: Number,
      default: -1,
    },
    edit_case: {
      type: Boolean,
      default: false,
    },
    resultado_itens: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    editarIndicador(item) {
      this.indicador_item = {
        editar: true,
        ...item,
      };
    },
    submitResultados() {
      this.notificacao_infos = {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      };

      this.loading = true;
      let data = this.resultados_item.map((item) => {
        return {
          ...item,
          exibir: item.exibir == "true" || item.exibir == true ? true : false,
        };
      });

      data = {
        id: this.case_id,
        values: [...data],
      };
      if (this.edit_case) {
        this.updateResultado(data);
      } else {
        this.createResultado(data);
      }
    },
    updateResultado(data) {
      api
        .put("/resultados", data)
        .then((resp) => {
          this.resultado_id = resp.data.id;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Edição de Resultados salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createResultado(data) {
      api
        .post("/resultados", data)
        .then((resp) => {
          this.resultado_id = resp.data.id;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Criação de Resultados salva com sucesso",
            count_timer: 10,
          };
          setTimeout(() => {
            this.$router.push("/backoffice/cases");
          }, 1000);
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addIndicadorResultado() {
      if (this.indicador_item.editar) {
        const indexItem = this.resultado_itens.findIndex(
          (item) => item.id == this.indicador_item.id
        );

        this.resultados_item[indexItem] = this.indicador_item;
      } else {
        this.resultados_item.unshift(this.indicador_item);
      }
      setTimeout(() => {
        this.indicador_item = {
          id: 0,
          descricaoBr: "",
          descricaoEn: "",
          exibir: false,
          indicadorBr: "",
          indicadorEn: "",
        };
      }, 100);
    },
    removerIndicador(item, index) {
      const r = window.confirm(
        `⚠️ Deseja remover o indicador ${item.indicadorBr} ?`
      );

      if (r) {
        this.resultados_item.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.edit_case) {
          this.resultados_item = this.resultado_itens;
        }
      }, 1500);
    });
  },
};
</script>

<style scoped>
.item-resultado {
  background: #f7f7f7;
}
.item-resultado p {
  max-width: 80%;
}
h2 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.grid-resultado {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
}
.item-resultado {
  padding: 20px 30px !important;
  border-radius: 0;
}
.item-resultado + .item-resultado {
  border-left: 1px solid #000;
}
.item-resultado span {
  font-size: 22px;
}
.resultados + .resultados {
  margin-top: 60px;
}
.remove-indicador {
  margin: 0 !important;
  position: initial !important;
}
.flex-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>