<template>
  <section>
    <form @submit.prevent="submitSeo" data-anima="bottom">
      <div class="item-case">
        <h2>SEO <Loading :mini="true" :no_space="true" v-if="loading" /></h2>

        <div class="campos-setup">
          <div class="grid-item">
            <div>
              <div class="item-form">
                <label for="titulo-seo-br">
                  (BR) - Título
                  <span>({{ seo_item.tituloBr.length }}/100 crt)</span>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="titulo-seo-br"
                  placeholder="Adicione o texto"
                  v-model="seo_item.tituloBr"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
              <div class="item-form">
                <label for="descricao-seo-br">
                  (BR) - Descrição
                  <span>({{ seo_item.descricaoBr.length }}/100 crt)</span>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="descricao-seo-br"
                  placeholder="Adicione o texto"
                  v-model="seo_item.descricaoBr"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
              <div class="item-form">
                <label for="Keywords-seo-br">
                  (BR) - Keywords
                  <span
                    >({{ countPalavras(seo_item.palavraChaveBr) }}/10
                    termos)</span
                  >
                </label>
                <input
                  type="text"
                  id="Keywords-seo-br"
                  placeholder=" Use vírgula para separar"
                  v-model="seo_item.palavraChaveBr"
                  @input="deb($event, 'palavraChaveBr')"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
            </div>
            <div>
              <div class="item-form">
                <label for="titulo-seo-en">
                  (EN) - Title
                  <span>({{ seo_item.tituloEn.length }}/100 crt)</span>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="titulo-seo-en"
                  placeholder="Adicione o texto"
                  v-model="seo_item.tituloEn"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
              <div class="item-form">
                <label for="descricao-seo-en">
                  (EN) - Description
                  <span>({{ seo_item.descricaoEn.length }}/100 crt)</span>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="descricao-seo-en"
                  placeholder="Adicione o texto"
                  v-model="seo_item.descricaoEn"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
              <div class="item-form">
                <label for="Keywords-seo-en">
                  (EN) - Keywords
                  <span
                    >({{ countPalavras(seo_item.palavraChaveEn) }}/10
                    termos)</span
                  >
                </label>
                <input
                  type="text"
                  id="Keywords-seo-en"
                  placeholder="ex: CRM, Newbacon, Data-driven | Use ',' para separar"
                  v-model="seo_item.palavraChaveEn"
                  @input="deb($event, 'palavraChaveEn')"
                  required
                  :disabled="loading || (seo_item.id && !edit_case)"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-seo">
            <div class="item-form">
              <label for="Author"> Author </label>
              <input
                type="text"
                id="Author"
                placeholder="Newbacon"
                v-model="seo_item.autor"
                required
                :disabled="loading || (seo_item.id && !edit_case)"
              />
            </div>
            <div class="item-form">
              <label for="Abstract"> Abstract </label>
              <input
                type="text"
                id="Abstract"
                placeholder="{%Cliente%}"
                v-model="seo_item.abstrato"
                required
                :disabled="loading || (seo_item.id && !edit_case)"
              />
            </div>
            <div class="item-form">
              <label for="Robots"> Robots </label>
              <input
                type="text"
                id="Robots"
                placeholder="Index, Follow"
                v-model="seo_item.robo"
                required
                :disabled="loading || (seo_item.id && !edit_case)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-footer">
        <button
          type="submit"
          class="btn"
          :disabled="loading || loading || (seo_item.id && !edit_case)"
        >
          Salvar SEO
          <Loading v-if="loading" data-anima="top" :mini="true" />
        </button>
        <Notificacao
          :infos="notificacao_infos"
          v-if="notificacao_infos.mensagem"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { api } from "@/services";
import { debounce } from "@/helpers";

export default {
  name: "SeoCase",
  data() {
    return {
      loading: false,
      seo_item: {
        id: null,
        tituloBr: "",
        tituloEn: "",
        descricaoBr: "",
        descricaoEn: "",
        palavraChaveBr: "",
        palavraChaveEn: "",
        autor: "",
        abstrato: "",
        robo: "",
      },
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  props: {
    seo_id: {
      type: Number,
      default: -1,
    },
    case_id: {
      type: Number,
      default: -1,
    },
    edit_case: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deb: debounce(function (event, item) {
      this.removerEspacos(event, item);
    }, 200),
    removerEspacos(event, item) {
      this.seo_item[item] = event.target.value.replace(/\s/g, ",");
    },
    countPalavras(frase) {
      return frase ? frase.split(",").length : 0;
    },
    fetchSeo(id) {
      this.loading = true;
      api
        .get(`/seo/${id}`)
        .then((resp) => {
          this.seo_item = resp.data;
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response.data || "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitSeo() {
      this.loading = true;
      this.notificacao_infos = {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      };
      if (this.seo_item.id) {
        this.updateSeo();
      } else {
        this.createSeo();
      }
    },
    updateSeo() {
      api
        .put(`/seo/${this.seo_item.id}`, this.seo_item)
        .then((resp) => {
          this.seo_item.id = resp.data.id;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Edição do SEO salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createSeo() {
      api
        .post("/seo", this.seo_item)
        .then((resp) => {
          this.seo_item.id = resp.data.id;
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Criação do SEO salva com sucesso",
            count_timer: 10,
          };
          this.$emit("updateSEO", this.seo_item.id);
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.edit_case && this.seo_id) {
          this.fetchSeo(this.seo_id);
        } else if (this.case_id) {
          this.loading = false;
          this.notificacao_infos = {
            variant: "error",
            mensagem: `Não foi encontrado item para esse id: #${this.case_id}. Você será redirecionado em 5s...`,
            count_timer: 10,
          };

          setTimeout(() => {
            this.$router.push("/backoffice/cases");
          }, 5000);
        } else {
          this.loading = false;
        }
      }, 1500);
    });
  },
};
</script>

<style scoped>
.flex-seo .item-form + .item-form {
  margin: 0;
}
h2 {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>
