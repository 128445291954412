<template>
  <form @submit.prevent="onSubmit" :class="{ no_case: no_case }">
    <div class="top-footer">
      <div class="text-info">
        <img
          loading="lazy"
          src="@/assets/img/svg/chat_FILL0_wght200_GRAD0_opsz48.svg"
          alt="Icone chat"
          width="30"
          height="30"
        />
        <span>
          {{
            $i18n.locale == "pt"
              ? "VAMOS FALAR SOBRE OS DESAFIOS DA SUA EMPRESA:"
              : "LET'S TALK ABOUT YOUR COMPANY'S CHALLENGES:"
          }}
        </span>
      </div>
      <div class="input-item">
        <input
          type="text"
          :placeholder="$i18n.locale == 'pt' ? 'Seu nome' : 'Your name'"
          v-model="form.nome"
          required
          v-if="step == 0"
        />
        <input
          data-anima="left"
          v-if="step == 1"
          type="text"
          :placeholder="$i18n.locale == 'pt' ? 'Empresa' : 'Company'"
          v-model="form.empresa"
          required
        />
        <input
          data-anima="left"
          v-if="step == 2"
          type="email"
          :placeholder="
            $i18n.locale == 'pt' ? 'E-mail de trabalho' : 'Work email'
          "
          v-model="form.email"
          required
        />
        <input
          data-anima="left"
          v-if="step == 3"
          type="tel"
          :placeholder="
            $i18n.locale == 'pt'
              ? 'Celular (00) 00000-0000'
              : 'Cell Phone (00) 00000-0000'
          "
          v-model="form.celular"
          required
          maxlength="15"
          @input="formatPhoneNumber"
        />
        <select
          data-anima="left"
          name="assunto"
          id="assunto"
          v-model="form.assunto"
          required
          v-if="step == 4"
        >
          <option value="null">
            {{
              $i18n.locale == "pt"
                ? "Selecione o assunto"
                : "Select the subject"
            }}
          </option>
          <option value="Quero conhecer mais sobre a Newbacon">
            Quero conhecer mais sobre a Newbacon
          </option>
          <option value="Quero evoluir minha estratégia de CRM">
            Quero evoluir minha estratégia de CRM
          </option>
          <option value="Quero aplicar AI & Algoritmos">
            Quero aplicar AI & Algoritmos
          </option>
          <option value="Quero melhorar a operação de automação de campanhas">
            Quero melhorar a operação de automação de campanhas
          </option>
        </select>
        <input
          data-anima="left"
          v-if="step == 5"
          type="text"
          :placeholder="
            $i18n.locale == 'pt' ? 'Como podemos ajudar?' : 'How can we help?'
          "
          v-model="form.mensagem"
        />
        <div class="notificacao">
          <Notificacao
            :infos="notificacao_infos"
            v-if="notificacao_infos.mensagem && step == 6 && !loading"
            :form="true"
          />
        </div>
        <button v-if="step < 6">
          <img
            loading="lazy"
            src="@/assets/img/svg/arrow_forward_ios_FILL0_wght200_GRAD0_opsz48.svg"
            alt="Icone botão"
            width="50"
            height="50"
          />
        </button>
        <Loading v-if="loading" :claro="claro" />
      </div>

      <div class="steps">
        <span
          v-for="(item, index) in total_steps"
          :key="index"
          :class="{ ok: step > index - 1 }"
        ></span>
        <!-- <img loading="lazy"src="@/assets/img/svg/mini-circles.svg" alt="Mini circulos" /> -->
      </div>
    </div>
  </form>
</template>

<script>
// import { api } from "@/services";

export default {
  name: "FormularioContato",
  props: {
    no_case: {
      type: Boolean,
      default: false,
    },
    claro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      total_steps: 6,
      step: 0,
      form: {
        nome: null,
        empresa: null,
        email: null,
        celular: null,
        assunto: null,
        mensagem: null,
      },
      loading: false,
    };
  },
  methods: {
    formatPhoneNumber() {
      if (this.form.celular) {
        let cleaned = this.form.celular.replace(/\D/g, "");

        if (cleaned.length === 11) {
          // (xx) xxxxx-xxxx
          this.form.celular = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
            2,
            7
          )}-${cleaned.slice(7, 11)}`;
        } else if (cleaned.length === 10) {
          // (xx) xxxx-xxxx
          this.form.celular = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
            2,
            6
          )}-${cleaned.slice(6, 10)}`;
        } else {
          // Não aplicar a máscara
          this.form.celular = cleaned;
        }
      }
    },
    showNotificacao(type, content) {
      this.notificacao_infos = {
        variant: type,
        mensagem: content,
        count_timer: 10,
      };
      this.loading = false;
    },
    onSubmit() {
      if (this.step == 5) {
        this.salvarDados();
      }
      this.step++;
    },
    salvarDados() {
      this.loading = true;
      this.$emit("FormularioContato", this.form);
    },
  },
};
</script>

<style scoped>
.top-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 32px;
  border-bottom: 1px solid #696969;
}

.no_case .top-footer {
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  border: none;
}
.no_case .top-footer img {
  filter: invert(1);
}
.text-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.no_case .text-info {
  /* flex-direction: column; */
}
.text-info img {
  width: 20px;
}
.text-info span {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2.1px;
  color: var(--white-color);
}
.no_case .text-info span {
  color: var(--primary-color);
}
.input-item {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}
.input-item input,
.input-item select {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  height: 50px;
  padding-left: 15px;
  color: #fff;
}
.no_case .input-item select {
  color: #000;
}
.input-item input,
.input-item input::placeholder {
  color: var(--white-color);
  font-weight: 300;
}
.no_case .input-item input,
.no_case .input-item input::placeholder {
  color: var(--primary-color);
}
.no_case .input-item input,
.no_case .input-item select {
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  width: 700px;
}
.input-item button {
  position: absolute;
  right: 0;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: transparent;
  cursor: pointer;
}
.steps {
  display: flex;
  align-items: center;
  gap: 5px;
}
.steps span {
  width: 13px;
  height: 13px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
.no_case .steps span {
  background: #f1f1f1;
}
.no_case .steps span.ok {
  background: #000;
}
.steps span.ok {
  background: #fff;
}
.notificacao {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
@media (max-width: 989px) {
  .top-footer {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    border: none;
  }
  .text-info,
  .center-footer nav {
    flex-direction: column-reverse;
  }
  .text-info span {
    text-align: center;
    max-width: 70%;
  }
  .input-item {
    width: 100%;
  }
  .input-item {
    width: 100%;
  }
  .input-item input {
    width: 100%;
  }
}
</style>
