<template>
  <section class="container">
    <h1>POLÍTICAS DE PRIVACIDADE</h1>
    <div class="titulo">
      <h2>
        Privacidade sempre foi prioridade na Newbacon. O cuidado e
        responsabilidade com os dados pessoais está no nosso DNA desde sempre.
      </h2>
    </div>
    <p>
      Somos uma agência Data-Driven e sabemos o valor dos dados. Por esse
      motivo, muito antes da LGPD existir, sempre trabalhamos com o objetivo de
      utilizar os dados para melhorar a prestação de serviços, experiências e
      relacionamento para os nossos clientes, parceiros e colaboradores.
    </p>
    <p>
      Com qual propósito a Newbacon trata dados pessoais? Da mesma forma que
      oferecemos para os clientes dos nossos clientes serviços de CRM /
      Data-Driven, aqui na Newbacon não poderia ser diferente. Aqui os seus
      dados pessoais são utilizados com o objetivo de melhorar a sua experiência
      em toda jornada do relacionamento conosco.
    </p>
    <p>
      A Newbacon coleta informações compartilhadas pelo usuário ao contratar
      e/ou utilizar nossos produtos e serviços. Os dados são coletados através
      do preenchimento dos formulários que disponibilizamos ou por meio dos
      nossos colaboradores.
    </p>
    <p>
      Os Dados Pessoais são coletados quando existe um relacionamento comercial
      ou profissional conosco, podendo ser capturado através das nossas
      plataformas digitais ou em contato direto com os nossos colaboradores.
      Quando você nos visita, são capturados alguns Dados Pessoais na nossa
      recepção para garantir a segurança e acessos a serviços como WiFi por
      exemplo. Utilizamos sistemas de captura de imagem (Câmeras de Segurança) e
      também sistemas de controle de acesso aos ambientes.
    </p>
    <p>
      Você no controle <br />Nosso comprometimento vai além da segurança e
      respeito à sua privacidade, queremos garantir a você transparência e
      controle dos seus dados pessoais. Você pode solicitar mais informações,
      alterações, ou exclusão de seus dados entrando em contato direto com a
      nossa equipe.
    </p>

    <div class="subtitulo">
      <h2>
        A seguir, você tem o detalhamento de nossa Política de Privacidade
        contemplando o processo de coleta, utilização, armazenamento e
        compartilhamento de Dados Pessoais.
      </h2>
    </div>
    <p>POLÍTICA DE PRIVACIDADE</p>
    <p>
      Na Newbacon, sempre prezamos pela responsabilidade e respeitos com os
      Dados Pessoais de clientes, parceiros, colaboradores e todas as pessoas
      que acessam nossas plataformas digitais.
    </p>
    <p>
      Desenvolvemos a nossa Política de Privacidade com objetivo de fornecer
      informações claras e transparentes para você, de como é feito o tratamento
      de dados pessoais realizados pela Newbacon ao utilizar seus sites,
      portais, produtos e serviços.
    </p>
    <p>COLETA DE DADOS</p>
    <p>
      Os Dados Pessoais são coletados quando existe um relacionamento comercial
      ou profissional conosco, podendo ser capturado através das nossas
      plataformas digitais ou em contato direto com os nossos colaboradores.
      Quando você nos visita, são capturados alguns Dados Pessoais na nossa
      recepção para garantir a segurança e acessos a serviços como WiFi por
      exemplo. Utilizamos sistemas de captura de imagem (Câmeras de Segurança)
      como também sistemas de controle de acesso aos ambientes.
    </p>
    <p>
      Além disso, nosso Site pode moldar os conteúdos oferecidos a você de
      acordo com suas escolhas de navegação. Ao fazer seu acesso sem desabilitar
      tecnologias, como “Identificadores Anônimos”, “cookies”, você
      expressamente escolhe este tipo de navegação voltado exclusivamente aos
      seus interesses e, portanto, autoriza o armazenamento de informações por
      meio destas tecnologias.
    </p>
    <p>
      As informações fornecidas serão tratadas como sigilosas e armazenadas de
      forma segura.
    </p>
    <p>COMO OS DADOS SERÃO UTILIZADOS</p>
    <p>
      As informações que você compartilha com a Newbacon têm como finalidade a
      gestão, administração, prestação, ampliação e melhoramento da experiência
      em nossos Sites e Serviços, considerando a possibilidade de o usuário
      moldar a sua navegação em relação aos dados que lhe são relevantes.
    </p>
    <p>COMPARTILHAMENTO DOS DADOS</p>
    <p>
      A Newbacon não compartilha os dados por você fornecidos com terceiros.
    </p>
    <p>EXCLUSÃO DE DADOS</p>
    <p>
      Caso você não queira mais que seus dados sejam utilizados, entre em
      contato com a equipe Newbacon e solicite a exclusão definitiva de suas
      informações.
    </p>
    <p>MUDANÇAS/ALTERAÇÕES</p>
    <p>
      Nossa política de privacidade está sujeita a atualizações devido ao
      compromisso que temos em aprimorar nossos serviços, por isso, recomendamos
      que seja consultada periodicamente.
    </p>
    <p>SOBRE A LGPD</p>
    <p>
      A LGPD, sigla que representa a Lei Geral de Proteção de Dados, de nº
      13.709/2018, surgiu para estabelecer regras que ofereçam mais clareza e
      transparência como as empresas coletam, armazenam, processam e
      compartilham seus dados pessoais, como nome, endereço de e-mail, telefone
      por exemplo.
    </p>
    <p>
      <strong
        >Veja alguns conceitos básico que irão te ajudar a ter uma melhor
        compreensão sobre o assunto.</strong
      >
    </p>

    <!-- --- -->
    <p>
      <strong>Bases da Lei</strong> <br />
      São as premissas legais que estabelecem o modelo para o cumprimento do
      tratamento de Dados Pessoais.
    </p>
    <p>
      <strong>Dado Pessoal</strong><br />
      Qualquer informação relativa a uma pessoa física, que seja capaz de levar
      a sua identificação como por exemplo: Nome completo, CPF, endereço, …
    </p>
    <p>
      <strong>Titular dos Dados</strong><br />

      Você é o titular do seus Dados, ou seja, o titular é a pessoa física a
      quem os Dados Pessoais se referem. Seja na condição de cliente, parceiro,
      colaborador ou usuários.
    </p>
    <p>
      <strong>Consentimento</strong><br />

      É a sua autorização para tratamento de dados pessoais, consentida através
      de forma explicita, clara, livre, informada e inequívoca para uma
      finalidade específica que serão tratados os seus dados.
    </p>

    <p>
      <strong>Dado Pessoal Sensível</strong><br />

      É qualquer informação relativa à raça/etnia, religião, opinião política,
      sexualidade e dados genéticos ou biométricos (como a biometria facial ou o
      DNA de um indivíduo). São considerados dados sensíveis por haver a real
      possibilidade de mau uso para fins discriminatórios e prejudiciais ao
      indivíduo.
    </p>
    <p>
      <strong>Encarregado</strong><br />

      É o colaborador encarregado de Proteção de Dados Pessoais (Data Protection
      Officer). Ele é o responsável pelas decisões relacionadas ao tratamento de
      Dados Pessoais e tem o papel de intermediar a comunicação entre os
      titulares, o controlador e a Autoridade Nacional de Proteção de Dados.
    </p>
    <p>
      <strong>Legítimo Interesse</strong><br />

      Permite que Dados Pessoais sejam tratados, sempre que o uso seja
      necessário ao atendimento de interesses legítimos nossos, como no contexto
      de atendimento e prestação de serviços, no qual pode ser necessário que
      você forneça algumas confirmações de seus Dados Pessoais para que tenhamos
      garantia de segurança e de que não se trata de uma fraude. Utilizar os
      dados pessoais coletados para fins de e-mail marketing, por exemplo,
      também se enquadra dentro do legítimo interesse do controlador. É
      importante que somente os dados estritamente necessários sejam utilizados.
      Dessa forma, preserva-se tanto o legítimo interesse do controlador quanto
      a integridade dos dados pessoais do titular.
    </p>
    <p>
      <strong>Tratamentos</strong><br />

      Toda e qualquer ação realizada com os dados, desde a coleta e
      armazenamento até o compartilhamento e uso. O ciclo completo de um dado
      pessoal, portanto, começa na coleta e termina na exclusão ou anonimização.
      O ciclo do tratamento contempla: coleta, armazenamento, consulta, uso,
      compartilhamento, transmissão, classificação, reprodução, exclusão,
      inativação, enriquecimento e avaliação.
    </p>
    <p>
      <strong>Cookies</strong><br />

      Tecnologia digital onde é possível transferir dados para o seu navegador
      (Browser), que nos permitem identificar o seu usuário, bem como perfil de
      navegação e/ou outras informações do seu dispositivo. Eles são utilizados
      para que possamos entender melhor as suas preferências e lhe oferecer
      produtos, serviços e experiências personalizadas.
    </p>

    <!-- .... -->
    <p>LISTA DE COOKIES</p>
    <p>
      Um cookie é um pequeno pedaço de dados (ficheiro de texto) que um site -
      quando visitado por um utilizador - pede ao seu navegador para armazenar
      no seu dispositivo, a fim de lembrar informações sobre si, tais como a sua
      preferência de idioma ou informações de login. Esses cookies são definidos
      por nós e são chamados de cookies primários. Também usamos cookies de
      terceiros - que são cookies de um domínio diferente do domínio do site que
      está a visitar - para as nossas iniciativas de publicidade e marketing.
      Mais especificamente, utilizamos cookies e outras tecnologias de
      localização para os seguintes fins:
    </p>
    <p>COOKIES DE DESEMPENHO</p>
    <p>
      Estes cookies permitem-nos contar visitas e fontes de tráfego, para que
      possamos medir e melhorar o desempenho do nosso website. Eles ajudam-nos a
      saber quais são as páginas mais e menos populares e a ver como os
      visitantes se movimentam pelo website. Todas as informações recolhidas por
      estes cookies são agregadas e, por conseguinte, anónimas. Se não permitir
      estes cookies, não saberemos quando visitou o nosso site.
    </p>
    <table>
      <thead>
        <tr>
          <th>Subgrupo de cookies</th>
          <th>Cookies</th>
          <th>Cookies utilizados</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>blog.newbacon.com</td>
          <td>__utma, __utmc, __utmz</td>
          <td>Próprio</td>
        </tr>
        <tr>
          <td>newbacon.com</td>
          <td>__utmb, _gat_UA-, __utmt_UA-nnnnnnnn, _ga, _gid, _gclxxxx</td>
          <td>Próprio</td>
        </tr>
      </tbody>
    </table>
    <p>COOKIES DE PUBLICIDADE</p>
    <p>
      Estes cookies podem ser estabelecidos através do nosso site pelos nossos
      parceiros de publicidade. Podem ser usados por essas empresas para
      construir um perfil sobre os seus interesses e mostrar-lhe anúncios
      relevantes em outros websites. Eles não armazenam diretamente informações
      pessoais, mas são baseados na identificação exclusiva do seu navegador e
      dispositivo de internet. Se não permitir estes cookies, terá menos
      publicidade direcionada.
    </p>
    <table>
      <thead>
        <tr>
          <th>Subgrupo de cookies</th>
          <th>Cookies</th>
          <th>Cookies utilizados</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>newbacon.com</td>
          <td>_fbp, _gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx</td>
          <td>Próprio</td>
        </tr>
        <tr>
          <td>doubleclick.net</td>
          <td>test_cookie</td>
          <td>Terceiro</td>
        </tr>
        <tr>
          <td>ads.linkedin.com</td>
          <td>lang</td>
          <td>Terceiro</td>
        </tr>
        <tr>
          <td>www.linkedin.com</td>
          <td>bscookie</td>
          <td>Terceiro</td>
        </tr>
        <tr>
          <td>www.facebook.com</td>
          <td></td>
          <td>Terceiro</td>
        </tr>
        <tr>
          <td>youtube.com</td>
          <td>ysc, consent, visitor_info1_live</td>
          <td>Terceiro</td>
        </tr>
        <tr>
          <td>linkedin.com</td>
          <td>
            bcookie, lidc, lang, AnalyticsSyncHistory, li_gc, UserMatchHistory
          </td>
          <td>Terceiro</td>
        </tr>
      </tbody>
    </table>

    <p>COOKIES ESTRITAMENTE NECESSÁRIOS</p>
    <p>
      Estes cookies são necessários para que o website funcione e não podem ser
      desligados nos nossos sistemas. Normalmente, eles só são configurados em
      resposta a ações levadas a cabo por si e que correspondem a uma
      solicitação de serviços, tais como definir as suas preferências de
      privacidade, iniciar sessão ou preencher formulários. Pode configurar o
      seu navegador para bloquear ou alertá-lo(a) sobre esses cookies, mas
      algumas partes do website não funcionarão. Estes cookies não armazenam
      qualquer informação pessoal identificável.
    </p>
    <table>
      <thead>
        <tr>
          <th>Subgrupo de cookies</th>
          <th>Cookies</th>
          <th>Cookies utilizados</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>.newbacon.com</td>
          <td>OptanonAlertBoxClosed, OptanonConsent</td>
          <td>Próprio</td>
        </tr>
      </tbody>
    </table>
    <div class="subtitulo footer-pv">
      <span>CONTATE-NOS</span>

      <p>
        Se você tiver dúvidas sobre os aspectos de privacidade do nosso Site ou
        quiser fazer uma reclamação, entre em contato conosco em
        <span> dpo@newbacon.com.</span>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermosDeUso",
};
</script>

<style scoped>
.container {
  max-width: 60%;
  margin-bottom: 150px;
}
h1,
p {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: lighter;
}
h1 {
  text-align: center;
  margin: 80px auto;
  letter-spacing: 2.4px;
}
p {
  margin: 0;
  line-height: 24px;
}
p + p {
  margin-top: 20px !important;
}
.titulo {
  margin: 0 auto;
}
h2 {
  font-size: 50px;
  font-family: "Roboto Slab";
  line-height: 59px;
  margin-bottom: 60px;
}
.subtitulo {
  margin: 50px auto;
  padding: 30px 0;
  border: 1px solid #000;
  border-left: none;
  border-right: none;
}
.subtitulo h2 {
  margin: 0;
  font-size: 25px;
  line-height: 24px;
}
.footer-pv {
  border-bottom: none;
}
.footer-pv span {
  font-weight: 600;
}
.footer-pv p {
  margin-top: 25px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 40px 0;
  border-radius: 20px;
}

th,
td {
  /* border: 1px solid #e5e5e5; */
  padding: 30px 20px;
  text-align: left;
  font-size: 16px;
  line-height: 1.4;
}

th {
  background-color: #000;
  color: #fff;
}
/* th:nth-child(1) {
  border-top-left-radius: 20px;
}
th:nth-child(3) {
  border-top-right-radius: 20px;
} */
td {
  border: 1px solid;
}

@media (max-width: 989px) {
  .container {
    max-width: 100%;
  }
  h1 {
    margin: 40px auto;
    font-size: 12px;
  }
  h2 {
    margin-bottom: 60px;
    font-size: 32px;
    line-height: 34px;
    margin: 30px auto;
  }
  th,
  td {
    padding: 10px;
    font-size: 13px;
  }
}
</style>