<template>
  <section class="container backoffice">
    <h1>👤 Listagem de Leads</h1>
    <p>Total de leads: {{ leads.totalCount || 0 }}</p>

    <div class="tabela" v-show="!loading" data-anima="bottom">
      <table>
        <thead>
          <tr>
            <th>#ID</th>
            <th>Nome do Lead</th>
            <th>Empresa</th>
            <th>E-mail</th>
            <th>Celular</th>
            <th>Assunto</th>
            <th>Mensagem</th>
            <!-- <th>Ações</th> -->
          </tr>
        </thead>
        <tbody v-if="leads.items?.length">
          <tr v-for="item in leads.items" :key="item.id">
            <td>#{{ item.id }}</td>
            <td>{{ item.nome }}</td>
            <td>{{ item.empresa }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.celular }}</td>
            <td>
              {{ item.assunto }}
            </td>
            <td class="msg-item">
              {{ item.mensagem }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!leads.items?.length && !loading" class="feedback">
        Nenhum dado encontrado
      </div>
    </div>
    <div class="paginacao" v-if="leads.items?.length && !loading">
      <div class="total-pages">
        <select
          name="total-pages"
          id="total-pages"
          v-model="filtros.pageSize"
          @change="fetchLeads"
        >
          <option value="10">10 por página</option>
          <option value="20">20 por página</option>
          <option value="30">30 por página</option>
          <option value="50">50 por página</option>
        </select>
      </div>
      <div class="paginas">
        <ul>
          <li
            class="page-anterior"
            @click="paginacao('ANTERIOR')"
            v-if="leads.hasPreviousPage"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/keyboard_arrow_down_FILL0_wght200_GRAD0_opsz48.svg"
              alt="seta"
            />
            Anterior
          </li>
          <li>Página {{ leads.page }}</li>
          <li
            class="page-proximo"
            @click="paginacao('PROXIMO')"
            v-if="leads.hasNextPage"
          >
            Próximo
            <img
              loading="lazy"
              src="@/assets/img/svg/keyboard_arrow_down_FILL0_wght200_GRAD0_opsz48.svg"
              alt="seta"
            />
          </li>
        </ul>
      </div>
    </div>
    <Loading v-if="loading" data-anima="bottom" />
  </section>
</template>

<script>
import { api } from "@/services";

export default {
  name: "ListagemLeads",
  data() {
    return {
      loading: false,

      leads: [],
      filtros: {
        page: 1,
        pageSize: 10,
      },
    };
  },

  methods: {
    paginacao(type) {
      switch (type) {
        case "ANTERIOR": {
          this.filtros.page--;
          break;
        }
        case "PROXIMO": {
          this.filtros.page++;
          break;
        }
      }
    },
    fetchLeads() {
      this.loading = true;
      api
        .get(
          `/lead?page=${this.filtros.page}&pageSize=${this.filtros.pageSize}`
        )
        .then((resp) => {
          this.leads = resp.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
  mounted() {
    this.fetchLeads();
  },
};
</script>

<style scoped>
h1 {
  font-family: "Roboto Slab";
  font-size: 28px;
  margin-bottom: 10px;
}
.filtros {
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  justify-content: space-between;
}

.filtros .item-form + .item-form {
  margin: 0;
  flex: 1;
}
.backoffice input,
.backoffice select,
.backoffice textarea {
  padding: 15px 10px;
}

.backoffice button {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 40px 0;
}

th,
td {
  /* border: 1px solid #e5e5e5; */
  padding: 15px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
}

th {
  background-color: #f7f7f7;
}

.actions {
  display: flex;
  gap: 10px;
}
.actions .btn {
  padding: 5px 15px;
  height: 40px;
  font-size: 13px;
}
.check-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.check-flex label {
  margin: 0;
}
.feedback {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  padding: 20px 30px;
  max-width: 300px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
}
.paginacao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px 0;
  margin-bottom: 60px;
}
.paginacao .paginas ul,
.paginacao .paginas ul li {
  display: flex;
  align-items: center;
  gap: 15px;
}
.paginacao .paginas ul li {
  gap: 10px;
  transition: 0.3s;
  background: #f7f7f7;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 15px;
}
.paginacao .paginas ul li.page-anterior img {
  transform: rotate(90deg);
}
.paginacao .paginas ul li.page-proximo img {
  transform: rotate(-90deg);
}
.paginacao .paginas ul li:hover {
  font-weight: 600;
  cursor: pointer;
}
.msg-item {
  max-width: 300px;
}
</style>