<template>
  <div id="app">
    <TheMenu v-if="!rotas_login" />

    <TheMenuLogado v-if="rotas_login && usuario_logado" />

    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>

    <TheFooter v-if="!rotas_login" id="contato" />
  </div>
</template>

<script>
import TheMenu from "@/components/Base/TheMenu.vue";
import TheMenuLogado from "@/components/Base/TheMenuLogado.vue";
import TheFooter from "@/components/Base/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheMenu,
    TheFooter,
    TheMenuLogado,
  },
  computed: {
    rotas_login() {
      return this.$route.meta.backoffice || false;
    },
    usuario_logado() {
      return this.$store.state.login || window.localStorage.token;
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang.split("-")[0];
      this.$store.dispatch("alterarIdioma", lang);
    },
    checkIfMobile() {
      const isMobile = window.innerWidth < 768;
      this.$store.dispatch("checkDevice", isMobile);
    },
  },

  mounted() {
    document.documentElement.className += " js-scroll";
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  created() {
    this.changeLanguage(sessionStorage.idioma || "pt-br");
  },
};
</script>


<style>
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: bolder;
  font-display: swap;
  src: url("./assets/fonts/RobotoSlab-Black.ttf") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Regular.ttf") format("woff2");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Medium.ttf") format("woff2");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Bold.ttf") format("woff2");
}

@import url("./assets/css/main.css");
@import url("./assets/css/backoffice.css");
</style>
