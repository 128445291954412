<template>
  <div class="carrosel-item" :class="name">
    <span
      v-for="(item, index) in carrosel_itens"
      :key="index"
      @click="clickItem(index)"
      :class="{ ativo: index == currentIndex }"
    ></span>
  </div>
</template>

<script>
export default {
  name: "CarroselFake",
  props: {
    carrosel_itens: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      total: 0,
      currentIndex: 0,
    };
  },
  watch: {
    carrosel_itens() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.carrosel_itens.length) {
        this.total = this.carrosel_itens.length;
        this.$nextTick(() => {
          this.animateCarrossel();
        });
      }
    },
    animateCarrossel() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.total;
        this.clickItem(this.currentIndex);
      }, 5000);
    },
    clickItem(index) {
      if (this.name == "case-carrousel") {
        this.$emit("clickItemCarroselPrimeiraQuebra", index);
      } else {
        this.$emit("clickItemCarroselSolucoes", index);
      }
      this.currentIndex = index;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.carrosel-item {
  display: flex;
  align-items: center;
  max-width: 450px;
  gap: 15px;
  margin: 20px 0;
}

.carrosel-item span {
  height: 8px;
  width: 100%;
  background-color: #d9d9d9;
  cursor: pointer;
  flex: 1;
  min-width: 70px;
  max-width: 100px;
}

.carrosel-item span.ativo {
  /* background-color: #000; */
}
.carrosel-item span.ativo::before {
  content: "";
  width: 0%;
  height: 8px;
  background: #000;
  position: relative;
  display: block;
  animation: load 5s forwards;
}
@keyframes load {
  from {
    width: 1%;
  }

  to {
    width: 100%;
  }
}

@media (max-width: 989px) {
  .carrosel-item {
    max-width: 100%;
    gap: 10px;
  }
  .carrosel-item span {
    height: 5px;
    min-width: initial;
  }
}
</style>
