<template>
  <section>
    <div class="bg-item" ref="bgCase">
      <div class="container primeira-quebra">
        <div>
          <div>
            <div class="titulo">
              <h1 data-anima="left" ref="primeiraQuebraH1">
                {{
                  $i18n.locale == "pt"
                    ? item_ativo?.tituloBr
                    : item_ativo?.tituloEn
                }}
              </h1>
              <button
                v-if="item_ativo?.tituloBr"
                class="btn btn-4 btn-ver-case"
                @click.prevent="visualizarCase"
              >
                {{ $i18n.locale == "pt" ? "VER CASE" : "VIEW CASE" }}
                <img
                  loading="lazy"
                  src="@/assets/img/svg/visibility_FILL0_wght200_GRAD0_opsz24.svg"
                  class="icone-ver"
                  alt="ver case icone"
                  width="30"
                  height="30"
                />
              </button>
            </div>

            <CarroselFake
              data-anima="bottom"
              name="case-carrousel"
              :carrosel_itens="carrosel_itens"
              @clickItemCarroselPrimeiraQuebra="clickItemCarrosel"
            />
          </div>

          <!-- <div data-anima="bottom">
            <p>{{ $i18n.locale == "pt" ? "Olá" : "Hello" }} <b>Marcelo</b></p>
            <span v-if="$i18n.locale == 'pt'">
              Por a caso gostaria de ver alguns cases <br />que construímos
              juntos?
            </span>
            <span v-if="$i18n.locale == 'en'">
              In case I would like to see some cases that<br />
              we built together?
            </span>
            <input
              type="text"
              :placeholder="
                $i18n.locale === 'pt'
                  ? '💬 Digite a sua resposta'
                  : '💬 Type your answer'
              "
            />
          </div> -->
        </div>
        <!-- src="@/assets/img/bg/carousel_1920x845.png" -->
        <!-- <img
          v-show="item_ativo?.imagemCarrouselDesk"
          :src="item_ativo?.imagemCarrouselDesk"
          data-anima="right"
          :alt="item_ativo?.textoAltCorrouselBr"
          class="bg-item"
        /> -->
      </div>
    </div>
  </section>
</template>

<script>
import CarroselFake from "../Base/CarroselFake.vue";
// import a from '../../assets/img/bg-fake.png'

export default {
  name: "PrimeiraQuebra",
  components: {
    CarroselFake,
  },
  props: {
    cases_home: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      item_ativo: {
        titulo: "",
        imagemCarrouselDesk: "",
        imagemCarrouselMobile: "",
      },
      carrosel_itens: [],
    };
  },
  watch: {
    // isMobile() {
    //   this.addBg(this.item_ativo);
    // },
    cases_home(value) {
      this.carrosel_itens = value;
      this.item_ativo = this.carrosel_itens[0];
      this.addBg(this.item_ativo);
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
  methods: {
    visualizarCase() {
      this.$router.push(`/cases/${this.item_ativo.id}`);
    },
    addBg(item) {
      this.$refs.bgCase.style.background = `url('${
        this.isMobile ? item.imagemCarrouselMobile : item.imagemCarrouselDesk
      }') center center no-repeat`;
      this.$refs.bgCase.style.backgroundSize = `cover`;
    },

    clickItemCarrosel(index) {
      this.item_ativo = this.carrosel_itens[index];

      this.$refs.primeiraQuebraH1.classList.add("hide");

      this.$nextTick(() => {
        this.$refs.primeiraQuebraH1.classList.remove("hide");
        this.addBg(this.item_ativo);
      });
    },
  },
};
</script>

<style scoped>
.primeira-quebra {
  min-height: 90vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}
.primeira-quebra h1 {
  font-size: 45px;
  font-family: "Roboto Slab";
  line-height: 50px;
  display: block;
  min-height: 240px;
}
.titulo {
  margin-top: 25%;
  min-height: 150px;
  margin-bottom: 32px;
}
.primeira-quebra h1.hide {
  display: none;
}
.primeira-quebra p,
.primeira-quebra span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.primeira-quebra input {
  background: transparent;
  max-width: 250px;
  display: block;
  margin-top: 25px;
  padding: 0 0 10px 0;
  height: 40px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid transparent;
}
.primeira-quebra input,
.primeira-quebra input::placeholder {
  font-size: 18px;
}
.primeira-quebra input:hover,
.primeira-quebra input:focus {
  border-color: var(--secondary-color);
}
.bg-item {
  /* position: absolute; */
  z-index: -1;
  transition: 0.3s;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  background-size: cover;
  background: url("") center center no-repeat;
}
@media (max-width: 989px) {
  .primeira-quebra {
    grid-template-columns: 1fr;
    margin: 0 auto;
    min-height: initial;
  }
  .primeira-quebra h1 {
    font-size: 24px;
    line-height: 26px;
    max-width: 95%;
  }
  .btn-ver-case {
    margin-top: 30px;
  }
  .bg-item {
    max-height: 55vh;
    /* margin-top: -40px; */
  }
  .titulo {
    margin-top: 280px;
    min-height: initial;
  }
  .primeira-quebra input {
    max-width: 100%;
  }
  .primeira-quebra h1 {
    min-height: 100px;
  }
}
.btn-ver-case {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
}
.btn-ver-case img {
  max-width: 30px;
}
</style>