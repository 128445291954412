<template>
  <div>
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  mounted() {
    this.quill = new Quill(this.$refs.editor, {
      theme: "snow",
      placeholder: "Insira o conteúdo",
    });
  },
  methods: {
    getHTML() {
      return this.quill.root.innerHTML;
    },
    setHTML(content) {
      this.quill.root.innerHTML = content;
    },
  },
};
</script>

