import Vue from 'vue'
// import store from '@/store/index'
import VueRouter from 'vue-router'
import Home from '@/views/HomeView.vue'
import Cases from '@/views/CasesView.vue'
import BaconNews from '@/views/BaconNews.vue'
import SolucoesView from '@/views/SolucoesView.vue'
import SobreNosView from '@/views/SobreNosView.vue'
import TermosDeUso from '@/views/TermosDeUso.vue'
import PoliticaDePrivacidade from '@/views/PoliticaDePrivacidade.vue'
import DetalheSolucoes from '@/views/DetalheSolucoes.vue'
import CaseItem from '@/views/CaseItem.vue'
import CaseRedirect from '@/views/CaseRedirect.vue'
import SolucaoRedirect from '@/views/SolucaoRedirect.vue'
import Login from '@/views/Backoffice/Login.vue'
import ListagemCases from '@/views/Backoffice/ListagemCases.vue'
import ListagemLeads from '@/views/Backoffice/ListagemLeads.vue'
import NovoCase from '@/views/Backoffice/NovoCase.vue'
import PaginaNaoEncontrada from '@/components/Base/PaginaNaoEncontrada.vue'
Vue.use(VueRouter)

import {
  alterarIdiomaGlobal
} from '@/helpers.js'

const routes = [

  {
    path: '/',
    name: 'Transformamos dados em comunicação eficiente',
    component: Home
  },
  {
    path: '/pt',
    name: 'Transformamos dados em comunicação eficiente -',
    component: Home,
  },
  {
    path: '/en',
    name: 'We transform data into efficient communication',
    component: Home,
  },
  {
    path: '/cases/',
    component: Cases,
    children: [{
        path: '',
        name: 'Cases',
        component: Cases,
      },
      {
        path: 'pt',
        name: 'Cases --',
        component: Cases,
      },
      {
        path: 'en',
        name: 'Cases -',
        component: Cases,
      },
    ]
  }, {
    path: '/bacon-news',
    // name: 'Bacon News',
    component: BaconNews,
    children: [{
        path: '',
        name: 'Bacon News',
        component: BaconNews,
      },
      {
        path: ':lang',
        name: 'Bacon News -',
        component: BaconNews,
      },
    ]
  },
  {
    path: '/cases/:id',
    // name: 'Case',
    component: CaseItem,
    children: [{
        path: '',
        name: 'Case',
        component: CaseItem,
      },
      {
        path: ':lang',
        name: 'CaseItem -',
        component: CaseItem,
      },

    ]
  },
  {
    path: '/cases/redirect/:id/:lang',
    name: 'Case redirect -',
    component: CaseRedirect
  },
  {
    path: '/solucao/redirect/:nome_solucao/:lang',
    name: 'Solucao redirect',
    component: SolucaoRedirect
  },
  {
    path: '/solucao/redirect/:nome_solucao/',
    name: 'Solucao redirect-',
    component: SolucaoRedirect
  },
  {
    path: '/solucoes',
    // name: 'Soluções',
    component: SolucoesView,
    children: [{
        path: '',
        name: 'Soluções',
        component: SolucoesView,
      },
      {
        path: 'pt',
        name: 'Soluções -Pt',
        component: SolucoesView,
      },
      {
        path: 'en',
        name: 'Soluções -En',
        component: SolucoesView,
      },
    ]
  },
  {
    path: '/solucoes/:solucao',
    // name: 'Detalhes Soluções',
    component: DetalheSolucoes,
    children: [{
        path: '',
        name: 'Detalhes Soluções',
        component: SolucoesView,
      },
      {
        path: ':lang',
        name: 'Detalhes Soluções -',
        component: SolucoesView,
      },
    ]
  },




  {
    path: '/sobre-nos',
    // name: 'Sobre nós',
    component: SobreNosView,
    children: [{
        path: '',
        name: 'Sobre nós',
        component: SobreNosView,
      },
      {
        path: ':lang',
        name: 'Sobre nós -',
        component: SobreNosView,
      },

    ]

  },
  {
    path: '/termos-de-uso',
    name: 'Termos de uso',
    component: TermosDeUso
  },
  {
    path: '/politica-de-privacidade',
    name: 'Política de privacidade',
    component: PoliticaDePrivacidade
  },

  {
    path: '/backoffice',
    name: 'Backoffice ',
    meta: {
      backoffice: true,
    },
    component: Login,
    redirect: () => {
      return {
        path: "/backoffice/login",
      };
    },
  },
  {
    path: '/backoffice/login',
    name: 'Backoffice | Login',
    meta: {
      backoffice: true,
    },
    component: Login
  },
  {
    path: '/backoffice/cases',
    name: 'Backoffice | Cases',
    meta: {
      backoffice: true,
      login: true
    },
    component: ListagemCases
  },
  {
    path: '/backoffice/cases/novo',
    name: 'Backoffice | Novo Case',
    meta: {
      backoffice: true,
      login: true
    },
    component: NovoCase
  },
  {
    path: '/backoffice/cases/editar/:id',
    name: 'Backoffice | Editar Case',
    meta: {
      backoffice: true,
      login: true
    },
    component: NovoCase
  },
  {
    path: '/backoffice/leads',
    name: 'Backoffice | Leads',
    meta: {
      backoffice: true,
      login: true
    },
    component: ListagemLeads
  },
  {
    path: '*',
    name: 'PaginaNaoEncontrada',
    component: PaginaNaoEncontrada
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
})

router.beforeEach((to, from, next) => {
  document.title = `Newbacon | ${to.name || ''}`;


  if (to.matched.some(record => record.meta.login)) {
    if (!window.localStorage.token) {
      next('/backoffice/login');
    } else {
      next();
    }
  } else {
    next();
  }

  setTimeout(() => {
    alterarIdiomaGlobal(to, from)
  }, 200);


})


export default router