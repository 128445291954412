<template>
  <div>
    <form @submit.prevent="submitSetup" data-anima="bottom">
      <div class="item-case">
        <h2 id="title-setup">
          Setup <Loading :mini="true" :no_space="true" v-if="loading" />
        </h2>

        <div class="content-item">
          <div class="itens-flex">
            <div class="item-form" v-if="configs_cases.status.length">
              <label for="status">Status</label>
              <select
                name="status"
                id="status"
                v-model="setup_item.idStatus"
                :disabled="loading || (setup_item.id && !edit_case)"
                required
              >
                <option
                  :value="status.id"
                  v-for="status in configs_cases.status"
                  :key="status.id"
                >
                  {{ status.descricao }}
                </option>
              </select>
              <!-- <div>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="setup_item.status"
                    :value="setup_item.status == 'ATIVO' ? true : false"
                  />
                  <span class="slider round"></span>
                </label>
              </div> -->
              <!-- <div class="d-flex">
              <input type="checkbox" checked id="status" />
              <label for="status">Case Ativo</label>
            </div> -->
            </div>

            <div class="item-form" v-if="configs_cases.cliente.length">
              <label for="clientes">Clientes</label>
              <select
                name="clientes"
                id="clientes"
                v-model="setup_item.idEmpresa"
                :disabled="loading || (setup_item.id && !edit_case)"
                required
              >
                <option
                  :value="cliente.id"
                  v-for="cliente in configs_cases.cliente"
                  :key="cliente.id"
                >
                  {{ cliente.nome }}
                </option>
              </select>
            </div>
            <div class="item-form">
              <label for="marca">Marca</label>
              <input
                list="marca"
                name="marca"
                v-model="setup_item.marca"
                required
              />

              <datalist id="marca">
                <option
                  :value="marca.descricao"
                  v-for="marca in configs_cases.marcas"
                  :key="marca.descricao"
                >
                  {{ marca.descricao }}
                </option>
              </datalist>
            </div>

            <div class="item-form" v-if="configs_cases.industria.length">
              <label for="industria">Industria</label>
              <select
                name="industria"
                id="industria"
                v-model="setup_item.idIndustria"
                required
                :disabled="loading || (setup_item.id && !edit_case)"
              >
                <option
                  :value="industria.id"
                  v-for="industria in configs_cases.industria"
                  :key="industria.id"
                >
                  {{ industria.descricao }}
                </option>
              </select>
            </div>

            <div class="item-form">
              <label for="bg-color">Background color - Hexa</label>
              <input
                type="color"
                id="bg-color"
                v-model="setup_item.bgCor"
                :disabled="loading || (setup_item.id && !edit_case)"
              />
            </div>

            <div class="item-form">
              <label for="font-color">Font color - Hexa</label>
              <input
                type="color"
                id="font-color"
                v-model="setup_item.fonteCor"
                required
                :disabled="loading || (setup_item.id && !edit_case)"
              />
            </div>

            <div class="item-form">
              <label for="carrossel-home">Carrossel Homepage</label>
              <select
                name="carrossel-home"
                id="carrossel-home"
                v-model="setup_item.fgCarrousel"
                required
                :disabled="loading || (setup_item.id && !edit_case)"
              >
                <option value="false">Não mostrar</option>
                <option value="true">Mostrar na home</option>
              </select>
            </div>
          </div>
          <div class="btn-industria">
            <button class="btn mini-btn" @click.prevent="novaIndustria">
              Add industria
            </button>
          </div>

          <div class="campos-setup">
            <div class="grid-item">
              <div>
                <div class="item-form">
                  <label for="titulo-br">
                    (BR) - Título do Case
                    <span>({{ setup_item.tituloBr.length }}/85 crt)</span>
                  </label>
                  <!-- maxlength="85" -->
                  <textarea
                    v-model="setup_item.tituloBr"
                    name="titulo-br"
                    id="titulo-br"
                    placeholder="Título do case com até 85 caracteres, incluindo espaço em branco e números e pontos"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  ></textarea>
                </div>

                <div class="item-form">
                  <label for="acao-br">
                    (BR) - Chamada para soluções
                    <span>({{ setup_item.chamadaBr.length }}/45 crt)</span>
                  </label>
                  <!-- maxlength="45" -->
                  <input
                    v-model="setup_item.chamadaBr"
                    type="text"
                    id="acao-br"
                    placeholder="Ex: CRM from zero to hero"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
              </div>

              <div>
                <div class="item-form">
                  <label for="titulo-en">
                    (EN) - Título do Case
                    <span>({{ setup_item.tituloEn.length }}/85 crt)</span>
                  </label>
                  <!-- maxlength="85" -->
                  <textarea
                    v-model="setup_item.tituloEn"
                    name="titulo-en"
                    id="titulo-en"
                    placeholder="Case title limited to 85 characters, including spaces, numbers, and periods."
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  ></textarea>
                </div>

                <div class="item-form">
                  <label for="acao-en">
                    (EN) - Chamada para soluções
                    <span>({{ setup_item.chamadaEn.length }}/45 crt)</span>
                  </label>
                  <!-- maxlength="45" -->
                  <input
                    v-model="setup_item.chamadaEn"
                    type="text"
                    id="acao-en"
                    placeholder="Ex: CRM from zero to hero"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="imagens-setup">
            <h2>Imagem Carousel</h2>
            <div class="itens-flex">
              <div class="item-form">
                <label for="img-desk-carousel">
                  imagem Carousel Home DESK:<br />
                  imagem png transparente com 1920x845px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemCarrouselDesk.file ||
                        setup_item.imagemCarrouselDesk.file ||
                        setup_item.imagemCarrouselDesk
                      "
                      :alt="setup_item.textoAltCorrouselBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-desk-carousel"
                    id="img-desk-carousel"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemCarrouselDesk')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-desk-carousel')"
                    >
                      Alterar imagem
                    </button> -->

                  <span>
                    Arquivo:
                    {{ setup_item.imagemCarrouselDesk.fileName }}</span
                  >
                </label>
              </div>

              <div class="item-form">
                <label for="img-mobile-carousel">
                  imagem Carousel Home Mobile:<br />
                  imagem png transparente com 720x600px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemCarrouselMobile.file ||
                        setup_item.imagemCarrouselMobile.file ||
                        setup_item.imagemCarrouselMobile
                      "
                      :alt="setup_item.textoAltCorrouselBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-mobile-carousel"
                    id="img-mobile-carousel"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemCarrouselMobile')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-mobile-carousel')"
                    >
                      Alterar imagem
                    </button> -->

                  <span>
                    Arquivo:
                    {{ setup_item.imagemCarrouselMobile.fileName }}</span
                  >
                </label>
              </div>

              <div class="item-form alts-carousel">
                <div class="item-alt">
                  <label for="alt-carousel-br"> (BR) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltCorrouselBr"
                    type="text"
                    id="alt-carousel-br"
                    placeholder="Case Data-driven Newbacon + Electrolux"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
                <div class="item-alt">
                  <label for="alt-carousel-en"> (EN) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltCarrouselEn"
                    type="text"
                    id="alt-carousel-en"
                    placeholder="Newbacon + Electrolux Data-driven Case"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="imagens-setup">
            <h2>Imagem Destaque</h2>
            <div class="itens-flex">
              <div class="item-form">
                <label for="img-desk-destaque">
                  imagem Destaque DESK & MOBILE: <br />
                  imagem png transparente com 600x560 px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemDestaque.file ||
                        setup_item.imagemDestaque.file ||
                        setup_item.imagemDestaque
                      "
                      :alt="setup_item.textoAltDestaqueBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-desk-destaque"
                    id="img-desk-destaque"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemDestaque')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-desk-destaque')"
                    >
                      Alterar imagem
                    </button> -->

                  <span>
                    Arquivo: {{ setup_item.imagemDestaque.fileName }}</span
                  >
                </label>
              </div>

              <div class="item-form">
                <label for="img-mobile-destaque">
                  imagem Efeito Fade DESK & MOBILE: <br />
                  imagem png transparente com 600x560 px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemDestaqueParalax.file ||
                        setup_item.imagemDestaqueParalax.file ||
                        setup_item.imagemDestaqueParalax
                      "
                      :alt="setup_item.textoAltDestaqueBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-mobile-destaque"
                    id="img-mobile-destaque"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemDestaqueParalax')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-mobile-destaque')"
                    >
                      Alterar imagem
                    </button> -->

                  <span>
                    Arquivo:
                    {{ setup_item.imagemDestaqueParalax.fileName }}</span
                  >
                </label>
              </div>

              <div class="item-form alts-destaque">
                <div class="item-alt">
                  <label for="alt-destaque-br"> (BR) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltDestaqueBr"
                    type="text"
                    id="alt-destaque-br"
                    placeholder="Case Data-driven Newbacon + Electrolux"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
                <div class="item-alt">
                  <label for="alt-destaque-en"> (EN) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltDestaqueEn"
                    type="text"
                    id="alt-destaque-en"
                    placeholder="Newbacon + Electrolux Data-driven Case"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="imagens-setup">
            <h2>Imagem Background</h2>
            <div class="itens-flex">
              <div class="item-form">
                <label for="img-desk-Background">
                  imagem bg DESK: <br />
                  imagem JPG transparente com 1920x845 px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemBgDesk.file ||
                        setup_item.imagemBgDesk.file ||
                        setup_item.imagemBgDesk
                      "
                      :alt="setup_item.textoAltBgBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-desk-Background"
                    id="img-desk-Background"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemBgDesk')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-desk-Background')"
                    >
                      Alterar imagem
                    </button> -->

                  <span> Arquivo: {{ setup_item.imagemBgDesk.fileName }}</span>
                </label>
              </div>

              <div class="item-form">
                <label for="img-mobile-Background">
                  imagem bg MOBILE:<br />
                  imagem JPG transparente com 720x1000 px

                  <div class="img-setup">
                    <img
                      loading="lazy"
                      :src="
                        imgAux.imagemBgMobile.file ||
                        setup_item.imagemBgMobile.file ||
                        setup_item.imagemBgMobile
                      "
                      :alt="setup_item.textoAltBgBr"
                    />
                  </div>
                  <input
                    type="file"
                    name="img-mobile-Background"
                    id="img-mobile-Background"
                    class="file-item"
                    accept="image/*"
                    @change="fileChange($event, 'imagemBgMobile')"
                    :required="!edit_case"
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                  <!-- <button
                      title="Clique para alterar"
                      class="btn btn-2-outline"
                      @click.prevent="clickItem('img-mobile-Background')"
                    >
                      Alterar imagem
                    </button> -->

                  <span>
                    Arquivo: {{ setup_item.imagemBgMobile.fileName }}</span
                  >
                </label>
              </div>

              <div class="item-form alts-Background">
                <div class="item-alt">
                  <label for="alt-Background-br"> (BR) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltBgBr"
                    type="text"
                    id="alt-Background-br"
                    placeholder="Case Data-driven Newbacon + Electrolux"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
                <div class="item-alt">
                  <label for="alt-Background-en"> (EN) - Texto ALT: </label>
                  <input
                    v-model="setup_item.textoAltBgEn"
                    type="text"
                    id="alt-Background-en"
                    placeholder="Newbacon + Electrolux Data-driven Case"
                    required
                    :disabled="loading || (setup_item.id && !edit_case)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="campos-setup">
            <div class="grid-item">
              <div>
                <div class="item-form">
                  <label for="solucao-setup"> Solução </label>
                  <select
                    name="solucao"
                    id="solucao"
                    :required="solucoes_itens.length == 0"
                    @change="changeSolucao"
                    :disabled="loading || (setup_item.id && !edit_case)"
                    v-model="setup_item.idSolucao"
                  >
                    <option
                      :value="solucao"
                      v-for="solucao in configs_cases.solucao"
                      :key="solucao.id"
                    >
                      {{ solucao.descricao }}
                    </option>
                  </select>
                  <div class="itens-solucao">
                    <div
                      class="item-solucao"
                      v-for="(item, index) in solucoes_itens"
                      :key="item.id"
                    >
                      {{ item.descricao }}
                      <a href="#" @click.prevent="removeSolucao(index)">X</a>
                    </div>
                  </div>
                </div>
                <span v-if="!configs_cases.solucao.length" class="valida-etapa"
                  >🚫 Nenhuma solução encontrada. Necessário cadastrar *</span
                >

                <!-- <ul class="lista-solucoes-setup">
                  <li>
                    - Planejamento do insight para o plano de ação:
                    <span>Automação de jornadas ultra personalizadas</span>
                  </li>
                  <li>- Execução criativa: <span>UX/UI</span></li>
                </ul> -->
              </div>

              <!-- <div>
                <div class="item-form">
                  <label for="servico-setup"> Serviço </label>
                  <select name="servico-setup" id="servico-setup">
                    <option value="null">Selecione o serviço</option>
                  </select>
                  <div class="btn-acao">
                    <button class="btn mini-btn">
                      Editar lista de soluções
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="flex-footer">
        <div>
          <button
            type="submit"
            class="btn"
            :disabled="
              loading ||
              !seo_id ||
              (setup_item.id && !edit_case) ||
              !configs_cases.solucao.length
            "
          >
            Salvar Setup
            <Loading v-if="loading" data-anima="top" :mini="true" />
          </button>
          <span v-if="!seo_id" class="valida-etapa"
            >🚫 Necessário completar a etapa SEO primeiro*</span
          >
        </div>
        <Notificacao
          :infos="notificacao_infos"
          v-if="notificacao_infos.mensagem"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { api } from "@/services";
export default {
  name: "SetupCase",
  data() {
    return {
      loading: false,
      setup_item: {
        idSolucao: null,
        idEmpresa: null,
        idStatus: null,
        idIndustria: null,
        tituloEn: "",
        tituloBr: "",
        chamadaEn: "",
        chamadaBr: "",
        bgCor: "#000000",
        fonteCor: "#000000",
        fgCarrousel: false,
        imagemCarrouselDesk: {
          fileName: "",
          file: "",
        },
        imagemCarrouselMobile: {
          fileName: "",
          file: "",
        },
        textoAltCorrouselBr: "",
        textoAltCarrouselEn: "",
        imagemDestaque: {
          fileName: "",
          file: "",
        },
        imagemDestaqueParalax: {
          fileName: "",
          file: "",
        },
        textoAltDestaqueBr: "",
        textoAltDestaqueEn: "",
        imagemBgDesk: {
          fileName: "",
          file: "",
        },
        imagemBgMobile: {
          fileName: "",
          file: "",
        },
        textoAltBgBr: "",
        textoAltBgEn: "",
        marca: "",
      },
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      configs_cases: {
        industria: [],
        cliente: [],
        solucao: [],
        status: [],
        marcas: [],
      },
      imgAux: {
        imagemBgDesk: {
          fileName: "",
          file: "",
        },
        imagemDestaque: {
          fileName: "",
          file: "",
        },
        imagemDestaqueParalax: {
          fileName: "",
          file: "",
        },
        imagemCarrouselDesk: {
          fileName: "",
          file: "",
        },
        imagemCarrouselMobile: {
          fileName: "",
          file: "",
        },
        imagemBgMobile: {
          fileName: "",
          file: "",
        },
      },
      solucoes_itens: [],
    };
  },
  props: {
    case_item: {
      type: Object,
      default: () => {},
    },
    case_id: {
      type: Number,
      default: -1,
    },
    seo_id: {
      type: Number,
      default: -1,
    },
    edit_case: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeSolucao(index) {
      this.solucoes_itens.splice(index, 1);
    },
    changeSolucao() {
      const contemIndex = this.solucoes_itens.findIndex(
        (item) => item.id == this.setup_item.idSolucao.id
      );

      if (contemIndex == -1) {
        this.solucoes_itens.push(this.setup_item.idSolucao);
      }

      this.setup_item.idSolucao = null;
    },
    novaIndustria() {
      const res = window.prompt("Nova Industria:");
      if (res) {
        this.createIndustria(res);
      }
    },
    createIndustria(descricao) {
      this.loading = true;
      const data = {
        descricao,
        ativo: true,
      };

      api
        .post("/industria", data)
        .then(() => {
          this.fetchConfigCases();
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Nova industria salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickItem(item) {
      document.querySelector(`#${item}`).click();
    },
    jsonToFormData(json) {
      const formData = new FormData();

      Object.keys(json).forEach((key) => {
        this.appendToFormData(formData, key, json[key]);
      });

      return formData;
    },

    appendToFormData(formData, key, value) {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          this.appendToFormData(formData, `${key}[${index}]`, element);
        });
      } else if (typeof value === "object" && value !== null) {
        Object.keys(value).forEach((subKey) => {
          this.appendToFormData(formData, `${key}.${subKey}`, value[subKey]);
        });
      } else {
        if (value) formData.append(key, value);
      }
    },
    getImageBase64(event, item) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imgAux[item] = {
          file: reader.result,
          fileName: file.name,
        };
      };
      reader.readAsDataURL(file);
    },
    fileChange(event, item) {
      this.setup_item[item] = {
        fileName: "",
        file: "",
      };
      const file = event.target.files[0];
      if (file) {
        this.setup_item[item].file = file;
        this.setup_item[item].fileName = file.name;

        this.getImageBase64(event, item);
      }
    },
    formatFilesEdit() {
      this.setup_item.textoAltCarrouselBr = this.setup_item.textoAltCorrouselBr;
      if (this.edit_case) {
        return {
          ...this.setup_item,
          industria: null,
          status: null,
          empresa: null,
          solucao: null,
          resultados: null,
          seo: null,
          caseConteudo: null,
          imagemCarrouselDesk: null,
          imagemCarrouselMobile: null,
          imagemDestaque: null,
          imagemDestaqueParalax: null,
          imagemBgDesk: null,
          imagemBgMobile: null,
          dataAtualizacao: null,
          dataCadastro: null,
        };
      } else {
        return this.setup_item;
      }
    },
    async submitSetup() {
      this.loading = true;
      this.setup_item.idSEO = this.seo_id;
      this.setup_item.SolucoesIds = this.solucoes_itens.map((item) => item.id);

      const body = this.formatFilesEdit();

      const formData = this.jsonToFormData(body);
      const appendFile = async (inputId, formDataKey) => {
        const file = document.querySelector(inputId).files[0];
        if (file) {
          formData.append(`${formDataKey}.file`, file);
          formData.append(`${formDataKey}.fileName`, file.name);
        }
      };

      await appendFile("#img-desk-carousel", "imagemCarrouselDesk");
      await appendFile("#img-mobile-carousel", "imagemCarrouselMobile");
      await appendFile("#img-desk-destaque", "ImagemDestaque");
      await appendFile("#img-mobile-destaque", "ImagemDestaqueParalax");
      await appendFile("#img-desk-Background", "ImagemBgDesk");
      await appendFile("#img-mobile-Background", "ImagemBgMobile");

      if (this.edit_case) {
        this.updateCase(formData);
      } else {
        this.createCase(formData);
      }
    },
    createCase(data) {
      api
        .post("/cases", data)
        .then((resp) => {
          this.setup_item.id = resp.data.id;
          this.$emit("updateSetup", resp.data.id);

          this.notificacao_infos = {
            variant: "success",
            mensagem: "Criação do Setup salva com sucesso",
            count_timer: 10,
          };
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCase(data) {
      api
        .put(`/cases/`, data)
        .then((resp) => {
          this.notificacao_infos = {
            variant: "success",
            mensagem: "Edição do Setup salva com sucesso",
            count_timer: 10,
          };
          this.setup_item.id = resp.data.id;
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchConfigCases() {
      this.loading = true;
      api
        .get("cases/configuration")
        .then((resp) => {
          this.configs_cases = resp.data;
          setTimeout(() => {
            if (this.edit_case) {
              const status = this.configs_cases.status.find(
                (item) => item.descricao == this.case_item.status
              );
              this.setup_item.idStatus = status.id;
            }
          }, 1000);
        })
        .catch((error) => {
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      this.fetchConfigCases();
      setTimeout(() => {
        if (this.edit_case && this.case_item) {
          this.setup_item = {
            idStatus: null,
            idEmpresa: this.case_item.empresa.id,
            idIndustria: this.case_item.industria.id,
            idSolucao: this.case_item.solucao.id,
            ...this.case_item,
          };
          this.solucoes_itens = this.case_item.solucao;
        }
      }, 800);
    });
  },
};
</script>

<style scoped>
h2 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.itens-solucao {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.item-solucao {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
}
.item-solucao a {
  color: #ef5257;
  font-weight: 600;
  margin-left: 10px;
}
.img-setup img {
  height: 150px;
  object-fit: contain;
}
.itens-flex .item-form + .item-form {
  margin: 0;
}
.btn-industria {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.btn-industria button {
  height: 45px;
}
</style>