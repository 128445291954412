<template>
  <section class="cases container bg-case-mobile">
    <div data-anima="bottom">
      <h1>CASES</h1>
      <div v-if="$i18n.locale == 'pt'">
        <h2 class="desk">
          Nossos criativos usam ciência e<br />
          análise de dados para encantar e<br />
          inspirar pessoas, em todos os impactos
        </h2>
        <h2 class="mobile">
          Nossos criativos usam ciência e análise de dados para encantar e
          inspirar pessoas, em todos os impactos
        </h2>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <h2 class="desk">
          Our creatives use science and<br />
          data analysis to delight and<br />
          inspire people, in all impacts
        </h2>
        <h2 class="mobile">
          Our creatives use science and data analysis to delight and inspire
          people, in all impacts
        </h2>
      </div>

      <p>
        {{
          $i18n.locale == "pt"
            ? "Conheça alguns dos nossos cases mais recentes ou se preferir, use o filtro"
            : `Discover some of our most recent cases or if you prefer, use the filter`
        }}
      </p>
    </div>
    <div>
      <FiltrosCase @filtrar="filtrar" />
    </div>

    <div class="lista-cases">
      <Cases ref="cases" />
    </div>
  </section>
</template>

<script>
import Cases from "@/components/Home/Cases.vue";
import FiltrosCase from "@/components/FiltrosCase.vue";
export default {
  name: "CasesView",
  components: {
    Cases,
    FiltrosCase,
  },
  methods: {
    filtrar(data) {
      const cases = this.$refs.cases;
      if (cases) {
        cases.filtrar(data);
      }
    },
  },
};
</script>

<style scoped>
h1,
p {
  text-align: center;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: lighter;
  margin: 40px auto;
}
h2 {
  font-size: 45px;
  text-align: center;
  font-family: "Roboto Slab";
  line-height: 50px;
}
.cases {
  padding-bottom: 50px;
}

@media (max-width: 989px) {
  .bg-case-mobile {
    background-image: url("../assets/img/bg/cases.png");
    background-attachment: fixed;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto;
    margin-top: -45px;
  }
  h1,
  p {
    font-size: 12px;
    margin: 30px auto;
  }
  p {
    font-size: 16px;
    text-align: left;
  }
  h2 {
    font-size: 24px;
    line-height: 26px;
    text-align: left;
  }
}
</style>