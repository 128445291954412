import store from '@/store/index'
import router from '@/router/index'
import i18n from "./i18n/i18n.js";

export function debounce(fn, delay) {
  let timeoutID = null;

  return function (...args) {
    clearTimeout(timeoutID);
    const context = this;

    timeoutID = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export class ScrollAnima {
  constructor(sections) {
    this.sections = document.querySelectorAll(sections);
    this.windowMetade = window.innerHeight * (store.state.mobile ? 0.8 : 0.8)
    this.checkDistance = debounce(this.checkDistance.bind(this), 20);
  }

  // Pega a distância de cada item em relação
  // ao topo do site
  getDistance() {
    this.distance = [...this.sections].map((section) => {
      const offset = section.offsetTop;
      return {
        element: section,
        offset: Math.floor(offset - this.windowMetade),
      };
    });
  }

  // Verifica a distância em cada objeto
  // em relação ao scroll do site
  checkDistance() {
    this.distance.forEach((item) => {
      if (window.pageYOffset > item.offset) {
        item.element.classList.add('ativo');
      }
      // else if (item.element.classList.contains('ativo')) {
      //   item.element.classList.remove('ativo');
      // }
    });
  }

  init() {
    if (this.sections.length) {
      this.getDistance();
      this.checkDistance();
      window.addEventListener('scroll', this.checkDistance);
    }
    return this;
  }

  // Remove o event de scroll
  stop() {
    window.removeEventListener('scroll', this.checkDistance);
  }
}

export function alterarIdiomaGlobal(to, from) {
  const langFromRouteMatch = from.path.match(/\/(pt|en)$/);
  const langFromRoute = langFromRouteMatch ? langFromRouteMatch[1] : null;

  const langFromNewRouteMatch = to.path.match(/\/(pt|en)$/);
  const langFromNewRoute = langFromNewRouteMatch ? langFromNewRouteMatch[1] : null;

  // Verificar se houve uma mudança de idioma na rota
  // if (langFromRoute !== langFromNewRoute) {
  // Definir idioma no Vuex apenas se a mudança não veio de uma alteração de rota
  if (!to.query.n && !to.hash) {
    let langSplit;

    // Verificar se o parâmetro lang está presente na nova rota
    if (langFromNewRoute === "pt" || langFromNewRoute === "en") {
      // Se o idioma estiver definido na nova rota, use-o
      langSplit = langFromNewRoute;
    } else {
      // Caso contrário, use o idioma armazenado na sessionStorage ou padrão 'pt'
      langSplit = sessionStorage.idioma !== undefined ? sessionStorage.idioma.split("-")[0] : "pt";
    }

    // Definir idioma no Vuex
    store.dispatch("alterarIdioma", langSplit);

    // Definir idioma no i18n
    i18n.locale = langSplit;
  }

  // Verificar se o parâmetro lang está presente na rota anterior
  if (langFromRoute === "pt" || langFromRoute === "en") {
    // Se o idioma estiver definido na rota anterior e a mudança não veio do menu,
    // redirecionar para a nova rota com o mesmo idioma
    if (!to.query.n && !to.hash) {
      const langParam = `/${langFromRoute}`;
      let newPath = to.fullPath.replace(/\/(pt|en)$/, ''); // Remove qualquer idioma existente
      newPath = newPath.replace('//', '/')

      let rotaFinal = `${newPath}${langParam}`
      rotaFinal = rotaFinal.replace('//', '/')

      router.replace(rotaFinal);


    }
    // }
  }
}