<template>
  <section class="case">
    <div class="" v-show="!loading && case_item.id" data-anima="bottom">
      <div>
        <div class="content bg-case">
          <div class="container header-case">
            <div class="flex-top">
              <span class="nome-cliente">{{
                case_item.marca || case_item.empresa?.nome
              }}</span>
              <span class="servico-cliente"
                >— {{ case_item.industria?.descricao }}</span
              >
            </div>
            <h1>
              {{
                $i18n.locale == "pt" ? case_item.tituloBr : case_item.tituloEn
              }}
            </h1>
          </div>
        </div>
        <div class="solucoes container" v-if="case_item.solucao?.length">
          <span>— {{ $i18n.locale == "pt" ? "SOLUÇÕES" : "SOLUTIONS" }}</span>
          <div class="lista-itens">
            <ul>
              <li v-for="item in case_item.solucao" :key="item.id">
                • {{ item.descricao }}
              </li>
            </ul>
          </div>
        </div>

        <div class="container">
          <div v-if="conteudos.length" class="conteudos-case">
            <div
              class="item-content projeto"
              v-for="conteudo in conteudos"
              :key="conteudo.id"
              :style="`order: ${conteudo.ordem};`"
            >
              <h2>
                {{
                  $i18n.locale == "pt" ? conteudo.tituloBr : conteudo.tituloEn
                }}
              </h2>

              <div
                v-if="$i18n.locale == 'pt'"
                v-html="conteudo.conteudoBr"
                class="conteudo-html"
              ></div>
              <div
                v-else
                v-html="conteudo.conteudoEn"
                class="conteudo-html"
              ></div>

              <div
                class="img-item img-projeto"
                v-if="!conteudo.urlVideo && conteudo.imagemDesk"
              >
                <img
                  loading="lazy"
                  :src="conteudo.imagemDesk"
                  :alt="conteudo.textoAltBr"
                />
              </div>
              <div
                class="img-item video-conteudo"
                v-else-if="conteudo.urlVideo"
              >
                <iframe
                  loading="lazy"
                  :title="conteudo.tituloBr"
                  :src="`https://player.vimeo.com/video/${getIdVimeo(
                    conteudo.urlVideo
                  )}?h=5d67acbef1&title=0&byline=0&portrait=0`"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              </div>
              <div class="img-item" v-else></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="item-content resultados"
            v-if="case_item.resultados?.length"
          >
            <h2>{{ $i18n.locale == "pt" ? "Resultados" : "Results" }}</h2>

            <div
              class="item-resultado"
              v-for="res in case_item.resultados"
              :key="res.id"
            >
              <div class="item-res">
                <span>{{
                  $i18n.locale == "pt" ? res.indicadorBr : res.indicadorEn
                }}</span>
                <p>
                  {{ $i18n.locale == "pt" ? res.descricaoBr : res.descricaoEn }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="footer-case"
            v-if="case_item.previousId || case_item.nextId"
          >
            <router-link
              :to="`/cases/redirect/${case_item.previousId}/${
                !$route.params.lang ? $i18n.locale : ''
              }`"
              class="anterior"
              title="case anterior"
              v-if="case_item.previousId"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.45"
                height="30"
                viewBox="0 0 16.45 30"
              >
                <path
                  id="arrow_forward_ios_FILL0_wght200_GRAD0_opsz48"
                  d="M277.911,232.461l-1.45-1.429,13.571-13.571-13.571-13.54,1.45-1.46,15,15Z"
                  transform="translate(-276.461 -202.461)"
                />
              </svg>
              {{ $i18n.locale == "pt" ? "Anterior" : "Previous" }}
            </router-link>
            <router-link
              title="próximo case"
              :to="`/cases/redirect/${case_item.nextId}/${
                !$route.params.lang ? $i18n.locale : ''
              }`"
              v-if="case_item.nextId"
            >
              {{ $i18n.locale == "pt" ? "Próximo" : "Next" }}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.45"
                height="30"
                viewBox="0 0 16.45 30"
              >
                <path
                  id="arrow_forward_ios_FILL0_wght200_GRAD0_opsz48"
                  d="M277.911,232.461l-1.45-1.429,13.571-13.571-13.571-13.54,1.45-1.46,15,15Z"
                  transform="translate(-276.461 -202.461)"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </section>
</template>

<script>
import { api } from "@/services";

export default {
  name: "CaseItem",

  data() {
    return {
      metaInfo: {
        meta: [
          {
            name: "description",
            content: "eis q",
          },
        ],
      },
      case_item: {
        id: null,
      },
      loading: false,
      conteudos: [],
      seo: {
        id: -1,
        tituloBr: null,
        tituloEn: null,
        descricaoBr: null,
        descricaoEn: null,
        palavraChaveBr: null,
        palavraChaveEn: null,
        autor: null,
        abstrato: null,
        robo: null,
      },
    };
  },
  metaInfo() {
    return {
      title:
        this.$i18n.locale == "pt" ? this.seo?.tituloBr : this.seo?.tituloEn,
      meta: [
        {
          name: "description",
          content:
            this.$i18n.locale == "pt"
              ? this.seo?.descricaoBr
              : this.seo?.descricaoEn,
        },
        {
          name: "keywords",
          content:
            this.$i18n.locale == "pt"
              ? this.seo?.palavraChaveBr
              : this.seo?.palavraChaveEn,
        },
        {
          name: "author",
          content: this.seo?.autor,
        },
        {
          name: "robots",
          content: this.seo?.robo,
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },

  methods: {
    updateMetaInfo() {
      this.$meta().refresh();
    },
    fetchConteudo(id) {
      this.loading = true;
      if (id) {
        api
          .get(`case-conteudo/case/${id}`)
          .then((resp) => {
            this.conteudos = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    fetchCase(id) {
      this.loading = true;
      if (id) {
        api
          .get(`/cases/${id}`)
          .then((resp) => {
            this.case_item = resp.data;
            console.log(resp);
            this.seo = this.case_item.seo;

            this.case_item.resultados = this.case_item.resultados.filter(
              (res) => {
                if (res.exibir) {
                  return res;
                }
              }
            );
            this.formatCase();
            this.$nextTick(() => {
              this.updateMetaInfo();
            });
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/cases");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    formatCase() {
      setTimeout(() => {
        // root
        const root = document.documentElement;

        // Altera o valor da variável CSS
        root.style.setProperty("--font-color-case", this.case_item.fonteCor);
        root.style.setProperty("--bg-color-case", this.case_item.bgCor);

        // bg case
        const bg_case = document.querySelector(".bg-case");
        bg_case.style.background = `url('${
          this.isMobile
            ? this.case_item.imagemBgMobile
            : this.case_item.imagemBgDesk
        }') center center no-repeat`;
        bg_case.style.backgroundSize = `cover`;
      });
    },
    getIdVimeo(url) {
      const match = url.match(/vimeo\.com\/(\d+)/);
      return match ? match[1] : null;
    },
  },
  mounted() {
    this.fetchCase(this.$route.params.id);
    this.fetchConteudo(this.$route.params.id);

    const root = document.documentElement;
    root.style.setProperty("--bg-color-case", "#fff");
  },
};
</script>

<style scoped>
.case {
  /* background: #1d301c; */
  background: var(--bg-color-case);
  padding-bottom: 100px;
}
@media (max-width: 1300px) {
  .case {
    /* min-height: 600vh; */
  }
}
.bg-case {
  /* background: url("") center center no-repeat; */
  background-size: cover;
  width: 100%;
  height: 95vh;
}
.container {
  max-width: 770px;
}
.content {
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: -100px;
}
.content .flex-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .flex-top span {
  font-size: 15px;
  color: var(--font-color-case);
  font-weight: lighter;
}
.content h1 {
  font-size: 45px;
  line-height: 50px;
  font-family: "Roboto Slab";
  text-align: center;
  color: var(--font-color-case);
  margin: 20px auto;
}
.solucoes {
  padding-bottom: 50px;
  border-bottom: 1px solid var(--font-color-case);
  margin-bottom: 40px;
  /* margin-top: -140px; */
}
.solucoes * {
  color: var(--font-color-case);
}
.solucoes h3 {
  font-family: "Roboto Slab";
  margin-bottom: 25px;
}
.solucoes span {
  font-size: 16px;
  font-weight: lighter;
  margin-bottom: 25px;
  display: block;
}
.solucoes .lista-itens {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.solucoes .lista-itens ul li {
  font-size: 14px;
  font-weight: lighter;
}
.solucoes .lista-itens ul li + li {
  margin-top: 10px;
}

.item-content {
  padding: 0;
}
.item-content * {
  color: var(--font-color-case);
}
.item-content h2 {
  font-size: 45px;
  font-family: "Roboto Slab";
  margin-bottom: 30px;
}
.item-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: lighter;
}
.item-content .img-item {
  width: 100%;
  max-height: 100%;
  /* background: var(--font-color-case); */
  margin: 40px 0;
  border-radius: 20px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-conteudo {
  height: 500px;
}
.item-content .img-item:hover {
  transform: scale(1.1);
}
.item-content .video-conteudo iframe {
  width: 100%;
  height: 100%;
}
/* resultados */
.resultados h2 {
  margin-bottom: 130px;
}
.item-resultado .item-res {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 20px;
}
.item-resultado + .item-resultado {
  margin-top: 60px;
}
.item-resultado span {
  font-size: 45px;
  font-family: "Roboto Slab";
  /* text-align: right; */
}
.item-resultado p {
  padding-left: 20px;
  border-left: 5px solid var(--font-color-case);
}
.footer-case {
  margin-top: 100px;
  /* margin-bottom: 150px; */
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer-case a {
  width: 100%;
  color: var(--bg-color-case);
  background: var(--font-color-case);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 30px;
  transition: 0.3s;
  gap: 15px;
}
.footer-case a:hover {
  transform: scale(1.05);
}
.footer-case a svg {
  fill: var(--bg-color-case);
  stroke: var(--bg-color-case);
}
@media (max-width: 989px) {
  .case {
    /* min-height: 430vh; */
    margin-top: -50px;
    padding-bottom: 100px;
  }
  .content {
    height: 40vh;
    margin-bottom: 40px;
    margin-top: 0;
  }
  .bg-case {
    height: 72vh !important;
    margin-top: 10px;
  }
  .content h1 {
    font-size: 24px;
    line-height: 26px;
    text-align: left;
  }
  .solucoes .lista-itens {
    grid-template-columns: 1fr;
  }
  .solucoes .lista-itens ul + ul {
    margin-top: 10px;
  }
  .item-content {
    padding: 25px 0;
    font-size: 18px;
    line-height: 1.4;
  }
  .item-content p {
    font-size: 18px;
    line-height: 1.4;
  }
  .item-content h2 {
    font-size: 28px;
    line-height: 30px;
  }

  .resultados h2 {
    margin-bottom: 25px;
    text-align: center;
  }
  .item-resultado {
    grid-template-columns: 1fr;
    gap: 25px;
    border: 1px solid currentColor;
    padding: 30px;
  }
  .item-resultado + .item-resultado {
    margin-top: 40px;
  }

  .item-resultado span {
    font-size: 30px;
    /* text-align: center; */
    /* margin-bottom: 25px; */
  }
  .item-resultado p {
    padding: 0;
    border: 0;
  }
  .item-resultado .item-res {
    grid-template-columns: 1fr;
  }
  .footer-case {
    margin: 50px 0;
  }
  .item-content .img-item {
    height: 250px;
  }
  .header-case {
    top: -100px;
    position: relative;
  }
  .solucoes {
    margin-top: -100px;
  }
}
.footer-case a.anterior {
  justify-content: flex-start;
}
.anterior svg {
  transform: rotate(-180deg);
}
.conteudos-case {
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
}
</style>