<template>
  <div>
    <img
      loading="lazy"
      v-if="id == 1"
      data-anima="right"
      src="@/assets/img/banners/execucao-criativa-data-driven_720x600.png"
      :alt="text"
      class="bg"
    />
    <img
      loading="lazy"
      v-else-if="id == 2"
      data-anima="right"
      src="@/assets/img/banners/insight-plano-de-acao_720x600.png"
      :alt="text"
      class="bg"
    />
    <img
      loading="lazy"
      v-if="id == 3"
      data-anima="right"
      src="@/assets/img/banners/data-management_720x600.png"
      :alt="text"
      class="bg"
    />
    <img
      loading="lazy"
      v-if="id == 4"
      data-anima="right"
      src="@/assets/img/banners/data-science_720x600.png"
      :alt="text"
      class="bg"
    />
    <img
      loading="lazy"
      v-if="id == 5"
      data-anima="right"
      src="@/assets/img/banners/gestao-360_720x600.png"
      :alt="text"
      class="bg"
    />
    <img
      loading="lazy"
      v-if="id == 6"
      data-anima="right"
      src="@/assets/img/banners/crm_from_zero-to_hero_720x600.png"
      :alt="text"
      class="bg"
    />
  </div>
</template>

<script>
export default {
  name: "ImgSolucao",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
};
</script>

<style scoped>
.img-item img {
  position: relative;
  right: 150px;
  top: 50px;
}
.img-item img.bg {
  max-width: 600px;
}
@media (max-width: 989px) {
  .img-item img.bg {
    left: 0;
    /* transform: rotate(-45deg); */
    max-width: 85%;
  }
}
</style>