import { render, staticRenderFns } from "./CasesItens.vue?vue&type=template&id=00395e12&scoped=true"
import script from "./CasesItens.vue?vue&type=script&lang=js"
export * from "./CasesItens.vue?vue&type=script&lang=js"
import style0 from "./CasesItens.vue?vue&type=style&index=0&id=00395e12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00395e12",
  null
  
)

export default component.exports