<template>
  <div class="container-aux">
    <section v-if="$i18n.locale == 'pt'">
      <div class="solucao-servicos" v-if="nome_solucao == 'execucao-criativa'">
        <h2>SERVIÇOS</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img src="@/assets/img/solucoes/lampada.svg" alt="lampada icone" />
            <p>
              Criação, UX e
              <strong>produção de assets criativos</strong> para peças de
              comunicação omnichannel
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/foguete.svg" alt="foguete icone" />
            <p>
              <strong>Uso de IA para tratamento de imagens</strong> e criação de
              artes com escalabilidade
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/shape_heart.svg"
              alt="shape_heart icone"
            />
            <p>
              Adaptação de Brand Guide para as
              <strong>comunicações data-driven</strong>
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/window.svg" alt="window icone" />
            <p>
              <strong>Criação de Landing Pages</strong>
            </p>
          </div>

          <div class="servico-item">
            <img src="@/assets/img/solucoes/game.svg" alt="game icone" />
            <p>
              CRM Footer para <strong>enriquecimento gamificado</strong> de
              atributos e dados
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/code.svg" alt="code icone" />
            <p>
              <strong>Desenvolvimento</strong> de API e CMS, desenvolvimento web
              e móvel
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/video.svg" alt="video icone" />
            <p>Vídeos interativos e <strong>personalizados</strong></p>
          </div>
        </div>
      </div>
      <div
        class="solucao-servicos"
        v-if="nome_solucao == 'data-management-e-engenharia-de-dados'"
      >
        <h2>SERVIÇOS</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/database.svg"
              alt="database icone"
            />
            <p><strong>Construção e manutenção</strong> de CDPs, DBMs</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/email_check.svg"
              alt="email_check icone"
            />
            <p>Rotinas de <strong>Data Quality</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/maos_dadas.svg"
              alt="maos_dadas icone"
            />
            <p>ETLs, <strong>integrações e automações</strong> de processos</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/visao-360.svg"
              alt="visao-360 icone"
            />
            <p><strong>Visão 360º</strong> dos clientes</p>
          </div>

          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/lista_mais.svg"
              alt="lista_mais icone"
            />
            <p>
              Aplicações web para captura e
              <strong>enriquecimento de dados</strong>
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/backup.svg" alt="backup icone" />
            <p>Data <strong>Hosting</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/api.svg" alt="api icone" />
            <p>Interfaces com <strong>plataformas de BI e Martech</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/escudo.svg" alt="escudo icone" />
            <p>Governança, <strong>Segurança dos dados e LGPD</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/clinical.svg"
              alt="clinical icone"
            />
            <p>Projetos de <strong>Data Consulting</strong></p>
          </div>
        </div>
      </div>

      <div
        class="solucao-servicos"
        v-if="nome_solucao == 'planejamento-data-driven'"
      >
        <h2>SERVIÇOS</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/playlist.svg"
              alt="playlist icone"
            />
            <p>Plano de captura e <strong>enriquecimento de dados</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/raio.svg" alt="raio icone" />
            <p><strong>Ultrapersonalização</strong> das interações</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/tag_coracao.svg"
              alt="tag_coracao icone"
            />
            <p>Gestão de Programas de <strong>Loyalty e Incentivo</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/a_z.svg" alt="a_z icone" />
            <p>
              Avaliação do <strong>nível de maturidade</strong> em dados,
              analytics, estratégia de relacionamento e automação de marketing
            </p>
          </div>

          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/mao_coracao.svg"
              alt="mao_coracao icone"
            />
            <p>
              <strong>Estratégia de uso dos dados </strong>no planejamento dos
              pilares de relacionamento em todo ciclo de vida do cliente
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/bolo.svg" alt="bolo icone" />
            <p>
              Desenho do plano tático de contato usando informações da base para
              definir gatilhos e
              <strong>jornadas automatizadas</strong>, além de campanhas ad hoc
              em todos os canais de comunicação 1to1
            </p>
          </div>
        </div>
      </div>
      <div class="solucao-servicos" v-if="nome_solucao == 'gestao360-do-crm'">
        <h2>SERVIÇOS</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img src="@/assets/img/solucoes/folder.svg" alt="folder icone" />
            <p>
              Metodologias proprietárias de
              <strong>gerenciamento dos projetos</strong>
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/orquestrar.svg"
              alt="orquestrar icone"
            />
            <p><strong>Orquestramento</strong> de equipe multidisciplinares</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/supervisao.svg"
              alt="supervisao icone"
            />
            <p>
              Interface com <strong>diferentes unidades de negócios</strong>
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/arvore_contas.svg"
              alt="arvore_contas icone"
            />
            <p><strong>Time de atendimento</strong> dedicado</p>
          </div>

          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/precisao.svg"
              alt="precisao icone"
            />
            <p>
              <strong>Operação das plataformas</strong> de Martech e Data
              Visualization
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/psyco.svg" alt="psyco icone" />
            <p><strong>Suporte</strong> Consultivo</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/inventario.svg"
              alt="inventario icone"
            />
            <p>Controle da <strong>operação</strong></p>
          </div>
        </div>
      </div>

      <div class="solucao-servicos" v-if="nome_solucao == 'data-science'">
        <h2>SERVIÇOS DE BUSINESS ANALYTICS</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/linguagem.svg"
              alt="linguagem icone"
            />
            <p>Processamento de <strong>linguagem natural</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/cesta.svg" alt="cesta icone" />
            <p>Basket <strong>Analysis</strong>, Next Best Offer</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/monitor_batimentos.svg"
              alt="monitor_batimentos icone"
            />
            <p><strong>Modelos preditivos:</strong> Churn, Compra e outros</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/rebase.svg" alt="rebase icone" />
            <p><strong>Recomendação</strong> de conteúdo e produtos</p>
          </div>

          <div class="servico-item">
            <img src="@/assets/img/solucoes/lupa.svg" alt="lupa icone" />
            <p>Detecção de <strong>fraudes</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/lista.svg" alt="lista icone" />
            <p>Classificação <strong>RFM & Segmentação</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/persona.svg" alt="persona icone" />
            <p>Criação de <strong>personas</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/estoque.svg" alt="estoque icone" />
            <p>Estoque, Inventários e Mix de Sortimento</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/leitor.svg" alt="leitor icone" />
            <p>Leitura de Cupom Fiscal <strong>(OCR)</strong></p>
          </div>
        </div>
      </div>

      <div class="solucao-servicos" v-if="nome_solucao == 'data-science'">
        <h2>SERVIÇOS DE BUSINESS INTELLIGENCE</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/oink_chart.svg"
              alt="oink_chart icone"
            />
            <p>Oink Chat <strong>(IA Generativa)</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/insights.svg"
              alt="insights icone"
            />
            <p><strong>Insights</strong> e análise de resultados</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/preview.svg" alt="preview icone" />
            <p>
              <strong>Desenvolvimento</strong> de dashboards e reports em
              Plataformas de Data Vis.
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/pivot.svg" alt="pivot icone" />
            <p>Newbacon <strong> Cubo</strong></p>
          </div>

          <div class="servico-item">
            <img src="@/assets/img/solucoes/devices.svg" alt="devices icone" />
            <p><strong>Reports:</strong> By Mail, By Whats e DataBook</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/dash.svg" alt="dash icone" />
            <p><strong>Dashboards</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/whatshot.svg"
              alt="whatshot icone"
            />
            <p>Definição de <strong>KPIs</strong></p>
          </div>
        </div>
      </div>
    </section>

    <section v-if="$i18n.locale == 'en'">
      <div class="solucao-servicos" v-if="nome_solucao == 'execucao-criativa'">
        <h2>SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img src="@/assets/img/solucoes/lampada.svg" alt="lamp icon" />
            <p>
              Creation, UX, and
              <strong>production of creative assets</strong> for omnichannel
              communication pieces
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/foguete.svg" alt="rocket icon" />
            <p>
              <strong>Use of AI for image processing</strong> and creation of
              scalable artwork
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/shape_heart.svg"
              alt="shape_heart icon"
            />
            <p>
              Adaptation of Brand Guide for
              <strong>data-driven communications</strong>
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/window.svg" alt="window icon" />
            <p>
              <strong>Creation of Landing Pages</strong>
            </p>
          </div>

          <div class="servico-item">
            <img src="@/assets/img/solucoes/game.svg" alt="game icon" />
            <p>
              CRM Footer for <strong>gamified enrichment</strong> of attributes
              and data
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/code.svg" alt="code icon" />
            <p>
              <strong>Development</strong> of API and CMS, web and mobile
              development
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/video.svg" alt="video icon" />
            <p>Interactive and <strong>personalized videos</strong></p>
          </div>
        </div>
      </div>

      <div
        class="solucao-servicos"
        v-if="nome_solucao == 'data-management-e-engenharia-de-dados'"
      >
        <h2>SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/database.svg"
              alt="database icone"
            />
            <p><strong>Building and maintaining </strong> CDPs, DBMs</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/email_check.svg"
              alt="email_check icone"
            />
            <p><strong>Data Quality</strong> routines</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/maos_dadas.svg"
              alt="maos_dadas icone"
            />
            <p>ETLs, <strong>integrations, and process automations </strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/visao-360.svg"
              alt="visao-360 icone"
            />
            <p><strong>360º customer</strong> view</p>
          </div>

          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/lista_mais.svg"
              alt="lista_mais icone"
            />
            <p>
              Web applications for data capture and
              <strong>enrichment</strong>
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/backup.svg" alt="backup icone" />
            <p>Data <strong>Hosting</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/api.svg" alt="api icone" />
            <p>Interfaces with <strong>BI and Martech</strong> platforms</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/escudo.svg" alt="escudo icone" />
            <p>
              Data governance,
              <strong>security, and LGPD compliance</strong>
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/clinical.svg"
              alt="clinical icone"
            />
            <p>Data Consulting <strong>projects</strong></p>
          </div>
        </div>
      </div>

      <div
        class="solucao-servicos"
        v-if="nome_solucao == 'planejamento-data-driven'"
      >
        <h2>SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img src="@/assets/img/solucoes/playlist.svg" alt="playlist icon" />
            <p>Data capture and <strong>enrichment plan</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/raio.svg" alt="raio icon" />
            <p><strong>Hyper-personalization</strong> of interactions</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/tag_coracao.svg"
              alt="tag_coracao icon"
            />
            <p>Management of <strong>Loyalty and Incentive Programs</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/a_z.svg" alt="a_z icon" />
            <p>
              Assessment of <strong>data maturity level</strong>, analytics,
              relationship strategy, and marketing automation
            </p>
          </div>

          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/mao_coracao.svg"
              alt="mao_coracao icon"
            />
            <p>
              <strong>Data usage strategy</strong> in planning relationship
              pillars throughout the customer lifecycle
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/bolo.svg" alt="bolo icon" />
            <p>
              Design of <strong>tactical contact plan</strong> using base
              information to define triggers and automated journeys, as well as
              ad hoc campaigns across all 1-to-1 communication channels
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/raio.svg" alt="raio icon" />
            <p><strong>Hyper-personalization</strong> of interactions</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/tag_coracao.svg"
              alt="tag_coracao icon"
            />
            <p>
              <strong>Management of Loyalty and Incentive Programs</strong>
            </p>
          </div>
        </div>
      </div>

      <div class="solucao-servicos" v-if="nome_solucao == 'gestao360-do-crm'">
        <h2>SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img src="@/assets/img/solucoes/folder.svg" alt="folder icon" />
            <p>
              Proprietary platforms and methodologies for
              <strong>project management</strong>
            </p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/orquestrar.svg"
              alt="orquestrar icon"
            />
            <p><strong>Orchestration</strong> of multidisciplinary teams</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/supervisao.svg"
              alt="supervisao icon"
            />
            <p>Interface with <strong>different business units</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/arvore_contas.svg"
              alt="arvore_contas icon"
            />
            <p>Dedicated <strong>service team</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/precisao.svg" alt="precisao icon" />
            <p>
              <strong>Operation</strong> of Martech and Data Visualization
              platforms
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/psyco.svg" alt="psyco icon" />
            <p><strong>Consultative support</strong></p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/inventario.svg"
              alt="inventario icon"
            />
            <p><strong>Project operation control</strong></p>
          </div>
        </div>
      </div>

      <div class="solucao-servicos" v-if="nome_solucao == 'data-science'">
        <h2>BUSINESS ANALYTICS SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/linguagem.svg"
              alt="linguagem icon"
            />
            <p><strong>Natural Language Processing</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/cesta.svg" alt="cesta icon" />
            <p><strong>Basket Analysis</strong>, Next Best Offer</p>
          </div>
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/monitor_batimentos.svg"
              alt="monitor_batimentos icon"
            />
            <p>
              <strong>Predictive models:</strong> Churn, Purchase, and others
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/rebase.svg" alt="rebase icon" />
            <p>
              <strong>Content, products, and services recommendation</strong>
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/lupa.svg" alt="lupa icon" />
            <p>Fraud <strong>detection</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/lista.svg" alt="lista icon" />
            <p><strong>RFM Classification & Segmentation</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/persona.svg" alt="persona icon" />
            <p><strong>Creation of personas</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/estoque.svg" alt="estoque icon" />
            <p>Inventory and Assortment Mix</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/leitor.svg" alt="leitor icon" />
            <p>Reading of Fiscal Coupon <strong>(OCR)</strong></p>
          </div>
        </div>
      </div>

      <div class="solucao-servicos" v-if="nome_solucao == 'data-science'">
        <h2>BUSINESS INTELLIGENCE SERVICES</h2>
        <div class="solucao-servicos-grid">
          <div class="servico-item">
            <img
              src="@/assets/img/solucoes/oink_chart.svg"
              alt="oink_chart icon"
            />
            <p>Oink Chat <strong>(Generative AI)</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/insights.svg" alt="insights icon" />
            <p><strong>Insights</strong> and results analysis</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/preview.svg" alt="preview icon" />
            <p>
              <strong>Development</strong> of dashboards and reports in Data
              Visualization Platforms
            </p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/pivot.svg" alt="pivot icon" />
            <p>Newbacon <strong>Cube</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/devices.svg" alt="devices icon" />
            <p><strong>Reports:</strong> By Mail, By WhatsApp, and DataBook</p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/dash.svg" alt="dash icon" />
            <p><strong>Dashboards</strong></p>
          </div>
          <div class="servico-item">
            <img src="@/assets/img/solucoes/whatshot.svg" alt="whatshot icon" />
            <p>Definition of <strong>KPIs</strong></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServicosSolucao",
  props: {
    nome_solucao: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.detalhe-solucao h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  z-index: 9999;
  position: relative;
  top: 50px;
}

.solucao-servicos h2 {
  top: 0;
  margin-bottom: 45px;
}
.solucao-servicos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 130px;
}
.solucao-servicos-grid .servico-item {
  border: 1px solid #000;
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 20px;
  transition: 0.3s;
  min-height: 90px;
}
.solucao-servicos-grid .servico-item:hover {
  background: #000;
}
.solucao-servicos-grid .servico-item img {
  min-height: 40px;
}
.solucao-servicos-grid .servico-item:hover img {
  filter: invert(1);
}
.solucao-servicos-grid .servico-item p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 7px;
}
.solucao-servicos-grid .servico-item:hover p,
.solucao-servicos-grid .servico-item:hover p strong {
  color: #fff !important;
}
@media (max-width: 989px) {
  .detalhe-solucao h2 {
    font-size: 12px;
    margin: 45px auto;
  }
  .solucoes-itens {
    padding: 40px 0px;
    display: grid;
    justify-content: center;
  }
  .solucoes-itens .item {
    margin-bottom: 30px;
  }
  .solucao-servicos-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .container-bg {
    padding: 0;
  }
  .container-aux {
    padding: 0 20px;
  }
}
</style>