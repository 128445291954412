<template>
  <section class="sobre-nos">
    <div>
      <h2 data-anima="top-scroll">
        {{ $i18n.locale == "pt" ? "SOBRE NÓS" : "ABOUT US" }}
      </h2>
      <img
        loading="lazy"
        data-anima="left-scroll"
        src="@/assets/img/bg/sobre-nos-1920x845.png"
        alt="sobre nos bg"
        class="bg desk"
      />
      <img
        loading="lazy"
        data-anima="left-scroll"
        src="@/assets/img/banners/sobre-nos_720x600.png"
        alt="sobre nos bg"
        class="bg mobile"
      />
      <div data-anima="bottom-scroll">
        <p v-if="$i18n.locale == 'pt'">
          Quando dados encontram paixão, a magia acontece.<br
            v-if="!isMobile"
          />
          Somos uma agência especialista em CRM e por este<br
            v-if="!isMobile"
          />
          motivo assinamos: <span>Data-driven Love</span>
        </p>
        <p v-if="$i18n.locale == 'en'">
          When data meets passion, magic happens.<br />
          We are a CRM specialist agency, and for this<br />
          reason, we sign:
          <span>Data-driven Love</span>
        </p>
        <router-link to="/sobre-nos" title="sobre nós"
          ><img
            src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
            alt="icone mais"
            class="icone-mais"
            width="50"
            height="50"
        /></router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SobreNos",
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
};
</script>

<style scoped>
.sobre-nos {
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.sobre-nos h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
  margin-top: 20px;
}
.sobre-nos img {
  margin: 0 auto;
}
.sobre-nos .bg {
  margin-top: -50px;
  height: 60vh;
}
.sobre-nos p {
  font-family: "Roboto Slab";
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.6px;
  text-align: center;
}
.sobre-nos p span {
  font-family: "Roboto Slab";
  color: var(--secondary-color);
}
.sobre-nos .icone-mais {
  margin: 25px auto;
  cursor: pointer;
}
@media (max-width: 989px) {
  .sobre-nos {
    margin: 60px auto;
    margin-top: 200px;
  }
  .sobre-nos h2 {
    font-size: 12px;
  }
  .sobre-nos p {
    text-align: left;
    font-size: 20px;
  }
  .sobre-nos .bg {
    margin-top: 0;
    height: auto;
  }
}
</style>