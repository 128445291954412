<template>
  <section>
    <div class="cases-itens" v-show="!loading">
      <div class="case-item" v-for="item in cases" :key="item.id">
        <div class="foto-container" data-anima="top">
          <!-- :src="!isMobile ? item.imagemDestaque : item.imagemDestaqueParalax" -->
          <div class="imagem-case">
            <img
              loading="lazy"
              :src="item.imagemDestaque"
              :alt="item.textoAltDestaqueBr"
              class="foto-case"
            />
            <img
              loading="lazy"
              :src="item.imagemDestaqueParalax"
              :alt="item.textoAltDestaqueBr"
              class="foto-case-paralax"
            />
          </div>
        </div>
        <p data-anima="left">
          {{ $i18n.locale == "pt" ? item.tituloBr : item.tituloEn }}
        </p>

        <div class="footer-case-item" data-anima="bottom">
          <div class="infos-case-item">
            <span class="nome-cliente">{{ item.empresa.nome }}</span>
            <span class="tipo-servico">{{ item.solucao[0]?.descricao }}</span>
          </div>
          <router-link
            :to="`/cases/${item.id}`"
            class="link-case-item"
            :title="item.tituloBr"
          >
            <img
              loading="lazy"
              src="@/assets/img/svg/ver-case.svg"
              class="txt-ver"
              alt="ver case icone"
              width="100"
              height="100"
            />
            <img
              loading="lazy"
              src="@/assets/img/svg/visibility_FILL0_wght200_GRAD0_opsz24.svg"
              class="icone-ver"
              alt="ver case icone"
              width="100"
              height="100"
            />
          </router-link>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </section>
</template>

<script>
import { api } from "@/services";
import { ScrollAnima } from "@/helpers";
export default {
  name: "CasesItens",
  props: {
    rodape: {
      type: Boolean,
      default: false,
    },
    cases_home: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cases: [],
      loading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
  methods: {
    filtrar(data) {
      this.fetchCases(data);
    },
    fetchCases(filtro = "") {
      if (this.cases_home.length) {
        this.cases = this.cases_home;
        this.loading = false;
      } else {
        this.loading = true;
        api
          .get(
            `/casesArray?statusId=1&page=1&pageSize=${
              this.rodape ? 3 : 100
            }${filtro}`
          )
          .then((resp) => {
            this.cases = resp.data?.items;
          })
          .finally(() => {
            this.loading = false;
          });
      }

      setTimeout(() => {
        const scrollAnima = new ScrollAnima("[data-anima]");
        scrollAnima.init();
      }, 1500);
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(() => {
      setTimeout(() => {
        this.fetchCases(sessionStorage.filtro_case || "");
      }, 200);
    });
  },
};
</script>

<style scoped>
/* cases item */
.bg-case {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
.cases-itens {
  margin: 0 auto;
  display: flex;
  gap: 60px;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 9999;
  margin-bottom: 40px;
  /* margin-top: -100px; */
  margin-top: -80px;
}
.case-item {
  max-width: 350px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-case {
  position: absolute;
  width: 350px;
}
.case-item .foto-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  max-height: 305px;
}
.case-item .foto-case {
  /* margin-bottom: -50px; */
  transition: 0.3s;
  object-fit: contain;
  max-height: 310px;
  width: 100%;
  flex: 1;
}
.case-item:hover .foto-case {
  transform: scale(1.05);
}
.case-item p {
  font-family: "Roboto Slab";
  font-size: 16px;
  line-height: 18px;
  padding-left: 20px;
  border-left: 1px solid var(--primary-color);
  max-width: 85%;
  min-height: 60px;
  margin-top: -40px;
}
.case-item .footer-case-item {
  border: 1px solid var(--primary-color);
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
}
.case-item .footer-case-item span {
  display: block;
}
.case-item .footer-case-item .nome-cliente {
  font-family: "Roboto Slab";
  font-size: 16px;
}
.case-item .footer-case-item .tipo-servico {
  font-weight: 300;
  font-size: 13px;
}
.case-item .footer-case-item .link-case-item {
  border-left: 1px solid var(--primary-color);
  width: 120px;
  height: 120px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-item .footer-case-item .link-case-item:hover img {
  filter: invert(100%);
}
.txt-ver {
  width: 100px;
}
.icone-ver {
  width: 30px;
}
.case-item .footer-case-item .link-case-item:hover .txt-ver {
  animation: rotateItem 4s forwards;
}

.case-item .footer-case-item .link-case-item:hover {
  background-color: black;
}
.icone-ver {
  position: absolute;
}
@keyframes rotateItem {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.js-scroll [data-anima="top-scroll"].ativo {
  animation: showBottom 3s forwards;
}
@media (max-width: 989px) {
  .cases-itens {
    justify-content: center;
    margin-top: 10px;
  }
}
.imagem-case {
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-item .foto-case {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
}
</style>