import axios from 'axios';

import router from './router/index'
import store from './store/index'

const url = process.env.VUE_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.ApiKey = 'Zm9pIG8gc-GF1bG8-gcXVlIGRlc2Vud-m9sdmV1'
    return config
  },
  function (error) {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (err) {

  // deslogar
  if (err.response.status == 401) {
    store.dispatch("deslogarUsuario");
    setTimeout(() => {
      router.push("/backoffice/login");
    }, 1000);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }



  const mensagem_erro_telegram = `
  🔥  Atenção\n\n 
  🖥️  Aplicação: SITE NEWBACON\n
  📅  Data: ${new Date().toLocaleString()}\n
  🌐   API: ${err.response.config.url}\n
  🚩  Erro: code - ${err.response.status} | erro - ${JSON.stringify(err.response.data)}\n
  📄  Body: ${btoa(err.config.data)}

  `;

  errorMonitoria(mensagem_erro_telegram)

  return Promise.reject(err);
});



export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
};


export function errorMonitoria(mensagem) {
  // console.log(mensagem)
  fetch(`https://api.telegram.org/bot5307523807:AAHuxs-SIftLXbN_HQ_AqX5GsPur5TViBys/sendMessage?chat_id=-911193531&text=${encodeURIComponent(mensagem)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}