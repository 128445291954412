<template>
  <section class="container">
    <div class="pg-nao-encontrada">
      <h1>Página não encontrada 👋</h1>
      <router-link to="/" title="Retornar para home">Voltar para home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "PaginaNaoEncontrada",
};
</script>

<style scoped>
.container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pg-nao-encontrada {
  background: #f7f7f7;
  padding: 40px 80px;
  border-radius: 5px;
  text-align: center;
}
h1 {
  margin-bottom: 20px;
}
</style>