<template>
  <section class="solucoes" data-anima="bottom">
    <div>
      <h1>{{ $i18n.locale == "pt" ? "SOMOS" : "WE ARE" }}</h1>

      <img
        loading="lazy"
        src="@/assets/img/bg/solucoes.png"
        alt="imagem Data driven lovers"
        class="desk bg-item"
      />

      <img
        loading="lazy"
        src="@/assets/img/banners/newbacon_data-driven-lovers_720x600.png"
        alt="imagem Data driven lovers"
        class="bg-solucoes mobile"
      />

      <h2>
        {{
          $i18n.locale == "pt"
            ? "Uma combinação de talentos, tecnologia, criatividade e processos pronta para ajudar sua empresa a atingir o nível de excelência em CRM."
            : "A ready-made combination of talent, technology, creativity and processes to help your company achieve the level of excellence in CRM."
        }}
      </h2>

      <p>
        {{
          $i18n.locale == "pt"
            ? `Conte com nossa experiência para transformar dados em insights,
        estratégias, comunicação e resultados surpreendentes. Descubra nossas
        soluções:`
            : `Count on our experience to transform data into insights,
        strategies, communication and surprising results. Discover our
        solutions:`
        }}
      </p>
    </div>

    <SolucoesItens />
  </section>
</template>

<script>
import SolucoesItens from "@/components/Home/SolucoesItens.vue";
export default {
  name: "SolucoesView",
  components: {
    SolucoesItens,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: lighter;
  font-family: "Roboto";
  margin: 30px auto;
  margin-bottom: 0 !important;
  letter-spacing: 2.4px;
}

p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: lighter;
  margin: 50px auto;
  width: 700px;
}
.solucoes .bg-item {
  max-height: 50vh;
  margin: 0 auto;
}
.solucoes h2 {
  width: 900px;
  font-family: "Roboto Slab";
  font-size: 45px;
  line-height: 50px;
  text-align: center;
  margin: 50px auto;
}
@media (max-width: 1300px) {
  .solucoes h2 {
    font-size: 38px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
@media (max-width: 989px) {
  .solucoes h2,
  .solucoes p {
    width: 100%;
  }
  .solucoes h2 {
    padding: 0px 20px;
    font-size: 24px;
    line-height: 26px;
    text-align: left;
    margin: 30px auto;
  }
  .solucoes p {
    padding: 0px 20px;
    text-align: left;
    margin: 0 auto;
  }
  .solucoes h1 {
    margin: 20px auto;
  }
}
</style>