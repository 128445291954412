<template>
  <section class="blog" data-anima="top">
    <h2>BLOG & NEWS</h2>
    <img
      loading="lazy"
      src="@/assets/img/bg/bacon-news-splash-1920x845.jpg"
      alt="blog bg"
      class="bg desk"
    />
    <img
      loading="lazy"
      src="@/assets/img/bg/bacon-news-splash-720x1000.jpg"
      alt="blog bg"
      class="bg mobile"
    />
    <div>
      <ItensBlog :page_news="true" :bacon_news="true" />
    </div>
  </section>
</template>

<script>
import ItensBlog from "../ItensBlog.vue";
export default {
  name: "BlogNews",
  components: {
    ItensBlog,
  },
};
</script>

<style scoped>
.blog {
  margin: 50px auto;
  margin-bottom: 0;
}
.blog h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
}
.blog img.bg {
  margin: 0 auto;
  margin-bottom: -90px;
}
.blog p {
  font-family: "Roboto Slab";
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.6px;
  text-align: center;
}
.blog p span {
  font-family: "Roboto Slab";
  color: var(--secondary-color);
}
@media (max-width: 989px) {
  .blog img.bg {
    width: 100%;
    height: 600px;
  }
}
</style>