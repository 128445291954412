import { render, staticRenderFns } from "./DetalheSolucoes.vue?vue&type=template&id=740cb614&scoped=true"
import script from "./DetalheSolucoes.vue?vue&type=script&lang=js"
export * from "./DetalheSolucoes.vue?vue&type=script&lang=js"
import style0 from "./DetalheSolucoes.vue?vue&type=style&index=0&id=740cb614&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740cb614",
  null
  
)

export default component.exports