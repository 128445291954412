<template>
  <section class="detalhe-solucao" data-anima="bottom">
    <div class="container container-bg" v-if="solucao_ativa">
      <!-- <h2>{{ $i18n.locale == "pt" ? "SOLUÇÕES" : "SOLUTIONS" }}</h2> -->
      <h2>
        {{
          $i18n.locale == "pt" ? solucao_ativa.tituloBr : solucao_ativa.tituloEn
        }}
      </h2>
      <div
        class="grid-section container-aux"
        :class="nome_solucao"
        v-if="solucao_ativa"
      >
        <div>
          <h3>
            {{
              $i18n.locale == "pt"
                ? solucao_ativa.tituloConteudoBr
                : solucao_ativa.tituloConteudoEn
            }}
          </h3>
          <p>
            {{
              $i18n.locale == "pt"
                ? solucao_ativa.conteudoBr
                : solucao_ativa.conteudoEn
            }}
          </p>
        </div>
      </div>

      <ServicosSolucao :nome_solucao="nome_solucao" />
    </div>
    <h2>{{ $i18n.locale == "pt" ? "OUTRAS SOLUÇÕES" : "OTHER SOLUTIONS" }}</h2>
    <SolucoesItens :solucao_ativa="nome_solucao" />
  </section>
</template>

<script>
import SolucoesItens from "@/components/Home/SolucoesItens.vue";
import ServicosSolucao from "@/components/ServicosSolucao.vue";
export default {
  name: "DetalheSolucoes",
  components: {
    SolucoesItens,
    ServicosSolucao,
  },
  data() {
    return {
      nome_solucao: "",
      solucao_ativa: null,
      solucoes: [
        {
          nome: "execucao-criativa",
          tituloBr: "Execução criativa",
          tituloEn: "Creative Execution",
          conteudoBr:
            "Nosso time de Criativos amam dados e é com essa inspiração e sinergia entre os times que criamos conteúdos, peças de comunicação online e offline multicanais que trazem mais resultados para os nossos clientes – sem perder a criatividade e o senso estético e abusando da inovação para promover ações de impacto.",
          conteudoEn:
            "Our Creative team loves data, and it's with this inspiration and synergy between teams that we create content, online and offline communication pieces across multiple channels that bring more results to our clients - without sacrificing creativity and aesthetic sense, while leveraging innovation to promote impactful actions.",
          cards: [],
          tituloConteudoBr: "Ultrapersonalização das experiências",
          tituloConteudoEn: "Hyper-personalized experiences",
        },
        {
          nome: "planejamento-data-driven",
          tituloBr: "Planejamento Data-driven",
          tituloEn: "Data-driven planning",
          conteudoBr:
            "Do insight ao plano de ação com desenho de planos de contatos e jornadas automatizadas a partir do entendimento dos desafios e oportunidades de cada negócio, potencializando o uso dos dados para personalizar experiências e comunicações e responder às perguntas dos nossos clientes – com muito “BACON” correndo nas veias.",
          conteudoEn: `From insight to action plan, designing contact plans and automated journeys based on understanding the challenges and opportunities of each business, leveraging data usage to personalize experiences and communications and address our clients' questions - with lots of "BACON" running through our veins.`,
          cards: [],
          tituloConteudoBr: "Data-driven de verdade",
          tituloConteudoEn: "Real Data-driven strategy",
        },
        {
          nome: "data-management-e-engenharia-de-dados",
          tituloBr: "Data management & engenharia de dados",
          tituloEn: "Data management & data engineering",
          conteudoBr:
            "Impulsionando conexões autênticas entre empresas e pessoas através da estratégia de dados e tecnologia aplicadas ao CRM.",
          conteudoEn:
            "Driving authentic connections between businesses and people through data strategy and technology applied to CRM.",
          cards: [],
          tituloConteudoBr: "Nosso combustível é o dado",
          tituloConteudoEn: "Our fuel is data",
        },
        {
          nome: "data-science",
          tituloBr: "Data science",
          tituloEn: "Data science. AI, BI & Martech",
          conteudoBr:
            "Analisamos comportamentos passados para prever padrões de comportamentos futuros, otimizando as análises das estratégias de aquisição, monetização, rentabilização e fidelização da base. Com especialistas em matemática, estatística e Machine Learning, personalizamos os estudos e algoritmos para cada projeto.",
          conteudoEn:
            "We analyze past behaviors to predict patterns of future behaviors, optimizing the analysis of acquisition, monetization, loyalty and retention strategies of the customer base. With experts in mathematics, statistics, and Machine Learning, we customize studies and algorithms for each project.",
          cards: [],
          tituloConteudoBr: "IA, BI e Analytics",
          tituloConteudoEn: "IA, BI and Advanced Analytics",
        },
        {
          nome: "gestao360-do-crm",
          tituloBr: "Gestão 360º do CRM",
          tituloEn: "360º CRM Management",
          conteudoBr:
            "Resultados incríveis nascem da fusão entre dados e criatividade. Dominamos a arte da gestão de projetos de CRM com times multidisciplinares, consultivos e 100% focados em CRM.",
          conteudoEn:
            "Incredible results stem from the fusion of data and creativity. We master the art of CRM project management with multidisciplinary, consultative teams that are 100% focused on CRM.",
          cards: [],
          tituloConteudoBr: "Operação de todo ecossistema de CRM",
          tituloConteudoEn: "Operation of the entire CRM ecosystem",
        },
      ],
    };
  },
  mounted() {
    this.nome_solucao = this.$route.params.solucao;
    this.solucao_ativa = this.solucoes.find(
      (item) => item.nome == this.nome_solucao
    );
    document.title =
      this.$i18n.locale == "pt"
        ? this.solucao_ativa.tituloBr
        : this.solucao_ativa.tituloEn;
    if (!this.solucao_ativa) {
      this.$router.push("/solucoes");
    }
  },
};
</script>

<style scoped>
section {
  background: #fff !important;
}
.grid-section {
  width: 100%;
  height: 700px;
  margin-top: -60px;
}
.grid-section.execucao-criativa {
  background: url("../assets/img/banners/solucoes_execucao-criativa-1920x845.png")
    center center no-repeat !important;
  background-size: contain !important;
}
.grid-section.data-management-e-engenharia-de-dados {
  background: url("../assets/img/banners/solucoes_data-management-1920x845.png")
    center center no-repeat !important;
  background-size: contain !important;
}
.grid-section.planejamento-data-driven {
  background: url("../assets/img/banners/solucoes_planejamento-1920x845.png")
    center center no-repeat !important;
  background-size: contain !important;
}
.grid-section.gestao360-do-crm {
  background: url("../assets/img/banners/solucoes_gestao-360-1920x845.png")
    center center no-repeat !important;
  background-size: contain !important;
}
.grid-section.data-science {
  background: url("../assets/img/banners/solucoes_data-science-1920x845.png")
    center center no-repeat !important;
  background-size: contain !important;
}

.detalhe-solucao h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  z-index: 9999;
  position: relative;
  top: 50px;
}
.grid-section p {
  font-family: "Roboto Slab";
  font-size: 24px;
  line-height: 26px;
  width: 550px;
}
.detalhe-solucao .grid-section {
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}
.detalhe-solucao .grid-section h3 {
  font-family: "Roboto Slab";
  color: black;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 40px;
  max-width: 80%;
}
.solucoes-itens .item {
  width: 300px;
  border: 1px solid white;
  margin-bottom: 10px;
  padding: 30px !important;
}

.item h5 {
  font-family: "Roboto Slab";
  font-size: 25px;
  line-height: 28px;
  display: grid;
  align-items: end;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
}

.solucoes-itens p {
  font-size: 16px;
  line-height: 24px !important;
  font-family: "Roboto";
  color: #000 !important;
  margin-top: 20px;
}
.solucoes-itens img {
  width: 30px;
  margin-bottom: 8px;
}
.solucao-servicos h2 {
  top: 0;
  margin-bottom: 45px;
}

@media (max-width: 1300px) {
  .container {
    max-width: 1250px;
  }
  .detalhe-solucao .grid-section h3 {
    max-width: 90%;
  }
}
@media (min-width: 990px) {
  .solucoes-itens {
    flex-wrap: wrap;
    max-width: 1100px;
    min-height: 350px;
    display: flex;
    /* justify-content: space-evenly; */
    margin: auto;
    margin-top: 50px;
    gap: 60px;
    padding-bottom: 70px;
  }
  .img-item {
    display: flex;
    align-items: center;
    position: relative;
    right: -150px;
    top: -150px;
  }
  .img-item img {
    position: relative;
    left: -100px;
    top: 80px;
  }
}
@media (max-width: 989px) {
  .container-bg {
    padding: 0;
  }
  .grid-section.execucao-criativa {
    background: url("../assets/img/banners/solucoes_execucao-criativa-720x1000.png")
      center center no-repeat !important;
    background-size: contain !important;
  }
  .grid-section.data-management-e-engenharia-de-dados {
    background: url("../assets/img/banners/solucoes_data-management-720x1000.png")
      center center no-repeat !important;
    background-size: contain !important;
  }
  .grid-section.planejamento-data-driven {
    background: url("../assets/img/banners/solucoes_planejamento-720x1000.png")
      center center no-repeat !important;
    background-size: contain !important;
  }
  .grid-section.gestao360-do-crm {
    background: url("../assets/img/banners/solucoes_gestao-360-720x1000.png")
      center center no-repeat !important;
    background-size: contain !important;
  }
  .grid-section.data-science {
    background: url("../assets/img/banners/solucoes_data-science-720x1000.png")
      center center no-repeat !important;
    background-size: contain !important;
  }
  .img-item {
    margin: 20px 0px;
  }
  .circulo {
    position: absolute;
    top: 0;
    z-index: -9;
  }
  .detalhe-solucao h2 {
    font-size: 12px;
  }
  .detalhe-solucao .grid-section {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -250px;
    height: 90vh;
  }
  .detalhe-solucao p,
  .grid-section div {
    width: 100% !important;
  }
  .solucao-servicos h2 {
    margin: 45px auto;
  }

  .detalhe-solucao h3 {
    font-size: 24px !important;
    line-height: 26px !important;
    margin-bottom: 20px !important;
  }
  .detalhe-solucao {
    padding-top: 60px;
    margin-top: -40px;
  }
  .detalhe-solucao p {
    font-size: 16px;
    line-height: 20px !important;
    font-family: "Roboto";
    color: #000 !important;
  }
  .solucoes-itens {
    padding: 40px 0px;
    display: grid;
    justify-content: center;
  }
  .solucoes-itens .item {
    margin-bottom: 30px;
  }
  .solucao-servicos-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .container-bg {
    padding: 0;
  }
  .container-aux {
    padding: 0 20px;
  }
}

</style>