import { render, staticRenderFns } from "./TermosDeUso.vue?vue&type=template&id=2ea0cc65&scoped=true"
import script from "./TermosDeUso.vue?vue&type=script&lang=js"
export * from "./TermosDeUso.vue?vue&type=script&lang=js"
import style0 from "./TermosDeUso.vue?vue&type=style&index=0&id=2ea0cc65&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea0cc65",
  null
  
)

export default component.exports