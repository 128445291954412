<template>
  <section class="container">
    <h1>TERMOS DE USO E PREFERÊNCIAS</h1>
    <div class="item-info">
      <h2>1. Vinculação</h2>
      <p>
        1.1. Ao acessar e/ou usar este website (“Site”), incluindo os conteúdos
        e funcionalidades disponíveis aqui e em outros domínios utilizados para
        operar o Site, aquele que acessa e/ou utiliza o Site (“Usuário”) declara
        ter lido e compreendido este Termo de Uso (“Termo”), bem como concordado
        integral e expressamente com o mesmo e estar obrigado a cumprir
        irrestritamente as condições aqui estabelecidas, formando um contrato
        entre o Usuário e a Ad.Agency Publicidade e Marketing Ltda.
        (“Newbacon”).
      </p>
      <p>
        1.2. O Usuário declara ser capaz ou estar legalmente representado para
        aderir e se vincular às condições deste Termo.
      </p>
      <p>
        1.3. Caso o Usuário não concorde com qualquer dispositivo deste Termo
        (integral ou parcialmente) ou não tenha capacidade legal para contratar,
        em nome próprio, o Usuário deverá imediatamente deixar este Site, fechar
        a aba ou janela do navegador de Internet que estiver usando para
        acessá-lo e abster-se de acessar ou deixar de usar, de qualquer forma, o
        Site.
      </p>
      <p>
        1.4. O Usuário reconhece e concorda que todos os avisos, regulamentos de
        uso e instruções apresentados no Site integram este Termo, como se nele
        estivessem transcritos.
      </p>
      <p>
        1.5. A Newbacon pode modificar este Termo a qualquer tempo, e o Usuário,
        ao acessar e/ou usar o Site, manifestará o seu consentimento e total
        concordância em cumprir com tais modificações ou revisões. O Usuário
        deve reler periodicamente a versão vigente deste instrumento (disponível
        na URL https://www.newbacon.com/), ainda que a Newbacon procure sempre
        mantê-lo informado de mudanças importantes realizadas neste Termo.
      </p>
      <p>
        1.6. O presente Termo entrará em vigor na data do primeiro acesso e/ou
        utilização do Site pelo Usuário e permanecerá em vigor durante todo o
        período em que sua conta estiver disponível e utilizável pelo Usuário,
        observado o disposto neste Termo.
      </p>
    </div>
    <div class="item-info">
      <h2>2. Uso do Site</h2>
      <p>
        2.1. A Newbacon concede permissão pessoal, não exclusiva e
        intransferível ao Usuário para este acessar e usar o Site de forma
        lícita, tendo acesso a todo o acervo de imagens, ilustrações, áudios,
        audiovisuais, palestras, debates, textos, gráficos, projetos, programas
        de computador, bases de dados e outros materiais disponíveis no Site
        (“Conteúdos”). Em contrapartida, o Usuário se compromete a não:
      </p>

      <p>
        a) reproduzir ou de qualquer forma copiar, transmitir, explorar ou
        distribuir os Conteúdos do Site, no todo ou em parte, para qualquer
        finalidade (incluindo texto, imagens e outros materiais), sem a
        autorização prévia e por escrito da Newbacon;
      </p>
      <p>
        b) alterar ou modificar qualquer parte do Site ou qualquer dos
        Conteúdos;
      </p>
      <p>
        c) acessar os Conteúdos através de qualquer tecnologia ou meio que não
        sejam as páginas de exibição do próprio Site ou de outro meio
        explicitamente autorizado que a Newbacon possa indicar;
      </p>
      <p>
        d) realizar ou participar de qualquer atividade que interfira ou
        interrompa o funcionamento do Site ou dos equipamentos, programas de
        computador e/ou serviços a que o Site esteja conectado;
      </p>
      <p>
        e) carregar, transmitir, divulgar, exibir, enviar ou de qualquer forma
        tornar disponível qualquer tipo de mensagem, anúncio, propaganda ou
        material promocional não autorizado previamente pela Newbacon, por
        escrito, ou mensagens não solicitadas (conhecidos como "junk mail" ou
        "spam"), correntes, esquemas de pirâmide ou outras;
      </p>
      <p>
        f) usar qualquer sistema automatizado, inclusive, mas sem se limitar a
        "robôs", "spiders" ou "offline readers", que acessem o Site de maneira a
        enviar mais solicitações de acesso ou uso em um dado período de tempo do
        que seja humanamente possível responder no mesmo período. O Usuário
        concorda em não coletar qualquer Conteúdo ou informação que identifique
        qualquer pessoa, inclusive nomes de contas, a partir do Site;
      </p>
      <p>
        g) utilizar o Site para qualquer fim outro que o acesso privado e
        informativo do Site, nem abordar outro usuário do Site com qualquer
        finalidade comercial;
      </p>
      <p>
        h) compartilhar links do Site em outros websites, sem a prévia
        autorização da Newbacon, por escrito;
      </p>
      <p>
        i) decodificar, descompilar ou efetuar engenharia reversa, para obter
        códigos fonte, informações e/ou outras características do Site e/ou dos
        Conteúdos;
      </p>
      <p>
        j) cumprir com este Termo e todas as leis e regulamentos aplicáveis,
        sejam regionais, nacionais ou internacionais, comprometendo-se a não
        praticar qualquer ato que possa expor terceiros de modo pejorativo,
        criar e/ou compartilhar um conteúdo ilegal ou de caráter difamatório,
        obsceno, pornográfico, imoral, político, partidário ou comercial, que
        possa violar a moral, a ética, os bons costumes ou direitos da Newbacon
        ou de terceiros, sob pena de arcar com as penalidades aplicáveis pela
        legislação vigente.
      </p>
      <p>
        2.2. A Newbacon se reserva o direito de suspender, cancelar ou
        descontinuar qualquer Conteúdo, funcionalidade ou acesso do Site a
        qualquer tempo, sem necessidade de prévia informação a quem quer que
        seja.
      </p>
      <p>
        2.3. Todos os links a websites externos que forem citados no Site não
        representam, em hipótese alguma, qualquer tipo de endosso a esses
        websites externos e/ou ao seu conteúdo pela Newbacon, sendo de exclusiva
        responsabilidade da entidade que os disponibiliza originalmente.
        Qualquer acesso e/ou uso desses websites externos pelo Usuário será
        feito por sua própria conta e risco. A Newbacon também não se
        responsabiliza caso o endereço do Site esteja disponível em outros
        websites.
      </p>
      <p>
        2.4. Além das restrições gerais acima expostas, as seguintes restrições
        e condições se aplicam especificamente ao uso dos Conteúdos do Site:
      </p>
      <p>
        a) os Conteúdos do Site e as marcas ali contidas são de propriedade
        exclusiva da Newbacon, fornecedores, parceiros ou clientes, conforme o
        caso, e estão sujeitos às normas de proteção de propriedade intelectual
        e segredo de negócio, independentemente de qualquer registro no Brasil
        ou no exterior;
      </p>
      <p>
        b) os Conteúdos do Site são disponibilizados no estado em que se
        encontram (‘as is’) apenas para informação e uso pessoal do Usuário, sem
        qualquer tipo de garantia, sendo vedada qualquer cópia, comercialização,
        licenciamento, distribuição ou qualquer outra forma de exploração para
        qualquer que seja a finalidade, sem o consentimento prévio, por escrito,
        da Newbacon, a quem se reservam todos os direitos ao Site e ao seu
        Conteúdo;
      </p>
      <p>
        c) o Usuário concorda em não violar, desativar ou de qualquer forma
        interferir em características relacionadas à segurança (incluindo
        analisar ou testar a vulnerabilidade de qualquer um dos sistemas
        técnicos ou redes do Site) e/ou funcionamento do Site ou características
        que evitem ou restrinjam o seu uso ou ainda qualquer ato que imponha
        limitações sobre o uso do Site ou de seu Conteúdo;
      </p>
      <p>
        d) o Usuário respeitará direitos de propriedade intelectual, segredos de
        negócio ou quaisquer outros direitos de terceiros disponíveis no Site;
      </p>
      <p>
        e) o Usuário reconhece que a Newbacon poderá estabelecer, a seu
        exclusivo critério, práticas gerais e limites relativos à utilização do
        Site, os quais deverão ser respeitados pelo Usuário, sob pena de sofrer
        penalidades administrativas e judiciais.
      </p>
      <p>
        2.5. O Usuário reconhece que o conteúdo do Site será objeto de buscas
        por ferramentas externas com o único objetivo de criar e disponibilizar
        ao público correspondência no website às palavras-chave pesquisadas. A
        Newbacon se reserva o direito de revogar as permissões às ferramentas
        externas de buscas tanto de forma geral como em casos específicos.
      </p>
      <p>
        2.6. Caso a Newbacon disponibilize no Site Conteúdos com a opção de
        download ou impressão, tais Conteúdos poderão ser usados unicamente pelo
        Usuário, a título não exclusivo, intransferível, apenas para sua
        referência e sem qualquer conotação comercial, ficando vedada qualquer
        reprodução, distribuição, transmissão, exibição, licenciamento ou
        qualquer outra forma de transferência, uso ou exploração, no todo ou em
        parte, desses Conteúdos, sem o consentimento prévio, por escrito, da
        Newbacon.
      </p>
    </div>
    <div class="item-info">
      <h2>3. Privacidade</h2>
      <p>
        3.1. O Site poderá capturar dados relativos ao seu navegador de
        Internet, bem como acesso e uso do Site. Essas informações pessoais e
        dados eventualmente capturados serão usados para aprimoramento e
        manutenção do Site e de seus Conteúdos, assim como para verificação do
        cumprimento deste Termo.
      </p>
      <p>
        3.2. O Usuário autoriza a Newbacon a utilizar suas informações pessoais
        e de navegação no Site em casos de requerimento ou ordem por autoridade
        competente.
      </p>
    </div>
    <div class="item-info">
      <h2>4. Área Restrita</h2>
      <p>
        4.1. Caso o Usuário tenha autorização da Newbacon para acessar a área
        restrita do Site, o Usuário deverá usar as credenciais de acesso
        previamente fornecidas pela Newbacon. As credenciais de acesso,
        incluindo o login e sua senha, são pessoais e intransferíveis.
      </p>
      <p>
        4.2. As credenciais de acesso à área restrita são criadas pela Newbacon,
        com base nas informações pessoais fornecidas pelo Usuário. É de
        responsabilidade do Usuário a manutenção das informações pessoais
        atualizadas junto ao banco de dados da Newbacon, bem como a sua
        veracidade.
      </p>
      <p>
        4.3. O Usuário é o único responsável pela atividade que ocorre em sua
        conta e deve guardar suas credencias de acesso em local seguro, sendo o
        Usuário o único responsável por sua confidencialidade. O Usuário deve
        notificar imediatamente a Newbacon sobre qualquer possível violação de
        segurança ou uso não autorizado de sua conta, ficando responsável pelos
        atos através dela realizados, até a sua correta comunicação à Newbacon.
      </p>
      <p>
        4.4. Ao acessar sua conta na área restrita do Site, o Usuário concorda
        que certas informações a respeito de sua conta e sua utilização
        registram-se automaticamente, principalmente informações decorrentes da
        atividade da conta, dados exibidos ou clicados e outras informações de
        registro (incluindo tipo de navegador, endereço IP, data e hora de
        acesso, ID do cookie e URL de referência).
      </p>
      <p>
        4.5. O Usuário reconhece e autoriza expressamente a Newbacon (e
        terceiros, que poderão ter acesso para fins de desenvolvimento ou
        suporte do Site) a utilizar suas informações e dados para fins de
        manutenção do Site, incluindo-se neste caso as hipóteses de backup de
        dados, replicação de conteúdo, criação de sistemas de segurança e/ou em
        casos de requerimento por autoridade competente com fundamento em ordem
        judicial, ordem administrativa ou investigação extrajudicial.
      </p>
      <p>
        4.6. O Usuário concorda que a Newbacon, a seu critério exclusivo, poderá
        cancelar ou suspender sua conta ou parte das funcionalidades a ela
        relacionadas, sem prévia comunicação:
      </p>
      <p>
        a) por descontinuação ou modificação da área restrita do Site (ou de
        qualquer de suas partes);
      </p>
      <p>b) por caso fortuito ou força maior;</p>
      <p>c) por questões de segurança;</p>
      <p>
        d) em função de ordem judicial ou requisição legal de autoridade
        competente;
      </p>
      <p>
        e) caso o Usuário descumpra ou aja de forma que claramente mostre que
        não pretende ou não pode cumprir qualquer condição deste Termo e/ou da
        legislação vigente; e
      </p>
      <p>
        f) caso o Usuário encerre sua relação com a Newbacon ou este queira
        excluir sua conta, devendo notificar previamente a Newbacon, a qualquer
        momento.
      </p>
      <p>
        4.7. A decisão de readmissão do Usuário representará mero ato de
        liberalidade da Newbacon, não representando, em nenhuma hipótese,
        qualquer alteração do disposto neste Termo. Da mesma forma, a readmissão
        do Usuário não representará a concordância da Newbacon com a ação ou
        omissão que resultou no desligamento do Usuário, remanescendo ao Usuário
        todas as obrigações referentes ao acesso à área restrita do Site e
        previstas neste Termo.
      </p>
    </div>

    <div class="item-info">
      <h2>5. Notificação de Violação de Direitos</h2>
      <p>
        5.1. Em caso de violação qualquer dos itens do presente Termo ou caso o
        Usuário suspeite de uma violação de direitos ou conteúdo impróprio como,
        por exemplo, mas sem se limitar, violação a direitos autorais, material
        obsceno, apologia ao ódio, imagens chocantes e repugnantes, atos
        perigosos e ilegais ou material difamatório, o Usuário deverá
        imediatamente notificar a Newbacon para análise e possível retirada do
        conteúdo supostamente irregular.
      </p>
      <p>
        5.2. É necessária a identificação do Usuário contendo dados pessoais e
        seu contato, assim como deve ser assinada a notificação (sendo aceita
        autenticação digital ou digitalizada).
      </p>
      <p>
        5.3. Qualquer pessoa que intencionalmente apresentar declaração falsa ou
        incorreta sobre um conteúdo estará sujeita às sanções previstas na
        legislação aplicável, bem como poderá ter seu acesso ao Site suspenso ou
        descontinuado.
      </p>
    </div>

    <div class="item-info">
      <h2>6. Limitação de Responsabilidade</h2>
      <p>
        6.1. O Usuário concorda que o uso do Site se fará exclusivamente por sua
        conta e risco, sendo que a Newbacon, bem como seus sócios, profissionais
        e representantes não terão qualquer responsabilidade perante o Usuário,
        relacionada ao uso (e suas consequências) do Site e/ou do Conteúdo,
        incluindo qualquer indenização ou outra obrigação, presente ou futura,
        com relação ao Usuário ou terceiros.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermosDeUso",
};
</script>

<style scoped>
.container {
  max-width: 60%;
  margin-bottom: 150px;
}
h1,
p {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: lighter;
}
h1 {
  text-align: center;
  margin: 80px auto;
  letter-spacing: 2.4px;
}
p {
  margin: 0;
  line-height: 24px;
}
p + p {
  margin-top: 20px !important;
}
h2 {
  font-size: 25px;
  font-family: "Roboto Slab";
  line-height: 24px;
  margin-bottom: 30px;
}
.item-info {
  margin-bottom: 40px;
}
@media (max-width: 989px) {
  .container {
    max-width: 100%;
  }
  h1 {
    margin: 40px auto;
    font-size: 12px;
  }
  h2 {
    font-size: 20px;
    line-height: 34px;
    margin: 30px auto;
  }
}
</style>