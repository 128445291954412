<template>
  <section class="solucoes">
    <div class="div-suporte">
      <h2 data-anima="top-scroll">
        {{ $i18n.locale == "pt" ? "SOLUÇÕES" : "SOLUTIONS" }}
      </h2>
      <div class="grid-section" v-show="!loading">
        <div data-anima="left-scroll">
          <h3 class="titulo-solucao" data-anima="left" ref="tituloSolucao">
            {{ $i18n.locale == "pt" ? item_ativo.textBr : item_ativo.textEn }}
          </h3>
          <router-link to="/solucoes" title="soluções">
            <img
              loading="lazy"
              src="@/assets/img/svg/add_FILL0_wght200_GRAD0_opsz48.svg"
              height="50"
              width="50"
              alt="icone mais"
              class="icone-mais"
          /></router-link>
          <CarroselFake
            name="solucoes-itens"
            :carrosel_itens="carrosel_itens"
            :mini="true"
            @clickItemCarroselSolucoes="clickItemCarrosel"
          />
        </div>
        <div class="img-item" data-anima="right-scroll">
          <!-- :src="getImage(item_ativo.img)" -->
          <ImgSolucao Solucao :id="item_ativo.id" :text="item_ativo.text" />
          <!-- <img
          :src="getImage(item_ativo.img)"
          :alt="item_ativo.text"
          class="bg mobile"
        /> -->
        </div>
      </div>
      <Loading v-if="loading" />
    </div>
  </section>
</template>

<script>
import CarroselFake from "../Base/CarroselFake.vue";
import ImgSolucao from "@/components/ImgSolucao.vue";

export default {
  name: "Solucoes",
  components: {
    CarroselFake,
    ImgSolucao,
  },
  data() {
    return {
      loading: false,
      item_ativo: {
        id: 0,
        text: "",
        img: "",
      },
      carrosel_itens: [
        {
          id: 1,
          textBr: "Execução criativa",
          textEn: "Creative Execution",
          img: "@/assets/img/banners/execucao-criativa-data-driven_720x600.png",
        },
        {
          id: 2,
          textBr: "Planejamento Data-driven",
          textEn: "Data-driven planning",
          img: "img/banners/insight-plano-de-acao_720x600.png",
        },
        {
          id: 3,
          textBr: "Data management & engenharia de dados",
          textEn: "Data management & data engineering",
          img: "img/banners/data-management_720x600.png",
        },
        {
          id: 4,
          textBr: "Data science",
          textEn: "Data science. AI, BI & Martech",
          img: "img/banners/data-science_720x600.png",
        },
        {
          id: 5,
          textBr: "Gestão 360º do CRM",
          textEn: "360º CRM Management",
          img: "img/banners/gestao-360_720x600.png",
        },
        {
          id: 6,
          textBr: "CRM from zero to hero",
          textEn: "CRM from zero to hero",
          img: "img/banners/crm_from_zero-to_hero_720x600.png",
        },
      ],
    };
  },

  methods: {
    clickItemCarrosel(index) {
      this.item_ativo = this.carrosel_itens[index];

      this.$refs.tituloSolucao.classList.add("hide");

      this.$nextTick(() => {
        this.$refs.tituloSolucao.classList.remove("hide");
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.item_ativo = this.carrosel_itens[0];
    });
  },
};
</script>

<style scoped>
.solucoes {
  margin: 50px auto;
  /* margin-top: 6%; */
  height: 80vh;
  display: flex;
  align-items: center;
}
.div-suporte {
  width: 100%;
}
.solucoes h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
}
.solucoes .grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.solucoes .grid-section h3 {
  color: var(--secondary-color);
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 90px;
  display: block;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}
.solucoes .grid-section h3.hide {
  display: none;
}
.img-item {
  margin: 0 auto;
  width: 400px;
  height: 400px;
  background: rgba(186, 186, 186, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: relative;
  right: -150px;
  top: -100px;
  margin-left: auto;
}
.img-item img {
  position: relative;
  left: -200px;
}
.solucoes .grid-section h3 {
  font-family: "Roboto Slab";
}
.icone-mais {
  min-width: 30px;
  min-height: 30px;
}
@media (max-width: 989px) {
  .solucoes {
    margin: 60px auto;
    height: auto;
  }
  .solucoes h2 {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .solucoes .grid-section {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-item {
    border-radius: 20px;
    border-top: transparent;
    right: 0;
    top: 0;
    width: 95%;
    height: 200px;
    transform: rotate(45deg);
  }
  .img-item img.bg {
    left: 0;
    /* transform: rotate(-45deg); */
    max-width: 250px;
  }
  .solucoes .grid-section h3 {
    color: var(--primary-color);
    font-family: "Roboto Slab";
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    max-width: 80%;
    z-index: 9999;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .icone-mais {
    margin: 0 auto;
  }
}
</style>