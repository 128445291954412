<template>
  <section>
    <PrimeiraQuebra :cases_home="displayedCases" />
    <div class="container">
      <SobreNos />
      <Solucoes />
      <Cases
        v-if="!loading"
        anima_scroll="-scroll"
        :rodape="true"
        :cases_home="cases_home_itens"
      />
      <Manifesto />
    </div>
    <BlogNews class="margin-manifesto" />
  </section>
</template>

<script>
import PrimeiraQuebra from "@/components/Home/PrimeiraQuebra.vue";
import SobreNos from "@/components/Home/SobreNos.vue";
import Solucoes from "@/components/Home/Solucoes.vue";
import Cases from "@/components/Home/Cases.vue";
import Manifesto from "@/components/Home/Manifesto.vue";
import BlogNews from "@/components/Home/BlogNews.vue";

import { ScrollAnima } from "@/helpers";

import { api } from "@/services";

export default {
  name: "HomeView",
  components: {
    PrimeiraQuebra,
    SobreNos,
    Solucoes,
    Cases,
    Manifesto,
    BlogNews,
  },
  data() {
    return {
      loading: true,
      cases_itens: [],
      displayedCases: [],
      cases_home_itens: [],
    };
  },
  methods: {
    selectRandomCases(cases) {
      const numberOfCasesToShow = 3;
      const randomCases = [];
      const casesCopy = [...cases];

      for (let i = 0; i < numberOfCasesToShow; i++) {
        const randomIndex = Math.floor(Math.random() * casesCopy.length);
        randomCases.push(casesCopy.splice(randomIndex, 1)[0]);
      }
      this.displayedCases = randomCases;
    },
    fetchCases() {
      this.loading = true;
      // const cases_home = sessionStorage.cases_home
      //   ? JSON.parse(sessionStorage.cases_home)
      //   : null;
      // const cases_home = null;
      // if (!cases_home) {
      api
        .get(`/cases?page=1&pageSize=50&statusId=1`)
        .then((resp) => {
          this.cases_itens = resp.data?.items;
          this.selectRandomCases(this.cases_itens);
          // sessionStorage.cases_home = JSON.stringify(this.cases_itens);
          this.cases_home_itens = resp.data?.items.slice(0, 3);
        })
        .finally(() => {
          const scrollAnima = new ScrollAnima("[data-anima]");
          scrollAnima.init();
          this.loading = false;
        });
      // } else {
      //   this.cases_itens = cases_home;
      // }
    },
  },
  mounted() {
    this.fetchCases();
    document.title = "Newbacon | Transformamos dados em comunicação eficiente";
  },
};
</script>

<style scoped>
.margin-manifesto {
  margin-top: 120px;
}
</style>

