<template>
  <section class="container">
    <div class="form" data-anima="bottom">
      <form action="" @submit.prevent="onSubmit">
        <div>
          <h2>Bem-vindo de volta 👋</h2>
          <p>Entre com as credenciais para gerenciar o site</p>
          <Notificacao
            :infos="notificacao_infos"
            v-if="notificacao_infos.mensagem"
          />
          <div class="content-form" v-if="!loading">
            <div class="item-form">
              <label for="email">E-mail</label>
              <input type="email" id="email" v-model="form.email" />
            </div>
            <div class="item-form">
              <label for="senha">Senha de acesso</label>
              <input type="password" id="senha" v-model="form.password" />
            </div>
            <button class="btn">Acessar</button>
          </div>
          <Loading v-if="loading" />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
    };
  },
  methods: {
    onSubmit() {
      window.scrollTo(0, 0);
      this.notificacao_infos = {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      };
      if (this.form.password) {
        this.loading = true;
        this.$store
          .dispatch("logarUsuario", this.form)
          .then(() => {
            this.$store.dispatch("getUsuario").then(() => {
              setTimeout(() => {
                this.$router.push({
                  path: "/backoffice/cases",
                  query: this.$route.query,
                });
              }, 200);
            });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
                ? error.response.data.errors[0]?.message
                : "Algo deu errado na requisição",
              count_timer: 10,
            };
          });
      }
    },
  },

  mounted() {
    this.loading = true;
    if (window.localStorage.token) {
      this.$router.push("/backoffice/cases");
    } else {
      this.loading = false;
    }
  },
};
</script>


<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.form {
  flex: 1;
  max-width: 500px;
  margin: 0 auto;
  padding: 60px 40px;

  border-radius: 10px;
  border: 1px solid #e5e5e5;
}

.form h2 {
  font-family: "Roboto Slab";
  font-size: 28px;
}
.form p {
  font-weight: 300;
  margin: 10px 0;
}
.content-form {
}
.content-form button {
  margin-top: 20px;
  margin-left: auto;
}
@media (max-width: 989px) {
  .form {
    padding: 40px 20px;
    border: none;
  }
  .form h2 {
    font-size: 18px;
  }
}
</style>