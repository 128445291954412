<template>
  <section class="cases">
    <div class="area-cases">
      <div class="div-suporte">
        <h2 :data-anima="`top${anima_scroll}`" v-if="rodape">
          {{ $i18n.locale == "pt" ? "NA PRÁTICA" : "IN PRACTICE" }}
        </h2>
        <div class="mobile-overlay">
          <span class="overlay-name" data-anima="bottom">CASES</span>
        </div>

        <CasesItens
          v-if="!loading"
          :data-anima="`bottom${anima_scroll}`"
          :rodape="rodape"
          :cases_home="cases_home"
          ref="itemCase"
        />
      </div>
    </div>

    <div class="text-cases" :data-anima="`top${anima_scroll}`" v-if="rodape">
      <h5 v-if="$i18n.locale == 'pt'">
        Nossos criativos usam ciência e análise de dados para<br
          v-if="!isMobile"
        />
        encantar e inspirar pessoas, em todos os impactos
      </h5>
      <h5 v-if="$i18n.locale == 'en'">
        Our creatives use data science and analytics to<br v-if="!isMobile" />
        enchant and inspire people, in all impacts
      </h5>
      <div class="btn-cases">
        <router-link to="/cases" class="btn btn-4" title="cases"
          >—
          <span>{{
            $i18n.locale == "pt" ? "VEJA TODOS OS CASES" : "SEE ALL CASES"
          }}</span>
          —</router-link
        >
      </div>
      <div v-if="form">
        <span class="ou">{{ $i18n.locale == "pt" ? "OU" : "OR" }}</span>

        <div>
          <FormularioContato
            :no_case="true"
            @FormularioContato="FormularioContato"
            ref="formContato"
          />
        </div>
        <div>
          <span class="ou sepreferir">{{
            $i18n.locale == "pt" ? "SE PREFERIR" : "IF YOU PREFER"
          }}</span>
          <div class="whats">
            <a href="https://wa.me/5511992214220?text=Olá!" target="_blank">
              <button class="btn btn-4">
                WHATSAPP
                <img
                  loading="lazy"
                  src="@/assets/img/svg/iconmonstr-whatsapp-1.svg"
                  alt="icone whatsapp"
                  width="50"
                  height="50"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CasesItens from "./CasesItens.vue";
import FormularioContato from "../Base/FormularioContato.vue";

import { api } from "@/services";

export default {
  name: "Cases",
  components: {
    CasesItens,
    FormularioContato,
  },
  props: {
    cases_home: {
      type: Array,
      default: () => [],
    },
    anima_scroll: {
      type: String,
      default: "",
    },
    rodape: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.mobile;
    },
  },
  methods: {
    filtrar(data) {
      const itemCase = this.$refs.itemCase;
      if (itemCase) {
        itemCase.filtrar(data);
      }
    },

    FormularioContato(data) {
      const form = this.$refs.formContato;

      api
        .post("/lead", data)
        .then(() => {
          if (form) {
            form.showNotificacao(
              "success",
              this.$i18n.locale == "pt"
                ? `Olá ${data.nome}, agradecemos seu interesse. Entraremos em contato em breve para entender melhor suas necessidades e discutir como podemos ajudar.`
                : `Hello ${data.nome}, thank you for your interest. We will contact you shortly to better understand your needs and discuss how we can help.`
            );
          }
        })
        .catch((error) => {
          form.showNotificacao(
            "error",
            error.response
              ? error.response.data.errors[0]?.message
              : this.$i18n.locale == "pt"
              ? "Algo deu errado na requisição"
              : "Something went wrong with the request"
          );
        });
    },
  },
};
</script>

<style scoped>
.cases {
  margin: 50px auto;
  /* position: relative; */
}
.cases h2 {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2.4px;
}
.cases .overlay-name {
  text-align: center;
  font-size: 400px;
  font-family: "Roboto Slab";
  color: #f7f7f7;
  position: relative;
  top: 130px;
  left: 10px;
  line-height: 26px;
  z-index: -1;
}
.cases .text-cases h5 {
  font-family: "Roboto Slab";
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin: 30px auto;
  margin-top: 50px;
}
.cases .text-cases button {
  margin: 0 auto;
}
.cases .text-cases button span {
  margin: 0 15px;
}
.cases .text-cases span.ou {
  text-align: center;
  font-weight: 300;
  margin: 30px auto;
  display: block;
  font-size: 14px;
}

.whats button {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  letter-spacing: 1.8px;
  width: 180px;
  padding: 0;
  font-weight: 600;
  background: #797979;
}
.whats button img {
  max-width: 15px;
}
.sepreferir {
  margin-top: 60px !important;
  margin-bottom: 20px !important;
}
.btn-cases {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.btn-cases a {
  display: inline-block;
}
.area-cases {
  min-height: 85vh;
  display: flex;
  align-items: center;
}
.div-suporte {
  width: 100%;
}

@media (max-width: 989px) {
  .cases {
    margin: 60px auto;
    position: relative;
  }
  .cases h2 {
    font-size: 12px;
  }

  /*
  .cases .mobile-overlay {
    background: red;
    transform: rotate(-90deg);
    left: -320px;
    position: absolute;
    top: 200px;
  }*/
  .mobile-overlay span {
    display: none;
  }
  .cases .overlay-name {
    font-size: 150px;
  }
  .cases .text-cases h5 {
    /* text-align: left; */
    font-size: 20px;
  }
  .input-item {
    width: 100%;
  }
  .input-item input {
    width: 100%;
  }
  .area-cases {
    height: auto;
  }
}
</style>