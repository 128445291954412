<template>
  <header data-anima="top">
    <nav class="container">
      <router-link to="/backoffice/login" class="logo">
        <img
          loading="lazy"
          src="@/assets/img/svg/logo-newbacon.svg"
          alt="Logo newbacon"
      /></router-link>

      <ul class="itens-menu">
        <li>
          <router-link to="/backoffice/cases">Lista de cases</router-link>
        </li>
        <li>
          <router-link to="/backoffice/cases/novo">Novo case</router-link>
        </li>
        <li>
          <router-link to="/backoffice/leads">Leads</router-link>
        </li>
        <li>
          <a
            class="btn usuario_logado"
            href="#"
            title="Clique para deslogar"
            @click.prevent="deslogarUsuario"
            >👋 {{ user_name }}</a
          >
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "MenuLogado",
  data() {
    return {
      mobile: false,
    };
  },
  computed: {
    user_name() {
      return window.localStorage.user
        ? JSON.parse(window.localStorage.user).nome
        : "";
    },
  },
  methods: {
    deslogarUsuario() {
      this.$store.dispatch("deslogarUsuario");
      this.$router.push("/backoffice/login");
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  /* background: var(--white-color); */
  z-index: 99999;
  margin-top: 20px;
  margin-bottom: -40px;
  background: #ffffff36;
  backdrop-filter: blur(25px);
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  /* background: var(--white-color); */
  min-height: 80px;
}
nav .logo {
  width: 120px;
  padding-bottom: 5px;
}
nav .itens-menu {
  display: flex;
  align-items: center;
  gap: 40px;
}
nav .itens-menu li a {
  font-size: 16px;
  transition: 0.3s;
}

nav li a:hover,
nav li a.router-link-active,
nav li a.router-link-exact-active {
  /* color: var(--secondary-color); */
  font-weight: 600;
  cursor: pointer;
}
.usuario_logado {
  background: #f7f7f7;
  color: #000 !important;
  padding: 1px 30px;
}
</style>