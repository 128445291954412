<template>
  <div class="accordion-item" :class="{ show: isOpen }">
    <div class="accordion-item-title" @click="toggleAccordion">
      - {{ title }}
      <img src="@/assets/img/svg/arrow-acordiao.svg" alt="seta arrow" />
    </div>
    <div class="accordion-item-content" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "ativo"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    if (this.ativo) {
      this.isOpen = true;
    }
  },
};
</script>

<style scoped>
.accordion-item {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  /* margin: 40px auto; */
}

.accordion-item-title {
  cursor: pointer;
  padding: 30px;
  font-weight: bold;
  border-radius: 10px;
  transition: 0.3s;
  font-family: "Roboto Slab";
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-item-title img {
  width: 20px;
  transition: 0.3s;
}
.show .accordion-item-title img {
  transform: rotate(-180deg);
}
.accordion-item-content {
  display: none;
  padding: 20px 30px;
}
.show .accordion-item-title,
.accordion-item-title:hover {
  background-color: #f7f7f7;
}
.show .accordion-item-content {
  display: block;
  padding-bottom: 40px;
}
</style>